import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ControlContainer, NgForm, UntypedFormGroup} from '@angular/forms';
import {
  BondingExternalContractDto,
  BondVersionDto,
  CompanyIdDto,
  CompanyRawDto,
  CompanySimpleDto,
} from '../../../bonding_shared/model';
import {LoggedUserService, RouterService} from '../../../bonding_shared/services';
import {BondingExternalContractType, ContractType} from '../../../bonding_shared/model/dictionary-ids';
import {ListEmitters} from '../../../bonding_shared/components/details-view/list-emitters';
import {ATableComponent} from '../../../bonding_shared';

@Component({
  selector: 'bonding-consortia-section',
  templateUrl: './bonding-consortia-section.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingConsortiaSectionComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() productTypeId: number;

  @Input() consortiumClientCompanies: CompanyIdDto[];
  @Input() consortiumBeneficiaryCompanies: CompanyIdDto[];

  consortiumClient = false;
  consortiumBeneficiary = false;

  @ViewChild('consortiumClientCompaniesTable') consortiumClientCompaniesTable: ATableComponent<CompanyIdDto>;
  @ViewChild('consortiumBeneficiaryCompaniesTable') consortiumBeneficiaryCompaniesTable: ATableComponent<CompanyIdDto>;

  selectors: ListEmitters = new ListEmitters();

  constructor(public router: RouterService, private loggedUserService: LoggedUserService) {
    this.selectors.initializeSelectorEmittersByNames(true, ['consortiumBeneficiary', 'consortiumClient']);
  }

  ngOnInit(): void {
    this.consortiumClient = this.consortiumClientCompanies.length > 0;
    this.consortiumBeneficiary = this.consortiumBeneficiaryCompanies.length > 0;
  }

  onConsortiumClientStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumClientCompaniesTable.deleteAllClicked();
    }
  }

  onConsortiumBeneficiaryStateChanged(checked: boolean) {
    if (!checked) {
      this.consortiumBeneficiaryCompaniesTable.deleteAllClicked();
    }
  }

  onSelectConsortiumClient(company: CompanySimpleDto) {
    if (!this.consortiumClientCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumClientCompaniesTable.onAddItem(company);
    }
  }

  onSelectConsortiumBeneficiary(company: CompanySimpleDto | CompanyRawDto) {
    if (!this.consortiumBeneficiaryCompanies.map((c) => c.id).includes(company.id)) {
      this.consortiumBeneficiaryCompaniesTable.onAddItem(company);
    }
  }
}

import {Component, Input, ViewChild} from '@angular/core';
import {BondingContractInquirySurveyDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'bonding-contract-inquiry-tab-survey',
  templateUrl: './bonding-contract-inquiry-tab-survey.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryTabSurveyComponent {
  @Input() showErrors = false;
  @Input() survey: BondingContractInquirySurveyDto;
  @Input() readonly = false;
  @ViewChild('tabModel', {static: true}) form: NgForm;

  setValue(event: {name: string; value: boolean}) {
    this.survey[event.name] = event.value;
    if (!this.survey.estateCollateral) {
      this.survey.tenderGuarantee = undefined;
      this.survey.contractPerformance = undefined;
      this.survey.repair = undefined;
      this.survey.advanceReturn = undefined;
      this.survey.exportContract = undefined;
      this.survey.exportProductionDelivery = undefined;
      this.survey.exportProductionSupplyChain = undefined;
    }
    if (!this.survey.environmentalProtection) {
      this.survey.wasteDisposalCost = undefined;
      this.survey.wasteSiteManagement = undefined;
      this.survey.environmentalDamage = undefined;
      this.survey.internationalWasteRelocation = undefined;
    }
  }

  setRefusal(v: boolean) {
    if (v) {
      this.form.control.disable();
    } else {
      this.form.control.enable();
    }
  }
}

import {AbstractService, UrlParams} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BondingContractOfferDto, StateTransitionDto} from '../model';
import {Observable} from 'rxjs';

@Injectable()
export class BondingContractOfferAbstractService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  getTransitions(bv: BondingContractOfferDto): Observable<Array<StateTransitionDto>> {
    if (bv && bv.status) {
      const params = UrlParams.new().add('edge', bv.edge).add('statusId', bv.status.id);
      return this.get<Array<StateTransitionDto>>(this.buildUrl(this.url, 'transitions', params));
    }
    return Observable.create([]);
  }

  createPrepareSuretyProposalTask(offerId: number): Observable<number> {
    const params = UrlParams.new().add('offerId', offerId);
    return this.get<number>(this.buildUrl(this.url, 'createPrepareSuretyProposalTask', params));
  }
}

import {Component, Input} from '@angular/core';
import {Feature} from '../../../../bonding_shared/model/dictionary-ids';
import {
  AppConfigService,
  BondingContractInquiryVersionCoreDto,
  BondingContractInquiryVersionDto,
  BondingContractOfferDto,
  BondVersionDto,
  FeatureService,
  LoggedUserService,
} from '../../../../bonding_shared';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'bonding-decisions',
  templateUrl: 'bonding-decisions.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingDecisionsComponent {
  @Input() object:
    | BondingContractInquiryVersionDto
    | BondingContractInquiryVersionCoreDto
    | BondVersionDto
    | BondingContractOfferDto;
  @Input() showErrors = false;
  @Input() readonly = false;

  constructor(
    private featureService: FeatureService,
    private appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {}

  showDecisionsSection(): boolean {
    return (
      !this.portal && this.featureService.get(Feature.SHOW_BONDING_DECISIONS_SECTION) && this.object?.id !== undefined
    );
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractService} from './abstract.service';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {
  BondingContractExposureDto,
  ContractDto,
  ContractTechnicalDetailsDto,
  ContractVersionDto,
  ExternalLimitDto,
  SearchResult,
  StatisticsDto,
} from '../model/dtos';
import {HttpClient} from '@angular/common/http';
import {Cacheable} from 'ts-cacheable';

@Injectable()
export class ContractService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
    this.url = this.urlPrefix + this.portalPrefix + 'contract';
  }

  getInitialContractVersion(clientId: number, businessUnitId: number) {
    return this.get<ContractVersionDto>(
      this.url + '/initialVersion?clientId=' + clientId + '&businessUnitId=' + businessUnitId
    );
  }

  getContractStatistics(contractId: number): Observable<StatisticsDto> {
    return this.get<StatisticsDto>(this.url + '/' + contractId + '/statistics');
  }

  getCompanyContracts(clientId: string): Observable<SearchResult<ContractDto>> {
    // return this.http.get(this._contractUrl + '?clientId=' + clientId + '&start=' + start + '&count=' + count)
    return this.get<SearchResult<ContractDto>>(this.url + '?clientId=' + clientId);
  }

  searchContracts(text: string, start: number, count: number): Observable<SearchResult<ContractDto>> {
    return this.get<SearchResult<ContractDto>>(
      this.url + '/search?text=' + text + '&start=' + start + '&count=' + count
    );
  }

  createContract(contract: ContractDto): Observable<ContractDto> {
    console.log('crete contract:' + JSON.stringify(contract));
    return this.put<ContractDto>(contract, this.url);
  }

  updateContract(contract: ContractDto): Observable<ContractDto> {
    console.log('update contract: id = ' + contract.id);
    return this.post<ContractDto>(contract, this.url);
  }

  deleteContract(id: number): Observable<ContractDto> {
    console.log('delete contract: id = ' + id);
    return this.delete<ContractDto>(this.url + '/' + id);
  }

  getTechnicalDetails(contractId: number): Observable<ContractTechnicalDetailsDto> {
    return this.get<ContractTechnicalDetailsDto>(this.url + '/' + contractId + '/technicalDetails');
  }

  updateTechnicalDetails(technicalDetails: ContractTechnicalDetailsDto) {
    return this.post<ContractTechnicalDetailsDto>(
      technicalDetails,
      this.url + '/' + technicalDetails.contract.id + '/technicalDetails'
    );
  }

  public getCurrentInvolvement(companyId: number): Observable<BondingContractExposureDto> {
    return this.get<BondingContractExposureDto>(this.url + '/involvement?companyId=' + companyId);
  }

  @Cacheable()
  public getExternalLimits(contractId: number): Observable<ExternalLimitDto[]> {
    return this.get<ExternalLimitDto[]>(this.url + '/' + contractId + '/externalLimits');
  }
}

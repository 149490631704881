import {Injectable} from '@angular/core';
import {AppConfigService} from './app-config.service';
import {LoggedUserService} from './logged-user.service';
import {Observable} from 'rxjs';
import {
  DictionaryBaseDto,
  IdDto,
  LimitDecisionDto,
  OfferCalculationEnvelopeDto,
  PolicyCreationDataDto,
  PolicyCreationInfoDto,
  PolicyInquiryBaseDto,
  PolicyInquiryOfferDto,
  PolicyInquiryOfferEnvelopeDto,
  PolicyInquiryVersionBaseDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionDto,
  PolicyInquiryVersionSimpleDto,
  SearchCriteria,
  SQForYearsDto,
  StateTransitionDto,
  VoteDto,
  PremiumCalculationCriteriaDto,
  PremiumCalculationResultDto,
  IndustryTariffsDto,
} from '../model';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {InquiryAbstractService} from './inquiry-abstract.service';
import {Cacheable} from 'ts-cacheable';
import {NumberUtils} from '../utils/number-utils';
import {TemplateService} from './template.service';
import {DictionaryBaseService} from './dictionary-base.service';
import {BinaryDownloaderService} from './binary-downloader.service';

@Injectable()
export class InquiryService extends InquiryAbstractService {
  constructor(
    http: HttpClient,
    appConfigService: AppConfigService,
    loggedUserService: LoggedUserService,
    protected templateService: TemplateService,
    protected dictionaryBaseService: DictionaryBaseService,
    private binaryDownloader: BinaryDownloaderService
  ) {
    super(http, appConfigService, loggedUserService, templateService, dictionaryBaseService);
    this.url = this.urlPrefix + this.portalPrefix + 'policyInquiryVersion';
  }

  // getInquiry(id: number): Observable<PolicyInquiryDto> {
  //   return this.get<PolicyInquiryDto>(this.url + '/' + id);
  // }
  //
  // getInquiries(clientId: number): Observable<SearchResult<PolicyInquirySimpleDto>> {
  //   return this.get<SearchResult<PolicyInquirySimpleDto>>(this.url + '?clientId=' + clientId);
  // }
  //
  // saveInquiry(inquiry: PolicyInquiryDto): Observable<PolicyInquiryDto> {
  //   if (inquiry.id > 0) {
  //     return this.updateInquiry(inquiry);
  //   } else {
  //     return this.createInquiry(inquiry);
  //   }
  // }

  voteOffer(offerId: number, vote: VoteDto) {
    return this.post1<VoteDto, PolicyInquiryOfferDto>(vote, this.url + '/' + offerId + '/doVote');
  }

  closeVoting(offerId: number): Observable<PolicyInquiryOfferDto> {
    return this.postEmpty<PolicyInquiryOfferDto>(this.url + '/offer/' + offerId + '/closevoting');
  }

  getTransitions(inquiry: PolicyInquiryVersionDto): Observable<Array<StateTransitionDto>> {
    return this.get<Array<StateTransitionDto>>(this.url + '/transition/' + inquiry.status.id);
  }

  getTransitionsMap(): Observable<Map<number, Array<StateTransitionDto>>> {
    return this.get<Map<number, Array<StateTransitionDto>>>(this.url + '/transition');
  }

  // private updateInquiry(inquiry: PolicyInquiryDto): Observable<PolicyInquiryDto> {
  //   return this.post<PolicyInquiryDto>(inquiry, this.url);
  // }
  //
  // private createInquiry(inquiry: PolicyInquiryDto): Observable<PolicyInquiryDto> {
  //   return this.put<PolicyInquiryDto>(inquiry, this.url);
  // }

  getOffer(id: number): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.get<PolicyInquiryOfferEnvelopeDto>(this.url + '/offer/' + id);
  }

  initOffer(inquiryId: number, offerTypeId: number, sourceOfferId?: number, blogOffer?: boolean, edge?: boolean) {
    const sourceOfferIdParam = sourceOfferId ? '/' + sourceOfferId : '';
    const blogOfferParam = sourceOfferId ? (blogOffer ? '/true' : '/false') : '';
    let edgeParams = '';
    if (edge === true || edge === false) {
      edgeParams = '?edge=' + edge;
    }
    return this.get<PolicyInquiryOfferEnvelopeDto>(
      this.url + '/initoffer/' + inquiryId + '/' + offerTypeId + sourceOfferIdParam + blogOfferParam + edgeParams
    );
  }

  createPolicyFromOffer(offer: PolicyCreationDataDto): Observable<PolicyCreationInfoDto> {
    return this.post1<PolicyCreationDataDto, PolicyCreationInfoDto>(offer, this.url + '/policyFromOffer');
  }

  createRenewalInquiry(policyContractVersionId: number): Observable<number> {
    return this.post<IdDto>({id: policyContractVersionId}, this.url + '/renewalInquiry').pipe(map((idDto) => idDto.id));
  }

  createRenewalOffer(policyContractVersionId: number): Observable<PolicyInquiryVersionBaseDto> {
    return this.post1<IdDto, PolicyInquiryVersionBaseDto>({id: policyContractVersionId}, this.url + '/renewalOffer');
  }

  saveCalculation(
    offerId: number,
    offerCalculation: OfferCalculationEnvelopeDto
  ): Observable<OfferCalculationEnvelopeDto> {
    return this.post<OfferCalculationEnvelopeDto>(
      offerCalculation,
      this.url + '/offer/' + offerId + '/saveCalculation/'
    ).pipe();
  }

  savePremiumCalculation(offerId: number, criteria: PremiumCalculationCriteriaDto) {
    return this.post<PremiumCalculationCriteriaDto>(
      criteria,
      this.url + '/offer/' + offerId + '/savePremiumCalculation/'
    );
  }

  calculate(offerId: number, offerCalculation: OfferCalculationEnvelopeDto): Observable<OfferCalculationEnvelopeDto> {
    return this.post<OfferCalculationEnvelopeDto>(
      offerCalculation,
      this.url + '/offer/' + offerId + '/calculate/'
    ).pipe();
  }

  calculatePremium(criteria: PremiumCalculationCriteriaDto): Observable<number> {
    return this.post1<PremiumCalculationCriteriaDto, number>(criteria, this.url + '/calculatePremium');
  }

  updateCalculationDate(): Observable<string> {
    return this.get<string>(this.url + '/updateCalculationDate');
  }

  calculateCalculatedPremiumRate(criteria: PremiumCalculationCriteriaDto): Observable<PremiumCalculationResultDto> {
    return this.post1<PremiumCalculationCriteriaDto, PremiumCalculationResultDto>(
      criteria,
      this.url + '/calculatePremiumRate'
    );
  }

  getCalculate(offerId: number): Observable<OfferCalculationEnvelopeDto> {
    return this.get<OfferCalculationEnvelopeDto>(this.url + '/offer/' + offerId + '/calculate/').pipe();
  }

  getLastVersion(id: number): Observable<PolicyInquiryVersionSimpleDto> {
    const crit = <SearchCriteria<PolicyInquiryVersionCriteriaDto>>{criteria: {last: true, policyInquiry: {id: id}}};
    return this.searchByCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(crit).pipe(
      map((res) => res.result[0])
    );
  }

  getClientProducts(): Observable<DictionaryBaseDto[]> {
    return this.get<DictionaryBaseDto[]>(this.url + '/clientProducts');
  }

  issuePolicyFromOffer(offerId: number): Observable<PolicyInquiryOfferEnvelopeDto> {
    return this.postEmpty<PolicyInquiryOfferEnvelopeDto>(this.url + '/offer/' + offerId + '/issuePolicy');
  }

  uploadDraftPolicy(templateId: number, offerId: number): Observable<void> {
    return this.postEmpty<void>(this.url + '/offer/' + offerId + '/uploadDraftPolicy/' + templateId);
  }

  getLimitActiveDecisions(inquiryId: number): Observable<LimitDecisionDto[]> {
    return this.get<LimitDecisionDto[]>(this.url + '/limitActiveDecisions/' + inquiryId);
  }

  getLimitPositiveDecisions(inquiryId: number): Observable<LimitDecisionDto[]> {
    return this.get<LimitDecisionDto[]>(this.url + '/limitPositiveDecisions/' + inquiryId);
  }

  getSurveyVisibility(inquiryId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/getSurveyVisibility/' + inquiryId);
  }

  getParentById(id: number): Observable<PolicyInquiryBaseDto> {
    return this.get<PolicyInquiryBaseDto>(this.url + '/parent/' + id);
  }

  @Cacheable({maxCacheCount: 100, maxAge: 60000})
  getConsentVisibility(clientId: number, inquiryId: number) {
    return this.get<StatementsVisibility>(
      this.url + '/consentVisibility/' + NumberUtils.notNull(clientId) + '/' + NumberUtils.notNull(inquiryId)
    );
  }

  getCoefficientSQParams(masterPCId: number): Observable<SQForYearsDto[]> {
    return this.get<SQForYearsDto[]>(this.url + '/coefficientSQParams/' + masterPCId);
  }

  getOfferDocument(offerId: number, fileName: string) {
    const url = this.url + '/offer/' + offerId + '/offerDocument';

    this.binaryDownloader.download('GET', url, 'application/pdf', 'application/octet-stream', fileName, false, null);
  }

  calculateInsurableTurnoverMultiplicator(criteria: PremiumCalculationCriteriaDto): Observable<number> {
    return this.post1<PremiumCalculationCriteriaDto, number>(
      criteria,
      this.url + '/calculateInsurableTurnoverMultiplicator'
    );
  }

  calculateLossRatioMultiplicator(criteria: PremiumCalculationCriteriaDto): Observable<number> {
    return this.post1<PremiumCalculationCriteriaDto, number>(criteria, this.url + '/calculateLossRatioMultiplicator');
  }

  calculateDiscretionaryCoverMultiplier(criteria: PremiumCalculationCriteriaDto): Observable<number> {
    return this.post1<PremiumCalculationCriteriaDto, number>(
      criteria,
      this.url + '/calculateDiscretionaryCoverMultiplier'
    );
  }

  calculateInsuranceCoverMultiplier(criteria: PremiumCalculationCriteriaDto): Observable<number> {
    return this.post1<PremiumCalculationCriteriaDto, number>(criteria, this.url + '/calculateInsuranceCoverMultiplier');
  }

  getIndustryTariffs(naceCodeId): Observable<IndustryTariffsDto> {
    return this.get<IndustryTariffsDto>(this.url + '/industryTariffs/' + naceCodeId);
  }
}

export interface StatementsVisibility {
  CONSENT_TO_CONTACT_THE_CONTRACTOR_USING_THE_CUSTOMERS_NAME: boolean | null;
  INFORMATION_CLAUSE_REGARDING_THE_PROCESSING_OF_PERSONAL_DATA: boolean | null;
}

import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {SharedComponentsModule} from '../../bonding_shared/components/shared-components.module';
import {PolicyContractDetailsComponent} from './policy-contract-details.component';
import {PolicyContractVersionSearchComponent} from './policy-contract-version-search.component';
import {PolicyContractCompanyListComponent, PolicyTabLayoutComponent} from './components';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CompanyModule} from '../company/company.module';
import {DocumentModule} from '../document/document.module';
import {PolicyContractViewService} from './services/policy-contract-view.service';
import {PolicyContractService, PolicyMonitoringVersionService} from '../../bonding_shared';
import {EmbeddablePolicySearchComponent} from './components/embeddable-policy-search.component';
import {PolicyContractTechnicalDetailsComponent} from './policy-contract-technical-details.component';
import {PolicyContractSharedModule} from './components/shared/policy-contract-shared.module';
import {CompanySharedModule} from '../company/components/shared/company-shared.module';
import {BrokerContractSharedModule} from '../broker-contract/components/shared/broker-contract-shared.module';
import {PolicyCessionModule} from '../policy-cession/policy-cession.module';
import {TurnoverReportingSectionComponent} from './components/sections/kuke/turnover-reporting-section.component';
import {StatementsSectionComponent} from './components/sections/kuke/statements-section.component';
import {PolicyContractPreviewComponent} from './policy-contract-preview.component';

import {CrPolicyProductComponent} from './components/products/cr-policy-product.component';
import {KukePolicyProductComponent} from './components/products/kuke-policy-product.component';

import {PolicyContractDataSectionComponent} from './components/sections/policy-contract-data-section.component';
import {PremiumSectionComponent} from './components/sections/premium-section.component';
import {KukeScopeOfCoverSectionComponent} from './components/sections/kuke/kuke-scope-of-cover-section.component';
import {IndemnificationSectionComponent} from './components/sections/indemnification-section.component';
import {CoveredTransactionSectionComponent} from './components/sections/kuke/covered-transaction-section.component';
import {CollateralsSectionComponent} from './components/sections/collaterals-section.component';
import {InsuredsSectionComponent} from './components/sections/insureds-section.component';
import {BankAccountSectionComponent} from './components/sections/bank-account-section.component';
import {CreditLimitsSectionComponent} from './components/sections/kuke/credit-limits-section.component';
import {OtherStandardConditionsSectionComponent} from './components/sections/other-standard-conditions-section.component';
import {TerminationSectionComponent} from './components/sections/termination-section.component';
import {CredendoCountriesSectionComponent} from './components/sections/cr/credendo-countries-section.component';
import {CountriesSectionComponent} from './components/sections/countries-section.component';
import {CountryGroupsSectionComponent} from './components/sections/country-groups-section.component';
import {SpecialClauseSectionComponent} from './components/sections/special-clause-section.component';
import {SpecialRiskSectionComponent} from './components/sections/special-risk-section.component';
import {BonusMalusSectionComponent} from './components/sections/bonus-malus-section.component';
import {ImportersSectionComponent} from './components/sections/kuke/importers-section.component';
import {ExportersSectionComponent} from './components/sections/kuke/exporters-section.component';
import {InvestorsSectionComponent} from './components/sections/kuke/investors-section.component';
import {TechnicalSectionComponent} from './components/sections/technical-section.component';
import {DocumentsSectionComponent} from './components/sections/documents-section.component';
import {PolicyCollectionSectionComponent} from './components/sections/kuke/policy-collection-section.component';
import {SpecialClausesCommentSectionComponent} from './components/sections/special-clauses-comment-section.component';
import {FlInsurerSectionComponent} from './components/sections/cr/fl-insurer-section.component';
import {RiskAssessmentSectionComponent} from './components/sections/cr/risk-assessment-section.component';
import {CollectionCompaniesSectionComponent} from './components/sections/cr/collection-companies-section.component';
import {TreatiesSectionComponent} from './components/sections/cr/treaties-section.component';
import {CaptiveProductComponent, ExcessOfLossProductComponent, TopUpProductComponent} from './components/products/cr';
import {
  KomEpProductComponent,
  KomEuProductComponent,
  KomFeProductComponent,
  KomFkProductComponent,
  KomOpProductComponent,
  KomPbProductComponent,
  KomSpProductComponent,
} from './components/products/kuke/kom';
import {
  GspIbzProductComponent,
  GspKdProductComponent,
  GspKnProductComponent,
  GspLfProductComponent,
  GspPbgProductComponent,
  GspPiProductComponent,
  GspPnsProductComponent,
  GspPnwProductComponent,
  GspWnProductComponent,
} from './components/products/kuke/gsp';
import {SalesRepSharedModule} from '../sales-rep/components/shared/sales-rep-shared.module';
import {BondSharedModule} from '../bond/components/shared/bond-shared.module';
import {CustomFormModule} from '../../common/custom-form/custom-form.module';
import {PolicyLimitListModule} from '../policy-limit-list/policy-limit-list.module';
import {TabViewModule} from 'primeng/tabview';
import {PolicyClauseModule} from './clause/policy-clause.module';
import {BonusMalusRequestDetailsComponent} from './components/premium-return/bonus-malus-request-details.component';
import {PolicyContractInfoComponent} from './components/shared/policy-contract-info.component';
import {BonusMalusRequestListComponent} from './components/premium-return/bonus-malus-request-list.component';
import {ExclusionsSectionComponent} from './components/sections/exclusions-section.component';
import {KukeSpecialRiskSectionComponent} from './components/sections/kuke/kuke-special-risk-section.component';
import {KukeDatesSectionComponent} from './components/sections/kuke/kuke-dates-section.component';
import {KukePolicyContractDataSectionComponent} from './components/sections/kuke/kuke-policy-contract-data-section.component';
import {PolicyYearsSectionComponent} from './components/sections/kuke/policy-years-section.component';
import {KukePremiumSectionComponent} from './components/sections/kuke/kuke-premium-section.component';
import {KukeIndemnificationSectionComponent} from './components/sections/kuke/kuke-indemnification-section.component';
import {KukeCountriesSectionComponent} from './components/sections/kuke/kuke-countries-section.component';
import {KukeNnLimitsSectionComponent} from './components/sections/kuke/kuke-nn-limits-section.component';
import {KukeInstallmentsSectionComponent} from './components/sections/kuke/kuke-installments-section.component';
import {KukePolicyContractInfoComponent} from './components/sections/kuke/kuke-policy-contract-info.component';
import {KukeInsuredsSectionComponent} from './components/sections/kuke/kuke-insureds-section.component';
import {KukePolicyOffersComponent} from './components/sections/kuke/kuke-policy-offers.component';
import {InquirySharedModule} from '../inquiry/shared/inquiry-shared.module';
import {KukePolicyTabLayoutComponent} from './components/kuke-policy-tab-layout.component';
import {KukeSpecialClauseSectionComponent} from './components/sections/kuke/kuke-special-clause-section.component';
import {KukeIndividualClauseSectionComponent} from './components/sections/kuke/kuke-individual-special-clause-section.component';
import {KukePolicyInquiriesSectionComponent} from './components/sections/kuke/kuke-policy-inquiries-section.component';
import {KukeTerminationSectionComponent} from './components/sections/kuke-termination-section.component';
import {KukeLimitListSectionComponent} from './components/sections/kuke-limit-list-section.component';
import {PolicyLimitListSettingsSectionComponent} from './components/sections/policy-limit-lists-settings-section.component';
import {PolicyDeclarationDetailsComponent} from './policy-declaration-details.component';
import {PolicyDeclarationImportComponent} from './components/sections/kuke/policy-declaration-import.component';
import {PolicyMonitoringVersionDetailsComponent} from './policy-monitoring-version-details.component';
import {DeclarationSharedModule} from '../declaration/shared/declaration-shared.module';
import {MehibScopeOfCoverSectionComponent} from './components/sections/mehib/mehib-scope-of-cover-section.component';
import {MehibPolicyProductComponent} from './components/products/mehib-policy-product.component';
import {StPolicyProductComponent} from './components/products/mehib/st/st-policy-product.component';
import {MltPolicyProductComponent} from './components/products/mehib/mlt/mlt-policy-product.component';
import {
  PolicyContractDetailsGuard,
  PolicyContractPreviewGuard,
  PolicyContractTechnicalDetailsGuard,
  PolicyContractVersionSearchGuard,
  PolicyDeclarationDetailsGuard,
} from './_guards';
import {PolicyCoInsurersSectionComponent} from './components/sections/cr/policy-co-insurers-section.component';
import {EcgPolicyClauseSectionComponent} from './components/sections/ecg/ecg-policy-clause-section.component';
import {CrSingleRiskProductComponent} from './components/products/cr/cr-single-risk-product.component';
import {DebtorBuyerUltimateUserSectionComponent} from './components/sections/mehib/debtor-buyer-ultimate-user-section.component';
import {MltForeignInvestmentProductComponent} from './components/products/mehib/mlt/mlt-foreign-investment-product.component';
import {MltBuyerCreditProductComponent} from './components/products/mehib/mlt/mlt-buyer-credit-product.component';
import {MltSupplierCreditProductComponent} from './components/products/mehib/mlt/mlt-supplier-credit-product.component';
import {MltSupplierCreditDiscountingProductComponent} from './components/products/mehib/mlt/mlt-supplier-credit-discounting-product.component';
import {MltTiedAidProductComponent} from './components/products/mehib/mlt/mlt-tied-aid-product.component';
import {MltPreshipmentRiskProductComponent} from './components/products/mehib/mlt/mlt-preshipment-risk-product.component';
import {SponsorsSectionComponent} from './components/sections/kuke/sponsors-section.component';
import {TransactionPartiesSectionComponent} from './components/sections/mehib/transaction-parties-section.component';
import {MehibFiCoveredTransactionSectionComponent} from './components/sections/mehib/mehib-fi-covered-transaction-section.component';
import {PolicyDrawdownSummaryComponent} from './components/drawdowns/policy-drawdown-summary.component';
import {PolicyDrawdownDetailsComponent} from './components/drawdowns/policy-drawdown-details.component';
import {PolicyDrawdownPaymentsComponent} from './components/drawdowns/policy-drawdown-payments.component';
import {PolicyDrawdownScheduleComponent} from './components/drawdowns/policy-drawdown-schedule.component';
import {BokAdministrationSectionComponent} from './components/sections/kuke/bok-administration-section.component';
import {PolicyContractReportingPeriodsGuard} from './_guards/policy-contract-reporting-periods.guard';
import {PolicyContractReportingPeriodsDetailsComponent} from './components/policy-contract-reporting-periods-details.component';
import {MehibCoveredTransactionSectionComponent} from './components/sections/mehib/mehib-covered-transaction-section.component';
import {EcgPolicyProductComponent} from './components/products/ecg/ecg-policy-product.component';
import {EcgBonusMalusSectionComponent} from './components/sections/ecg/ecg-bonus-malus-section.component';
import {DiscretionaryCoverSectionComponent} from './components/sections/discretionary-cover-section.component';
import {EcgScopeOfCoverSectionComponent} from './components/sections/ecg/ecg-scope-of-cover-section.component';
import {EcgTopUpPolicyProductComponent} from './components/products/ecg/ecg-top-up-policy-product.component';
import {EcgPolicyInquiriesSectionComponent} from './components/sections/kuke/ecg-policy-inquiries-section.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    SharedComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule,
    CompanyModule,
    CompanySharedModule,
    BrokerContractSharedModule,
    SalesRepSharedModule,
    DocumentModule,
    PolicyContractSharedModule,
    PolicyCessionModule,
    BsDropdownModule,
    BondSharedModule,
    PolicyLimitListModule,
    CustomFormModule,
    PolicyClauseModule,
    TabViewModule,
    DeclarationSharedModule,
    InquirySharedModule,
    // InquiryFormsModule,
  ],
  declarations: [
    PolicyContractDetailsComponent,
    PolicyContractVersionSearchComponent,
    PolicyContractTechnicalDetailsComponent,
    PolicyContractCompanyListComponent,
    EmbeddablePolicySearchComponent,
    OtherStandardConditionsSectionComponent,
    TerminationSectionComponent,
    KukeTerminationSectionComponent,
    KukeLimitListSectionComponent,
    PolicyContractPreviewComponent,
    PolicyTabLayoutComponent,
    PolicyDeclarationDetailsComponent,
    PolicyDeclarationImportComponent,
    PolicyDrawdownSummaryComponent,
    PolicyDrawdownDetailsComponent,
    PolicyDrawdownPaymentsComponent,
    PolicyDrawdownScheduleComponent,
    PolicyMonitoringVersionDetailsComponent,
    PolicyContractReportingPeriodsDetailsComponent,
    PolicyYearsSectionComponent,
    KukePolicyTabLayoutComponent,
    KukePremiumSectionComponent,
    KukeIndemnificationSectionComponent,
    KukeCountriesSectionComponent,
    KukeNnLimitsSectionComponent,
    KukeInstallmentsSectionComponent,
    KukeInsuredsSectionComponent,
    KukePolicyOffersComponent,
    KukeSpecialClauseSectionComponent,
    KukeIndividualClauseSectionComponent,
    KukePolicyInquiriesSectionComponent,

    // PRODUCT CONTAINERS
    CrPolicyProductComponent,
    MehibPolicyProductComponent,
    KukePolicyProductComponent,
    // CR PRODUCTS CONTAINERS
    ExcessOfLossProductComponent,
    TopUpProductComponent,
    CaptiveProductComponent,
    CrSingleRiskProductComponent,
    // KUKE PRODUCTS CONTAINERS
    KomOpProductComponent,
    KomEpProductComponent,
    KomPbProductComponent,
    KomFeProductComponent,
    KomFkProductComponent,
    KomSpProductComponent,
    KomEuProductComponent,
    GspKnProductComponent,
    GspKdProductComponent,
    GspIbzProductComponent,
    GspPiProductComponent,
    GspWnProductComponent,
    GspLfProductComponent,
    GspPnwProductComponent,
    GspPnsProductComponent,
    GspPbgProductComponent,
    // MEHIB PRODUCTS CONTAINERS
    StPolicyProductComponent,
    MltPolicyProductComponent,
    MltForeignInvestmentProductComponent,
    MltBuyerCreditProductComponent,
    MltSupplierCreditDiscountingProductComponent,
    MltSupplierCreditProductComponent,
    MltTiedAidProductComponent,
    MltPreshipmentRiskProductComponent,
    // ECG PRODUCTS CONTAINERS
    EcgPolicyProductComponent,
    EcgTopUpPolicyProductComponent,
    // CR SECTIONS
    FlInsurerSectionComponent,
    PolicyCoInsurersSectionComponent,
    RiskAssessmentSectionComponent,
    CollectionCompaniesSectionComponent,
    TreatiesSectionComponent,

    // KUKE SECTIONS
    ExportersSectionComponent,
    ImportersSectionComponent,
    InvestorsSectionComponent,
    SponsorsSectionComponent,
    PolicyCollectionSectionComponent,
    CollateralsSectionComponent,
    KukeScopeOfCoverSectionComponent,
    MehibScopeOfCoverSectionComponent,
    CreditLimitsSectionComponent,
    CoveredTransactionSectionComponent,
    KukeDatesSectionComponent,
    TurnoverReportingSectionComponent,
    KukePolicyContractDataSectionComponent,
    PolicyYearsSectionComponent,
    StatementsSectionComponent,
    KukePolicyContractInfoComponent,
    PolicyLimitListSettingsSectionComponent,
    BokAdministrationSectionComponent,

    // CR, KUKE SECTIONS
    PolicyContractDataSectionComponent,
    PremiumSectionComponent,
    IndemnificationSectionComponent,
    InsuredsSectionComponent,
    BankAccountSectionComponent,
    CredendoCountriesSectionComponent,
    CountriesSectionComponent,
    ExclusionsSectionComponent,
    CountryGroupsSectionComponent,
    SpecialClauseSectionComponent,
    SpecialRiskSectionComponent,
    KukeSpecialRiskSectionComponent,
    SpecialClausesCommentSectionComponent,
    DocumentsSectionComponent,
    TechnicalSectionComponent,
    BonusMalusSectionComponent,
    PolicyContractInfoComponent,
    BonusMalusRequestDetailsComponent,
    BonusMalusRequestListComponent,

    // Mehib SECTIONS
    DebtorBuyerUltimateUserSectionComponent,
    TransactionPartiesSectionComponent,
    MehibFiCoveredTransactionSectionComponent,
    MehibCoveredTransactionSectionComponent,

    // Ecg Sections
    EcgPolicyClauseSectionComponent,
    EcgBonusMalusSectionComponent,
    DiscretionaryCoverSectionComponent,
    EcgScopeOfCoverSectionComponent,
    EcgPolicyInquiriesSectionComponent,
  ],
  exports: [
    PolicyContractDetailsComponent,
    EmbeddablePolicySearchComponent,
    PolicyContractInfoComponent,
    BonusMalusRequestDetailsComponent,
    BonusMalusRequestListComponent,
    KukeNnLimitsSectionComponent,
  ],
  providers: [
    PolicyContractViewService,
    PolicyContractDetailsGuard,
    PolicyContractPreviewGuard,
    PolicyContractService,
    PolicyContractTechnicalDetailsGuard,
    PolicyContractReportingPeriodsGuard,
    PolicyContractVersionSearchGuard,
    PolicyDeclarationDetailsGuard,
    PolicyMonitoringVersionService,
  ],
})
export class PolicyModule {}

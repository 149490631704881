import {Component, Inject, Input, Optional, ViewChild} from '@angular/core';
import {BondingContractInquiryVersionDto, BusinessUnitIdDto, UserSimpleDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm, NgModelGroup} from '@angular/forms';
import {ContactPersonService, LoggedUserService, RouterService, UserService} from '../../../../bonding_shared/services';
import {AcquisitionMethod, BusinessObjectType, Source} from '../../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {BusinessUnit} from '../../../../bonding_shared/model/business-unit';
import {RepoDocumentListComponent} from '../../../repository-documents';

@Component({
  selector: 'bonding-contract-inquiry-tab-main-kuke',
  templateUrl: './bonding-contract-inquiry-tab-main-kuke.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryTabMainKukeComponent {
  @Input() showErrors = false;
  @Input() multiProduct = false;
  @Input() readonly = false;
  @ViewChild('tabModel', {static: true}) tabModel: NgModelGroup;

  inquiryVersion: BondingContractInquiryVersionDto;
  salesUsers: UserSimpleDto[] = null;

  readonly bondingInquiryBO = BusinessObjectType.BONDING_CONTRACT_INQUIRY;
  readonly salesmanAcquisitionMethod = AcquisitionMethod.SALESMAN;
  readonly Source = Source;
  readonly BusinessUnit = BusinessUnit;
  readonly RepoDocumentListComponent = RepoDocumentListComponent;

  @Input() set version(v: BondingContractInquiryVersionDto) {
    this.inquiryVersion = v;
    this.loadSalesUsers();
  }

  constructor(
    public router: RouterService,
    private contactPersonService: ContactPersonService,
    private loggedUserService: LoggedUserService,
    private translateService: TranslateService,
    @Optional() @Inject('verifyFinancialDataConfirm') public verifyFinancialDataConfirm: boolean,
    private userService: UserService
  ) {}

  get inquiry() {
    return this.inquiryVersion && this.inquiryVersion.inquiry;
  }

  get portal(): boolean {
    return this.loggedUserService.portal;
  }

  get commentLabel(): string {
    if (this.portal) {
      return this.translateService.instant('common.comment');
    }
    return this.translateService.instant('common.externalComment');
  }

  protected loadSalesUsers() {
    if (!this.inquiry) {
      this.salesUsers = null;
      return;
    }
    const businessUnit: BusinessUnitIdDto = this.inquiry.businessUnit;
    this.userService.getSalesUsers(businessUnit).subscribe((users) => {
      this.salesUsers = users;
    });
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm, UntypedFormBuilder} from '@angular/forms';
import {RouterService} from '../../bonding_shared/services/router-service';
import {ActivatedRoute} from '@angular/router';
import {LimitGuiService} from './shared/services/limit-gui.service';
import {DetailsView} from '../../bonding_shared/components/details-view/details-view';
import {
  DictionaryBaseDto,
  FileImportDto,
  LimitMassDecisionDto,
  LimitMassDecisionMetadataDto,
  LimitRequestCriteriaDto,
} from '../../bonding_shared/model/dtos';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {LimitService} from '../../bonding_shared/services/limit.service';
import {BusinessPropertiesService} from '../../bonding_shared/services/business-properties.service';
import {
  ImportType,
  LimitElementaryRight,
  LimitRequestStatus,
  ProductLine,
} from '../../bonding_shared/model/dictionary-ids';
import {ImportListComponent} from '../import/import-list.component';
import {IMultiSelectSettings} from '../../bonding_shared/components/multiselect-dropdown/multiselect-dropdown';
import {StringUtils} from '../../bonding_shared/utils';
import {CompanyService, LoggedUserService} from '../../bonding_shared';

@Component({
  selector: 'limit-mass-decision',
  templateUrl: './limit-mass-decision.component.pug',
})
export class LimitMassDecisionComponent extends DetailsView implements OnInit {
  decision: FileImportDto;
  selectedFile: File;
  multiSelectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 0,
    buttonClasses: 'bon-btn-warning',
    minWidth: '400px',
  };
  fixedBuyer = false;
  params: LimitMassDecisionViewParams;
  @ViewChild(ImportListComponent) fileList: ImportListComponent;

  @ViewChild('ngForm', {static: true}) ngForm: NgForm;

  readonly ImportType = ImportType;
  readonly ProductLine = ProductLine;
  readonly LimitElementaryRight = LimitElementaryRight;
  readonly fullWriteAccess = this.loggedUserService.hasRight(LimitElementaryRight.IMPORT_FILE_LIMIT_MASS_DECISION);
  public companyName: string;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    public guiService: LimitGuiService,
    private limitService: LimitService,
    private loggedUserService: LoggedUserService,
    public router: RouterService,
    protected growlService: GrowlService,
    protected businessPropertiesService: BusinessPropertiesService
  ) {
    super(growlService);
    this.initDecision();
  }

  initDecision() {
    this.decision = <LimitMassDecisionDto>{
      type: <DictionaryBaseDto>{id: ImportType.LIMIT_REQUESTS_FOR_MASS_DECISION},
      metadata: <LimitMassDecisionMetadataDto>{},
    };
  }

  ngOnInit() {
    this.form = this.ngForm.form;
    this.route.queryParams.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: LimitMassDecisionViewParams) {
    this.params = params;
    this.companyService.getCompanyBaseDto(Number(this.params.companyId)).subscribe((company) => {
      this.companyName = company.registrationName;
    });
    this.generateFileOnInit();
  }

  generateFileOnInit() {
    if (this.params.companyId) {
      this.fixedBuyer = true;
      this.generateFileForLimitMassReduction(+this.params.companyId);
    }
  }

  generateFileForLimitMassReduction(companyId: number) {
    const criteria = <LimitRequestCriteriaDto>{
      buyerCompanyId: +companyId,
      status: {id: LimitRequestStatus.DONE},
      last: true,
    };
    this.limitService.export(
      criteria,
      'limits.xlsx',
      (errorMsg) => this.downloadErrorCallback(errorMsg),
      (file) => {
        if (file) {
          this.selectedFile = file;
          const reader = new FileReader();
          reader.readAsDataURL(this.selectedFile);
          reader.onload = (e: any) => {
            this.decision.content = e.target.result.split(',')[1];
          };
          this.decision.fileName = this.selectedFile.name;
        }
      }
    );
  }

  onCancel() {
    this.initDecision();
    this.clearFile();
  }

  generate() {
    this.clearErrors();
    if (!this.form.valid || !this.selectedFile) {
      StringUtils.logFormInvalidFields(this.form);
      this.showFormError();
      return;
    }
    this.inProgress = true;
    this.limitService.massDecision(this.decision).subscribe({
      next: (r) => {
        this.fileList?.refreshFileTable();
        this.inProgress = false;
        this.showSavedMsg();
        this.clearFile();
      },
      error: (error) => this.handleServerError(error),
    });
  }

  loadFile() {
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (e: any) => {
      this.decision.content = e.target.result.split(',')[1];
      this.decision.fileName = this.selectedFile.name;
    };
  }

  clearFile() {
    this.selectedFile = undefined;
    this.decision.content = undefined;
    this.decision.fileName = undefined;
  }

  get mdata() {
    return this.decision.metadata;
  }
}

export class LimitMassDecisionViewParams {
  companyId?: number | string;
}

import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params} from '@angular/router';
import {RouterService} from '../../bonding_shared/services/router-service';
import {InvoiceDataComponent} from './components/invoice-data.component';
import {
  CompanyBaseDto,
  CompanySimpleDto,
  DictionaryBaseDto,
  InvoiceDto,
  InvoiceItemCriteriaDto,
  InvoiceNumberingBagBaseDto,
  ReportDefinitionSimpleDto,
  SearchCriteria,
  TemplateSimpleDto,
} from '../../bonding_shared/model/dtos';
import {InvoiceService} from '../../bonding_shared/services/invoice.service';
import {TemplateService} from '../../bonding_shared/services/template.service';
import {InvoiceItemGuiService} from '../invoice-item/services/invoice-item-gui.service';
import {
  AppProperty,
  BusinessObjectType,
  ContactNoteElementaryRight,
  DocumentType,
  ElementaryRight,
  InvoiceCategory,
  InvoiceItemStatus,
  InvoiceStatus,
} from '../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {GrowlService} from '../../bonding_shared/services/growl/growl.service';
import {ContractService} from '../../bonding_shared/services/contract.service';
import {AbstractInvoiceDetailsComponent} from '../../bonding_shared/components/shared/abstract-invoice-details.component';
import {
  BusinessPropertiesService,
  BusinessReportService,
  ContractVersionService,
  DictionaryBaseService,
  DocumentService,
  LoggedUserService,
  PropertyService,
} from '../../bonding_shared/services';
import {InvoiceGuiService} from './services/invoice-gui.service';
import {DictionaryUtils} from '../../bonding_shared/utils/dictionary-utils';
import {ConfirmDialogComponent} from '../../bonding_shared/components/confirm-dialog';
import {LifecycleDropdownSimpleComponent} from '../../bonding_shared/components/lifecycle-dropdown';
import {BackendError} from '../../bonding_shared/model';
import {ListEmitters} from '../../bonding_shared/components/details-view/list-emitters';
import {StringUtils} from '../../bonding_shared';
import {InvoiceItemMassService} from '../../bonding_shared/services/invoice-item-mass.service';

@Component({
  selector: 'invoice-details',
  templateUrl: 'invoice-details.component.html',
})
export class InvoiceDetailsComponent extends AbstractInvoiceDetailsComponent {
  _invoiceDataCmp: InvoiceDataComponent;

  get invoiceDataCmp(): InvoiceDataComponent {
    return this._invoiceDataCmp;
  }

  @ViewChild(InvoiceDataComponent, {static: false})
  set invoiceDataCmp(cmp: InvoiceDataComponent) {
    if (cmp) {
      this._invoiceDataCmp = cmp;
    }
  }
  @ViewChild('confirmDialog', {static: true}) confirmDialog: ConfirmDialogComponent;
  @ViewChild('infoDialog', {static: true}) infoDialog: ConfirmDialogComponent;
  @ViewChild('newClientSelectionDialog', {static: true}) newClientSelectionDialog: ConfirmDialogComponent;
  @ViewChild(LifecycleDropdownSimpleComponent, {static: true})
  lifecycleDropdownSimpleComponent: LifecycleDropdownSimpleComponent;

  public invoice: InvoiceDto = <InvoiceDto>{};
  protected invoiceAutomatically = false;
  public invoiceInitialized = false;
  public selectedNewClient: CompanySimpleDto;
  protected currentStatus: DictionaryBaseDto;

  readonly BusinessObjectTypeINVOICE = BusinessObjectType.INVOICE;
  readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  readonly SEND_LAST_EMAIL_STATUSES = [
    InvoiceStatus.SENT,
    InvoiceStatus.SENT_MANUALLY,
    InvoiceStatus.PAID,
    InvoiceStatus.COMM_GESTURE,
    InvoiceStatus.MIGRATION,
  ];

  protected templates: TemplateSimpleDto[];
  protected reportDefinitions: ReportDefinitionSimpleDto[];
  public listEmitters: ListEmitters = new ListEmitters();
  private hideSpecification: boolean;

  constructor(
    public invoiceService: InvoiceService,
    protected route: ActivatedRoute,
    private templateService: TemplateService,
    private routerService: RouterService,
    private invoiceGuiService: InvoiceGuiService,
    private invoiceItemGuiService: InvoiceItemGuiService,
    private invoiceItemMassService: InvoiceItemMassService,
    private contractService: ContractVersionService,
    protected propertyService: PropertyService,
    protected businessPropertiesService: BusinessPropertiesService,
    protected translateService: TranslateService,
    private documentService: DocumentService,
    protected growlService: GrowlService,
    protected dictionaryBaseService: DictionaryBaseService,
    private location: Location,
    protected businessReportService: BusinessReportService,
    protected loggedUserService: LoggedUserService,
    private cd: ChangeDetectorRef
  ) {
    super(
      propertyService,
      businessPropertiesService,
      translateService,
      growlService,
      dictionaryBaseService,
      route,
      loggedUserService,
      businessReportService
    );
    this.listEmitters.selectorNameList = ['Client'];
    this.listEmitters.initializeSelectorEmitters(true);
  }

  initializeView(params: Params, force?: boolean) {
    super.initializeView(params);
    const id = +params['id'];
    if (id === this.invoice.id && !force) {
      return;
    }

    this.updateInProgress();

    const correctedId = +params['correctedId'];
    const nullify = params['nullify'] ? JSON.parse(params['nullify']) : undefined;
    const newItemStatusId = params['newItemStatusId'] ? +params['newItemStatusId'] : undefined;
    const newClientId = params['newClientId'] ? +params['newClientId'] : undefined;
    this.hideSpecification = params['hideSpecification'] ? JSON.parse(params['hideSpecification']) : undefined;

    if (id > 0) {
      this.loadInvoice(id);
    } else if (correctedId > 0) {
      this.loadCorrection(correctedId, nullify, id, newItemStatusId, newClientId);
    } else if (id === 0 && correctedId === 0) {
      this.newInvoiceFromItems();
    } else if (id === 0) {
      this.newEmptyInvoice();
    }
  }

  private updateInProgress() {
    this.inProgress = !(
      this.invoiceInitialized &&
      (!this.invoiceAutomatically || (this.invoiceDataCmp && this.invoiceDataCmp.invoiceAutomaticallyFinished))
    );
  }

  private newEmptyInvoice() {
    this.invoice = <InvoiceDto>{};
    this.invoiceInitialized = true;
    this.updateInProgress();
  }

  private loadInvoice(id: number) {
    this.invoiceService.getInvoice(id).subscribe(
      (invoice) => this.setInvoice(invoice),
      (error) => {
        console.log('Error during invoiceService.getInvoice for id = ' + id);
        this.serverErrors = error;
      },
      () => {
        this.invoiceInitialized = true;
        this.updateInProgress();
      }
    );
  }

  private loadCorrection(
    correctedId: number,
    nullify: boolean,
    id: number,
    newItemStatusId: number,
    newClientId: number
  ) {
    this.invoiceService.getInvoiceCorrection(correctedId, nullify, newItemStatusId, newClientId).subscribe(
      (invoice) => this.setInvoice(invoice),
      (error) => {
        console.log('Error during invoiceService.getInvoiceCorrection for correctedId = ' + id);
        this.serverErrors = error;
      },
      () => {
        this.invoiceInitialized = true;
        this.updateInProgress();
      }
    );
  }

  private newInvoiceFromItems() {
    console.log('createInvoice with searched unInvoiced items');
    // for development ease, this should not ever happen in production, but happens during dev
    if (!this.invoiceItemGuiService.trasnsferDataProvider.searchCriteria.criteria.client) {
      this.routerService.toInvoiceItemSearch(this.categoryId);
    }

    const company: CompanyBaseDto = this.invoiceItemGuiService.trasnsferDataProvider.searchCriteria.criteria.client;
    if (!company || !company.address || !company.address.country) {
      this.newEmptyInvoice();
      this.growlService.error('invoice.details.newInvoiceFromItemsError');
      return;
    }

    this.invoiceAutomatically = true;

    this.invoiceService.getInvoiceInitialVersion(company.address.country.id, company.id, this.categoryId).subscribe(
      (invoice) => this.setInvoice(invoice),
      (error) => {
        console.log('Error during invoiceService.getInvoiceInitialVersion');
        this.serverErrors = error;
      },
      () => {
        this.invoiceInitialized = true;
        this.updateInProgress();
      }
    );
  }

  onSave() {
    delete this.serverErrors;
    this.invoiceDataCmp.showErrors = true;
    if (!this.invoiceDataCmp.form.valid) {
      StringUtils.logFormInvalidFieldsRecursive(this.invoiceDataCmp.form);
      this.showFormError();
      return;
    }

    return new Promise((resolve, reject) => {
      if (DictionaryUtils.inArr(this.invoiceDataCmp.invoice.status, this.REMOVE_STATUSES)) {
        this.confirmDialog.openAndExecuteOnConfirm(
          'invoice.details.removeConfirmationTitle',
          'invoice.details.removeConfirmationMessage',
          this.save.bind(this),
          reject
        );
      } else if (
        DictionaryUtils.equalsDictAndId(this.invoiceDataCmp.invoice.status, InvoiceStatus.ISSUED) &&
        !this.invoiceDataCmp.invoice.number
      ) {
        // Issue invoice: DRAFT -> PENDING
        const titleKey = this.invoiceDataCmp.invoice.correctedInvoice
          ? 'invoice.details.issueConfirmationTitleCreditNote'
          : 'invoice.details.issueConfirmationTitleInvoice';
        const messageKey = this.invoiceDataCmp.invoice.correctedInvoice
          ? 'invoice.details.issueConfirmationMessageCreditNote'
          : 'invoice.details.issueConfirmationMessageInvoice';
        this.confirmDialog.openAndExecuteOnConfirm(titleKey, messageKey, this.save.bind(this), reject);
      } else {
        this.save();
      }
    });
  }

  onCancel() {
    if (this.invoiceDataCmp && this.invoiceDataCmp.listEmitters) {
      this.invoiceDataCmp.listEmitters.closeAllSelectors();
    }
    if (this.invoiceInitialized) {
      super.onCancel(this.route);
    } else {
      this.location.back();
    }
  }

  onPrintSelected(template: TemplateSimpleDto) {
    if (!this.invoice) {
      return;
    }
    if (this.draft) {
      this.documentService.generatePdf(
        template.id,
        this.invoice.id,
        (template.name || 'Invoice') + '.pdf',
        false,
        (error) => {
          try {
            this.serverErrors = JSON.parse(error);
          } catch (e) {
            this.serverErrors = error;
          }
        }
      );
    } else if (this.invoiceDataCmp.editionBlocked()) {
      this.documentService.generateAndSave(template.id, this.invoice.id).subscribe(
        () => {
          this.invoiceDataCmp.documentListComponent.search();
          this.growlService.notice('invoice.details.documentAdded', 'invoice.details.documentSuccess');
        },
        (error) => (this.serverErrors = error)
      );
    } else {
      this.routerService.toDocumentDetailsNew(template.id, this.invoice.id);
    }
  }

  public get draft() {
    return this.invoice.status.id === InvoiceStatus.DRAFT;
  }

  get newDocumentDisabled() {
    return (!this.draft && !this.loggedUserService.hasRight(ElementaryRight.INVOICE_CREATE_UPDATE)) || this.inProgress;
  }

  showSavedMsg() {
    this.growlService.notice('invoice.details.saved');
  }

  showErrorMsg() {
    this.growlService.error('invoice.details.saveError');
  }

  showFormError() {
    this.growlService.error('invoice.details.formErrors');
  }

  checkSaveResult(error: any) {
    console.log('Save error: ', error);
    this.inProgress = false;
    this.serverErrors = error;
    this.invoice.status = this.currentStatus;
    console.log('Client: ', this.invoiceDataCmp.invoice.client);
  }

  get createCorrectionVisible(): boolean {
    return !!(
      this.invoice.number &&
      this.invoice.id &&
      this.invoice.numberingBag &&
      this.invoice.numberingBag.correctionEnabled &&
      ((this.appProperties && this.appProperties[AppProperty.INVOICE_BROKER_NOTE_CORRECTION]) ||
        this.categoryId !== InvoiceCategory.BROKER)
    );
  }

  get createCorrectionWithoutSpecificationVisible(): boolean {
    return this.createCorrectionVisible && this.invoice.numberingBag.correctionWithoutSpecificationEnabled;
  }

  createCorrection() {
    console.log('create correction');
    this.routerService.toInvoiceCorrection(<InvoiceCategory>this.categoryId, this.invoice.id, false);
  }

  createCorrectionWithoutSpecification() {
    console.log('create correction without specification');
    this.routerService.toInvoiceCorrection(
      <InvoiceCategory>this.categoryId,
      this.invoice.id,
      false,
      undefined,
      undefined,
      true
    );
  }

  get createCorrectionToZeroVisible(): boolean {
    return !!(
      this.invoice.number &&
      this.invoice.id &&
      this.invoice.netAmount &&
      this.invoice.numberingBag &&
      this.invoice.numberingBag.correctionToZeroEnabled &&
      (this.appProperties[AppProperty.INVOICE_BROKER_NOTE_CORRECTION] || this.categoryId !== InvoiceCategory.BROKER)
    );
  }

  createCorrectionToZero(doRecalculation: boolean) {
    console.log('create correction');
    const newItemStatusId = !doRecalculation ? InvoiceItemStatus.MANUAL : undefined;
    this.routerService.toInvoiceCorrection(<InvoiceCategory>this.categoryId, this.invoice.id, true, newItemStatusId);
  }

  get createNewClientCorrectionVisible(): boolean {
    return !!(
      this.invoice.number &&
      this.invoice.id &&
      (this.appProperties[AppProperty.INVOICE_BROKER_NOTE_CORRECTION] || this.categoryId !== InvoiceCategory.BROKER)
    );
  }

  newClientSelection() {
    return new Promise(() => {
      this.newClientSelectionDialog.openAndExecuteOnConfirm(
        'invoice.details.newClientSelectionTitle',
        '',
        this.moveToOtherClientAccepted.bind(this),
        this.moveToOtherClientCancel.bind(this)
      );
    });
  }

  public onNewClientSelect(company: CompanySimpleDto) {
    this.selectedNewClient = company;
  }

  protected moveToOtherClientAccepted() {
    if (this.selectedNewClient) {
      this.routerService.toInvoiceCorrection(
        <InvoiceCategory>this.categoryId,
        this.invoice.id,
        true,
        InvoiceItemStatus.MANUAL,
        this.selectedNewClient.id
      );
    }
  }

  protected moveToOtherClientCancel() {
    this.selectedNewClient = undefined;
  }

  sendLastInvoice() {
    this.inProgress = true;
    this.invoiceService.sendLastInvoice(this.invoice.id).subscribe(
      (result) => {
        this.growlService.notice('invoice.details.emailSent', 'invoice.details.emailSuccess');
        this.invoiceDataCmp.documentListComponent.search();
        this.invoice.status = result.status;
        this.currentStatus = result.status;
        this.invoice.businessStatus = result.businessStatus;
        this.lifecycleDropdownSimpleComponent.reloadTransitions();
        this.inProgress = false;
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }

  get sendEmailDisabled() {
    return (
      this.emptyDocumentList() ||
      !this.loggedUserService.hasRight(ElementaryRight.INVOICE_SEND_EMAIL) ||
      this.inProgress
    );
  }

  protected logError(error: BackendError) {
    this.serverErrors = error;
  }

  protected invoiceDataInitialisationFinished() {
    this.updateInProgress();
  }

  getInvoiceCategoryLabel(): string {
    return this.invoiceGuiService.getInvoiceCategoryLabel(this.invoiceCategoryMap[this.categoryId], false);
  }

  private setInvoice(invoice: InvoiceDto) {
    this.invoice = invoice;
    this.currentStatus = invoice.status;
    this.serverErrors = invoice.warnings;
    this.setTemplates();
    this.loadReportDefinitions();
  }

  private setTemplates() {
    const dictionarySelectors: DictionaryBaseDto[] = [];
    dictionarySelectors.push(this.invoice.invoiceType);
    dictionarySelectors.push(this.invoice.category);

    if (this.invoice.contractLink) {
      dictionarySelectors.push(this.invoice.contractLink.linkType);
    }

    let documentType: DocumentType = DocumentType.INVOICE;

    if (
      this.invoice.status.id === InvoiceStatus.PAID &&
      this.invoice.businessUnit.countryCode === 'IT' &&
      (this.invoice.contractLink.linkType.id === BusinessObjectType.POLICY ||
        this.invoice.contractLink.linkType.id === BusinessObjectType.CONTRACT)
    ) {
      documentType = DocumentType.INVOICE_PAYMENT_CONFIRMATION;
    }

    if (this.invoice.contractLink && this.invoice.contractLink.linkType.id === BusinessObjectType.CONTRACT) {
      this.contractService.getContract(this.invoice.contractLink.contract.id).subscribe((contract) => {
        dictionarySelectors.push(contract.type);
        this.templateService
          .findByType(documentType, BusinessObjectType.INVOICE, undefined, dictionarySelectors)
          .subscribe((result) => (this.templates = result));
      });
    } else {
      this.templateService
        .findByType(documentType, BusinessObjectType.INVOICE, undefined, dictionarySelectors)
        .subscribe((result) => (this.templates = result));
    }
  }

  private loadReportDefinitions() {
    if (!this.invoice || !this.invoice.id) {
      this.reportDefinitions = [];
      return;
    }
    this.invoiceService.getReportDefinitions(this.invoice.id).subscribe(
      (reportDefinitions) => {
        this.reportDefinitions = reportDefinitions;
      },
      (error) => {
        console.log('Error during invoiceService.getReportDefinitions');
        this.serverErrors = error;
      }
    );
  }

  private save() {
    console.log('save', this.invoiceDataCmp.invoice.status);
    this.inProgress = true;
    this.serverErrors = '';
    if (this.hideSpecification !== undefined) {
      this.invoiceDataCmp.invoice.hideSpecification = this.hideSpecification;
    }

    if (this.invoiceDataCmp.invoice.numberingBag) {
      this.invoiceDataCmp.invoice.numberingBag = <InvoiceNumberingBagBaseDto>{
        id: this.invoiceDataCmp.invoice.numberingBag.id,
        pattern: this.invoiceDataCmp.invoice.numberingBag.pattern,
        calculateInterests: this.invoiceDataCmp.invoice.numberingBag.calculateInterests,
        documentType: this.invoiceDataCmp.invoice.numberingBag.documentType,
        interestWithInvoice: this.invoiceDataCmp.invoice.numberingBag.interestWithInvoice,
      };
    }

    const statusBeforeSave = this.invoiceDataCmp.invoice.status;
    if (this.invoiceDataCmp.invoice.id > 0) {
      const toBeRemoved = DictionaryUtils.inArr(statusBeforeSave, this.REMOVE_STATUSES);
      this.invoiceService.updateInvoice(this.invoiceDataCmp.invoice).subscribe(
        (invoice) => {
          if (toBeRemoved) {
            this.growlService.notice('invoice.details.removed');
            window.history.back();
          } else {
            this.setInvoice(invoice);
            // Refresh document list
            this.invoiceDataCmp.documentListComponent.search();
            this.inProgress = false;
            this.showAfterSavePopup(statusBeforeSave);
          }
        },
        (error) => this.checkSaveResult(error)
      );
    } else {
      this.invoiceService.createInvoice(this.invoiceDataCmp.invoice).subscribe(
        (invoice) => {
          this.setInvoice(invoice);
          // Refresh document list if it is already created. If not it will load current document list on init
          if (this.invoiceDataCmp && this.invoiceDataCmp.documentListComponent) {
            this.invoiceDataCmp.documentListComponent.search();
          }
          this.inProgress = false;
          this.routerService.toInvoiceDetails(this.categoryId, this.invoice.id);
          this.showAfterSavePopup(statusBeforeSave);
        },
        (error) => this.checkSaveResult(error)
      );
    }
  }

  private emptyDocumentList() {
    return !(
      this.invoiceDataCmp &&
      this.invoiceDataCmp.documentListComponent &&
      this.invoiceDataCmp.documentListComponent.documentTable.items &&
      this.invoiceDataCmp.documentListComponent.documentTable.items.length > 0
    );
  }

  private showAfterSavePopup(oldStatus: DictionaryBaseDto) {
    const newStatus = this.invoiceDataCmp.invoice.status;

    if (DictionaryUtils.equalsDictAndId(newStatus, InvoiceStatus.ISSUED)) {
      const titleKey = this.invoiceDataCmp.invoice.correctedInvoice
        ? 'invoice.details.issueReminderTitleCreditNote'
        : 'invoice.details.issueReminderTitleInvoice';
      const messageKey = this.invoiceDataCmp.invoice.correctedInvoice
        ? 'invoice.details.issueReminderMessageCreditNote'
        : 'invoice.details.issueReminderMessageInvoice';
      this.infoDialog.openAndExecuteOnConfirm(titleKey, messageKey, () => {
        this.showSavedMsg();
      });
    } else {
      this.showSavedMsg();
    }
  }

  get writeAllOffVisible() {
    return (
      this.invoice &&
      this.invoice.id &&
      this.categoryId === InvoiceCategory.BROKER &&
      this.loggedUserService.hasRight(ElementaryRight.INVOICE_MASS_WRITEALLOFF)
    );
  }

  openWriteAllOffConfirmDialog() {
    this.confirmDialog.openAndExecuteOnConfirm(
      'invoice.details.writeAllOffConfirmationTitle',
      'invoice.details.writeAllOffConfirmationMessage',
      this.writeAllOff.bind(this)
    );
  }

  writeAllOff() {
    this.inProgress = true;
    this.serverErrors = '';
    const searchCriteria = <SearchCriteria<InvoiceItemCriteriaDto>>{
      criteria: {
        invoice: {
          id: this.invoice.id,
        },
      },
    };

    this.invoiceItemMassService.writeAllOff(searchCriteria).subscribe(
      (result) => {
        this.growlService.notice('invoice.details.writeAllOffSuccess', '', {count: result});
        this.loadInvoice(this.invoice.id);
      },
      (error) => {
        this.serverErrors = error;
        this.inProgress = false;
      }
    );
  }
}

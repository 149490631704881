import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {
  AppConfigService,
  BinaryDownloaderService,
  BiService,
  BondVersionService,
  BrokerContractVersionService,
  BusinessPropertiesService,
  BusinessReportService,
  BusinessUnitService,
  CacheService,
  ClaimService,
  ClientCompanyService,
  CompanyAbstractService,
  CompanyRatingService,
  CompanyService,
  ContractService,
  ContractVersionService,
  CountryRatingService,
  CurrencyExchangeRateService,
  DashboardService,
  DeclarationFileService,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  FormatService,
  GeoDictBaseService,
  GeoDictService,
  ImageService,
  InquiryService,
  InvoiceCoreService,
  InvoiceItemService,
  InvoicePaymentService,
  InvoiceService,
  LanguageService,
  LimitService,
  LoggedUserService,
  NumberFormatService,
  OfferService,
  PolicyContractService,
  PolicyContractVersionService,
  PolicyLimitListService,
  PropertyService,
  ReportExecutionService,
  ReportService,
  RepositoryService,
  RouterService,
  SettingsService,
  SidebarModule,
  TaskService,
  TemplateService,
  ThirdPartyService,
  TopNavModule,
  UserService,
  VersionInfoService,
  VersionSummaryModule,
} from '../bonding_shared';
import {PortalAuthenticationGuard} from './portal.authentication-guard';
import {TaskModule} from '../bonding/task/task.module';
import {SharedComponentsModule} from '../bonding_shared/components/shared-components.module';
import {ReportModule} from '../bonding/report/report.module';
import {DocumentModule} from '../bonding/document/document.module';
import {DashboardModule} from '../bonding/home/home.module';
import {CompanyModule} from '../bonding/company/company.module';
import {BondModule} from '../bonding/bond/bond.module';
import {ContractModule} from '../bonding/bonding-contract/contract/contract.module';
import {TemplateDetailsGuiService} from '../bonding/document';
import {BrokerContractGuiService} from '../bonding/broker-contract/services/broker-contract-gui.service';
import {BrokerContractModule} from '../bonding/broker-contract/broker-contract.module';
import {BusinessReportModule} from '../bonding/business-report/business-report.module';
import {VersionInfoModule} from '../bonding/version-info/version-info.module';
import {RepositoryDocumentListModule} from '../bonding_shared/components/repository-document-list/repository-document-list.module';
import {PolicyModule} from '../bonding/policy/policy.module';
import {LimitModule} from '../bonding/limit/limit.module';
import {InvoiceGuiService} from '../bonding/invoice/services/invoice-gui.service';
import {InvoiceModule} from '../bonding/invoice/invoice.module';
import {InvoiceItemGuiService} from '../bonding/invoice-item/services/invoice-item-gui.service';
import {InvoiceItemModule} from '../bonding/invoice-item/invoice-item.module';
import {ClaimModule} from '../bonding/claim/claim.module';
import {DocumentGuiService} from '../bonding/document/services/document-gui.service';
import {PortalComponent} from './portal.component';
import {PortalSidebarModule} from './portal-sidebar';
import {PortalTopNavModule} from './portal-topnav';
import {BrokerModule} from './broker/broker.module';
import {
  BrokerPortfolioService,
  PasswordResetService,
  PortalCompanyService,
  PortalDeclarationService,
  PortalInvoiceService,
  PortalPolicyContractService,
  PortalPolicyContractVersionService,
  PortalRouterService,
  QuotationService,
} from './services';
import {ClientBondModule} from './clientbond/clientbond.module';
import {ClientTemplateModule} from './template/client-template.module';
import {ContractBondPortalService} from './clientbond';
import {ClientLimitModule} from './limit/client-limit.module';
import {ClientPolicyModule} from './policy/client-policy.module';
import {ClientDeclarationModule} from './declaration/client-declaration.module';
import {ClientOutstandingsModule} from './outstandings/client-outstandings.module';
import {CommonContractModule} from './contract/common-contract.module';
import {ContractSharedModule} from '../bonding/bonding-contract/contract/components/shared/contract-shared.module';
import {CompanySharedModule} from '../bonding/company/components/shared/company-shared.module';
import {ClientInvoiceModule} from './invoice/client-invoice.module';
import {InfoModule} from './info/info.module';
import {GroupModule} from '../bonding/group/group.module';
import {BsModalService} from 'ngx-bootstrap/modal';
import {InvoicePaymentModule} from '../bonding/invoice-payment/invoice-payment.module';
import {InvoicePaymentGuiService} from '../bonding/invoice-payment/services/invoice-payment-gui.service';
import {PolicyLimitListModule} from '../bonding/policy-limit-list/policy-limit-list.module';
import {BlockchainModule} from './blockchain/blockchain.module';
import {BlockchainService} from './blockchain';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ErrorInterceptor} from '../bonding_shared/utils/error.interceptor';
import {PortalUserService} from './services/portal-user.service';
import {AdminModule} from './admin/admin.module';
import {PortalInquiryService} from './services/portal-inquiry.service';
import {InquiryAbstractService} from '../bonding_shared/services/inquiry-abstract.service';
import {ClientInquiryModule} from '../../../../projects/kuke-portal/src/app/inquiry/client-inquiry.module';
import {ImportModule} from '../bonding/import/import.module';
import {PortalContactPersonService} from './services/portal-contact-person.service';
import {KukeClientPolicyModule} from '../../../../projects/kuke-portal/src/app/policy/kuke-client-policy.module';
import {EcgClientPolicyModule} from '../../../../projects/ecg-portal/src/app/policy/ecg-client-policy.module';
import {PortalOfferService} from './services/portal-offer.service';
import {PortalCollectionClaimService} from './services/portal-collection-claim.service';
import {PortalSalesRepRelationVersionService} from './services/portal-sales-rep-relation-version.service';
import {ReportExecutionModule} from '../bonding/report-execution/report-execution.module';
import {BusinessReportPortalService} from './services/portal-business-report.service';
import {PortalBondingContractOfferService} from './services/portal-bonding-contract-offer.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TopNavModule,
    SidebarModule,
    VersionSummaryModule,
    TaskModule,
    ReportModule,
    ReportExecutionModule,
    BusinessReportModule,
    DocumentModule,
    DashboardModule,
    CompanyModule,
    CompanySharedModule,
    GroupModule,
    BondModule,
    LimitModule,
    ImportModule,
    ClaimModule,
    SharedComponentsModule,
    ContractModule,
    ContractSharedModule,
    BrokerContractModule,
    VersionInfoModule,
    PolicyModule,
    InvoiceModule,
    InvoiceItemModule,
    InvoicePaymentModule,
    PortalSidebarModule,
    PortalTopNavModule,
    BrokerModule,
    ClientBondModule,
    ClientLimitModule,
    CommonContractModule,
    ClientPolicyModule,
    KukeClientPolicyModule,
    EcgClientPolicyModule,
    ClientInquiryModule,
    ClientDeclarationModule,
    ClientOutstandingsModule,
    ClientInvoiceModule,
    ClientTemplateModule,
    InfoModule,
    RepositoryDocumentListModule,
    PolicyLimitListModule,
    BlockchainModule,
    AdminModule,
  ],
  declarations: [PortalComponent],
  exports: [PortalComponent],
  providers: [
    VersionInfoService,
    SettingsService,
    AppConfigService,
    PortalAuthenticationGuard,
    DictionaryBaseService,
    DictionaryService,
    UserService,
    ReportService,
    BusinessReportService,
    TaskService,
    TemplateService,
    DocumentService,
    DocumentGuiService,
    BinaryDownloaderService,
    BiService,
    NumberFormatService,
    ContractVersionService,
    BondVersionService,
    ContractBondPortalService,
    DeclarationFileService,
    {
      provide: CompanyService,
      useClass: PortalCompanyService,
    },
    {
      provide: CompanyAbstractService,
      useClass: PortalCompanyService,
    },
    ContractService,
    TemplateDetailsGuiService,
    BrokerContractVersionService,
    BrokerContractGuiService,
    PropertyService,
    RepositoryService,
    PortalPolicyContractVersionService,
    {
      provide: InquiryAbstractService,
      useClass: PortalInquiryService,
    },
    {
      provide: OfferService,
      useClass: PortalOfferService,
    },
    PortalDeclarationService,
    {
      provide: PolicyContractVersionService,
      useClass: PortalPolicyContractVersionService,
    },
    {
      provide: PolicyContractService,
      useClass: PortalPolicyContractService,
    },
    BusinessUnitService,
    LimitService,
    ClaimService,
    InvoiceCoreService,
    InvoiceService,
    InvoiceGuiService,
    InvoiceItemService,
    InvoiceItemGuiService,
    InvoicePaymentService,
    InvoicePaymentGuiService,
    CompanyRatingService,
    {
      provide: RouterService,
      useClass: PortalRouterService,
    },
    PortalRouterService,
    ContractVersionService,
    QuotationService,
    BusinessReportPortalService,
    PortalInvoiceService,
    BrokerPortfolioService,
    DashboardService,
    PasswordResetService,
    CountryRatingService,
    CurrencyExchangeRateService,
    PortalCompanyService,
    ImageService,
    LanguageService,
    BsModalService,
    GeoDictService,
    GeoDictBaseService,
    BusinessPropertiesService,
    InquiryService,
    CacheService,
    PolicyLimitListService,
    BlockchainService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ClientCompanyService,
    ThirdPartyService,
    ReportExecutionService,
    FormatService,
    PortalUserService,
    LoggedUserService,
    PortalContactPersonService,
    PortalCollectionClaimService,
    PortalSalesRepRelationVersionService,
    PortalBondingContractOfferService,
  ],
})
export class PortalModule {}

import {Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {CellChangeEvent} from '../../../../../bonding_shared/components/aku-table/column.component';
import {UntypedFormGroup} from '@angular/forms';
import {
  BondingContractInquiryBondDto,
  ContractBondCriteriaDto,
  ContractBondDto,
  ContractBondExtendedDto,
  ContractVersionSimpleDto,
  SearchCriteria,
} from '../../../../../bonding_shared/model/dtos';
import {BondType, ContractType, DictionaryProfile} from '../../../../../bonding_shared/model/dictionary-ids';
import {DictionaryBaseService} from '../../../../../bonding_shared/services';
import {AppConfigService} from '../../../../../bonding_shared/services/app-config.service';
import {ATableComponent} from '../../../../../bonding_shared/components/aku-table';
import {BondingContractOfferProposedCommissionService} from '../../../offer/services/bonding-contract-offer-proposed-commission.service';
import {ContractBondPortalService} from '../../../../../portal/clientbond';
import {map} from 'rxjs/operators';
import {BusinessUnit} from '../../../../../bonding_shared/model/business-unit';

@Component({
  selector: 'bond-type-list',
  templateUrl: './bond-type-list.component.pug',
})
export class BondTypeListComponent implements OnInit {
  @ViewChild('bondTypes', {static: true}) bondTypesTable: ATableComponent<
    ContractBondExtendedDto | BondingContractInquiryBondDto
  >;

  @Input() contractBondTypes: ContractBondDto[] | BondingContractInquiryBondDto[] = [];
  @Input() form: UntypedFormGroup;
  @Input() showErrors = false;
  @Input() buId: number;
  @Input() dictionaryProfileId: DictionaryProfile;
  @Input() dictionaryUserRoleId: number;
  @Input() externalContract = false;
  @Input() unavailableTypes: Set<number>;
  @Input() promiseOfGuaranteeFeeEditable = true;
  @Input() minPremiumEditable = true;
  @Input() maxTotalLiability: number;
  @Input() liabilitiesRequired = false;
  @Input() contractTypeId: number;
  @Input() readOnly = false;
  @Input() freeAmountShow = false;
  @Input() usedAmountShow = false;
  @Input() contractVersionId; // Mandatory if freeAmountShow

  @Input() parentType: 'contract' | 'inquiry' | 'offer' = 'contract';

  @Output() typeChange = new EventEmitter<void>();

  readonly ContractType = ContractType;
  private KUKE_GSP_MAX_BOND_DURATION = 108;

  constructor(
    public dictionaryBaseService: DictionaryBaseService,
    public appService: AppConfigService,
    @Optional() public bondingContractOfferProposedCommissionService: BondingContractOfferProposedCommissionService,
    private contractBondService: ContractBondPortalService
  ) {}

  ngOnInit(): void {
    const cts = this.contractBondTypes;
    if ((this.freeAmountShow || this.usedAmountShow) && cts && cts.length > 0 && this.contractVersionId) {
      this.initFreeAndUsedAmount();
    }
    this.initPriceProposed();
  }

  private initFreeAndUsedAmount() {
    const searchCriteria = <SearchCriteria<ContractBondCriteriaDto>>{};
    searchCriteria.criteria = <ContractBondCriteriaDto>{};
    searchCriteria.criteria.contractVersionId = this.contractVersionId;

    const rewriteFreeAndUsedAmountToContractBondTypes = (values: ContractBondExtendedDto[]) => {
      for (const val of values) {
        const found = <ContractBondExtendedDto>this.contractBondTypes.find((c) => c.bondType.id === val.bondType.id);
        if (found) {
          found.freeAmount = val.freeAmount;
          found.usedAmount = val.usedAmount;
        } else {
          console.warn(
            `Rewrite freeAmount and freeAmount failed. Not found contract bond type for ${val.bondType.id}.`
          );
        }
      }
    };

    this.contractBondService
      .searchByCriteria<ContractBondCriteriaDto, ContractBondExtendedDto>(searchCriteria)
      .pipe(map((result) => result.result))
      .subscribe((values) => rewriteFreeAndUsedAmountToContractBondTypes(values));
  }

  onAddUpdateDelete(bond: ContractBondDto) {
    if (this.externalContract) {
      bond.price = 0;
      bond.deedFee = 0;
    }
    bond.active = true;
    if (this.kuke && this.buId === BusinessUnit.KUKE_GSP) {
      bond.maxBondDuration = this.KUKE_GSP_MAX_BOND_DURATION;
    }
    this.onItalyBidPerformanceBondTypeAdd(bond);
    this.typeChanged();
  }

  onItalyBidPerformanceBondTypeAdd(bond: ContractBondDto) {
    if (this.liabilitiesRequired) {
      let bid: ContractBondDto;
      if (bond.bondType.id === BondType.BID_BOND_IT && (!bond.id || bond.id === 0)) {
        bid = bond;
      }
      if (bid) {
        if (
          !(<ContractBondDto[]>this.contractBondTypes).find((pf) => pf.bondType.id === BondType.PERFORMANCE_BOND_IT)
        ) {
          const performance: ContractBondDto = <ContractBondDto>{};
          this.dictionaryBaseService
            .getDictionaryEntry('DictionaryBase', BondType.PERFORMANCE_BOND_IT)
            .subscribe((b) => (performance.bondType = b));

          if (bid.maxBondLiability) {
            performance.maxBondTotalLiability = bid.maxBondLiability * 1.5;
          }
          if (bid.maxBondTotalLiability) {
            performance.maxBondTotalLiability = bid.maxBondTotalLiability * 1.5;
          }
          if (bid.maxBondLiability) {
            performance.maxBondLiability = bid.maxBondLiability * 1.5;
          }

          (<ContractBondDto[]>this.contractBondTypes).push(performance);
        }
      }
    }
  }

  get kuke() {
    return this.appService.kuke;
  }

  get credendo() {
    return this.appService.credendo;
  }

  get inquiry() {
    return this.parentType === 'inquiry';
  }

  get offer() {
    return this.parentType === 'offer';
  }

  get contract() {
    return this.parentType === 'contract';
  }

  onMaxDurationChangeChange(event: CellChangeEvent<ContractBondDto | BondingContractInquiryBondDto>) {
    const bs = this.bondingContractOfferProposedCommissionService;
    if (bs && !this.inquiry && event && event.item) {
      const item = event.item as ContractBondDto;
      bs.getProposedCommission(item.maxBondDuration).subscribe((v) => (item.priceProposed = v));
    }
  }

  private initPriceProposed() {
    const bs = this.bondingContractOfferProposedCommissionService;
    if (bs && !this.inquiry && this.contractBondTypes && this.contractBondTypes.length > 0) {
      const items = this.contractBondTypes as ContractBondDto[];
      items
        .filter((item) => item.maxBondDuration > 0 && !item.priceProposed)
        .forEach((item) => {
          bs.getProposedCommission(item.maxBondDuration).subscribe((v) => (item.priceProposed = v));
        });
    }
  }

  typeChanged() {
    this.typeChange.emit();
  }
}

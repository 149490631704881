import {Injectable} from '@angular/core';
import {AbstractGuard} from '../../../bonding_shared/components/abstract-guard';
import {LoggedUserService} from '../../../bonding_shared';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {CompanyElementaryRight, LimitElementaryRight} from '../../../bonding_shared/model/dictionary-ids';

@Injectable()
export class LimitMassGuard extends AbstractGuard {
  constructor(loggedUserService: LoggedUserService, router: Router) {
    super(loggedUserService, router);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return (
      this.checkRight(state.url, LimitElementaryRight.LIMIT_MASS_DECISION_VIEW) ||
      this.checkRight(state.url, CompanyElementaryRight.COMPANY_IMPORT_FILE_LIMIT_MASS_DECISION)
    );
  }
}

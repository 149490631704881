import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {
  AppConfigService,
  ATableComponent,
  BICompanyOrOrderResponse,
  BIOrderRequest,
  BiService,
  BondDto,
  BondingContractExposureDto,
  BondingContractInquiryVersionCriteriaDto,
  BondingContractInquiryVersionSimpleDto,
  BondVersionCriteriaDto,
  BondVersionService,
  BondVersionSimpleDto,
  BrokerContractVersionCriteriaDto,
  BrokerContractVersionService,
  BrokerContractVersionSimpleDto,
  BusinessReportService,
  ClaimService,
  ClaimVersionCriteriaDto,
  ClaimVersionDto,
  CompanyCriteriaDto,
  CompanyDto,
  CompanyNaceCodeDto,
  CompanyRawDto,
  CompanyService,
  CompanySimpleDto,
  CompanyStatisticsDto,
  ConfirmDialogComponent,
  ContractBaseDto,
  ContractService,
  ContractVersionCriteriaDto,
  ContractVersionService,
  ContractVersionSimpleDto,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryDto,
  DictionaryService,
  ErrorReason,
  FeatureService,
  FormatService,
  GrowlService,
  InquiryService,
  LoggedUserService,
  PolicyContractVersionCriteriaDto,
  PolicyContractVersionService,
  PolicyContractVersionSimpleDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchCriteria,
  SearchDataProvider,
  SettingsService,
  TemplateService,
  TemplateSimpleDto,
} from '../../bonding_shared';
import {
  BIReportType,
  BISource,
  BusinessObjectType,
  COMPANY_TYPE_BROKERS,
  CompanyElementaryRight,
  CompanyIdentifierType,
  ContactNoteElementaryRight,
  DocumentType,
  ElementaryRight,
  Feature,
  ImportType,
  KukeRepoOperation,
  LimitRequestType,
  PolicyElementaryRight,
  RepoDocumentElementaryRight,
  ServiceContactElementaryRight,
  ServiceNoteElementaryRight,
  UserRole,
} from '../../bonding_shared/model/dictionary-ids';
import {ClaimListComponent} from '../claim/components/shared/claim-list.component';
import {PolicyVersionListComponent} from '../policy/components/shared/policy-version-list.component';
import {DocumentListComponent} from '../document';
import {BusinessUtils} from '../../bonding_shared/utils/business-utils';
import {InquiryGuiService} from '../inquiry/services/inquiry-gui.service';
import {PolicyInquiryVersionListComponent} from '../policy/components/shared/policy-inquiry-version-list.component';
import {CompanyGuiService} from './services/company-gui.service';
import {CompanyLegalInsolvencyService} from '../../bonding_shared/services/company-legal-insolvency.service';
import {CompanyRatingsComponent} from './components/shared/company-ratings.component';
import {Observable} from 'rxjs';
import {DateUtils} from '../../bonding_shared/utils/date-utils';
import {Item} from '../../bonding_shared/components/item-dropdown/multi-item-dropdown.component';
// tslint:disable-next-line:max-line-length
import {BondingContractInquiryVersionListComponent} from '../bonding-contract/inquiry/components/shared/bonding-contract-inquiry-version-list.component';
import {BondingContractInquiryService} from '../../bonding_shared/services/bonding-contract-inquiry.service';
import {HttpParams} from '@angular/common/http';
import {ReportDefinition} from '../../bonding_shared/model/report-ids';
import {BiReportListComponent} from '../bi-report/components';

@Component({
  selector: 'company-preview',
  templateUrl: './company-preview.component.pug',
  styles: [
    '.statistics-label { display: inline-block; width: 45px; }',
    '.statistics-span-value { display: inline-block; width: 120px; text-align: right }',
    '.statistics-span-currency { display: inline-block; width: 25px; text-align: right }',
  ],
})
export class CompanyPreviewComponent implements OnInit {
  public readonly ImportType = ImportType;
  public readonly BusinessObjectType = BusinessObjectType;
  public readonly ContactNoteElementaryRight = ContactNoteElementaryRight;
  public readonly ServiceContactElementaryRight = ServiceContactElementaryRight;
  public readonly ServiceNoteElementaryRight = ServiceNoteElementaryRight;
  public readonly RepoDocumentElementaryRight = RepoDocumentElementaryRight;
  public readonly PolicyElementaryRight = PolicyElementaryRight;
  public readonly CompanyElementaryRight = CompanyElementaryRight;
  @ViewChild(PolicyVersionListComponent) public policyList: PolicyVersionListComponent;
  @ViewChild(PolicyInquiryVersionListComponent)
  public policyInquiryList: PolicyInquiryVersionListComponent;
  @ViewChild(BondingContractInquiryVersionListComponent)
  public bondingContractInquiryList: BondingContractInquiryVersionListComponent;
  @ViewChild('claims', {static: true}) public claims: ClaimListComponent;
  @ViewChild(DocumentListComponent, {static: true}) public documentList: DocumentListComponent;
  @ViewChild('biReports') public biReportList: BiReportListComponent;
  @ViewChild(CompanyRatingsComponent, {static: true}) public companyRatingsComponent: CompanyRatingsComponent;
  @ViewChild('subsidiariesTable') public subsidiariesTable: ATableComponent<CompanySimpleDto>;
  @ViewChild('orderReportConfirm', {static: true}) orderReportConfirmDialog: ConfirmDialogComponent;

  public errors: ErrorReason[] | string;
  public policyDataProvider: SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>;
  public policyInquiryDataProvider: SearchDataProvider<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>;
  public claimDataProvider: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>;
  public claimRiskDataProvider: SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>;
  public clientBondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  public beneficiaryBondDataProvider: SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>;
  public contractDataProvider: SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>;
  public bondingContractInquiryDataProvider: SearchDataProvider<
    BondingContractInquiryVersionCriteriaDto,
    BondingContractInquiryVersionSimpleDto
  >;
  public brokerContractDataProvider: SearchDataProvider<
    BrokerContractVersionCriteriaDto,
    BrokerContractVersionSimpleDto
  >;
  public company: CompanyDto;
  public mainNaceCodes: CompanyNaceCodeDto[];
  public selectedContract: ContractVersionSimpleDto;
  public statistics: CompanyStatisticsDto;
  public objectNotFound = false;
  public inProgress = false;
  public possibleBISources: DictionaryBaseDto[];
  public templates: TemplateSimpleDto[] = [];
  public buyerTemplates: TemplateSimpleDto[] = [];
  public biReportTypes: DictionaryBaseDto[] = [];
  public contractInvolvement: BondingContractExposureDto;
  public companySource: BISource;
  private subsidiariesDataProvider: SearchDataProvider<CompanyCriteriaDto, CompanySimpleDto>;
  private country: DictionaryBaseDto;
  private biReportType: DictionaryBaseDto;
  private biSourceId: number;
  private biCountryId: number;
  private forceOrder = false;

  private limitButtonOptions = [];

  readonly LimitRequestType = LimitRequestType;
  readonly Feature = Feature;

  constructor(
    private companyService: CompanyService,
    private companyGUIService: CompanyGuiService,
    private biService: BiService,
    private legalInsolvencyService: CompanyLegalInsolvencyService,
    private loggedUserService: LoggedUserService,
    private bondVersionService: BondVersionService,
    private dictionaryService: DictionaryService,
    private dictionaryBaseService: DictionaryBaseService,
    private activatedRoute: ActivatedRoute,
    private policyService: PolicyContractVersionService,
    private inquiryService: InquiryService,
    private inquiryGUIService: InquiryGuiService,
    private bondingContractInquiryService: BondingContractInquiryService,
    private claimService: ClaimService,
    private contractVersionService: ContractVersionService,
    private contractService: ContractService,
    private brokerContractService: BrokerContractVersionService,
    private businessReportService: BusinessReportService,
    private growlService: GrowlService,
    private cd: ChangeDetectorRef,
    private formatService: FormatService,
    public appService: AppConfigService,
    private templateService: TemplateService,
    public router: RouterService,
    public settingsService: SettingsService,
    private featureService: FeatureService
  ) {}

  public get mehib(): boolean {
    return this.appService.mehib;
  }

  public get kuke(): boolean {
    return this.appService.kuke;
  }

  public get ecg(): boolean {
    return this.appService.ecg;
  }

  public get credendo(): boolean {
    return this.appService.credendo;
  }

  public get kukeInternal(): boolean {
    return this.kuke && this.internal;
  }

  public get broker(): boolean {
    return this.company.companyType && COMPANY_TYPE_BROKERS.indexOf(this.company.companyType.id) >= 0;
  }

  public get companySimple(): CompanySimpleDto {
    return BusinessUtils.toCompanySimpleDto(JSON.parse(JSON.stringify(this.company)));
  }

  public get internal(): boolean {
    return this.companySource === BISource.INTERNAL;
  }

  public get legalInsolvencyStatus(): string {
    return (
      this.company.lastLegalInsolvencyVersion.status.name +
      ' - ' +
      this.company.lastLegalInsolvencyVersion.substatus.name
    );
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => this.initializeView(params));
  }

  refreshSubsidiaries(companyId: number) {
    this.subsidiariesDataProvider.searchCriteria.criteria.parentOfBranch.id = companyId;
    if (this.subsidiariesTable) {
      this.subsidiariesTable.search();
    }
  }

  public newBond(): void {
    const selectedContractId = this.selectedContract ? this.selectedContract.contract.id : 0;
    this.router.toBondDetailsNew(this.company.id, selectedContractId);
  }

  public onSelectContractVersion(contractVersion: ContractVersionSimpleDto): void {
    this.selectedContract = !contractVersion ? null : contractVersion;
  }

  public canOrderReport(): boolean {
    return (
      !this.inProgress &&
      (this.credendo || this.mehib || this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_BI_REPORT_ORDER))
    );
  }

  public canAccessRating(): boolean {
    return this.internal;
  }

  public canAccessCollection(): boolean {
    return this.loggedUserService.hasRight(ElementaryRight.ACCESS_MODULE_COLLECTION);
  }

  public openStatistics(): void {
    if (!this.statistics) {
      this.loadStatistics();
    }
  }

  public orderReport() {
    if (!Number.isNaN(this.biSourceId) && this.biSourceId) {
      this.loadOrOrderReport(this.biReportType, this.biSourceId, true);
    }
  }

  public isBrokerCompanyAndUserWithCreateContractRights(): boolean {
    return this.broker && this.loggedUserService.hasRight(ElementaryRight.BROKERCONTRACT_CREATE);
  }

  public cancelOrderingReport() {
    console.log('Resign from ordering report');
  }

  public loadOrOrderReport(
    biReportType: DictionaryBaseDto = <DictionaryBaseDto>{id: BIReportType.COMMERCIAL_REPORT},
    biSourceId: number,
    forceOrder = false
  ): void {
    this.errors = undefined;
    this.inProgress = true;
    this.biReportType = biReportType;
    this.biSourceId = biSourceId;
    const biOrderRequest = <BIOrderRequest>{force: forceOrder};
    if (this.company.id) {
      biOrderRequest.companyId = this.company.id;
    } else {
      biOrderRequest.companyRaw = <CompanyRawDto>this.company;
    }
    biOrderRequest.type = biReportType;
    biOrderRequest.country = this.country;
    const observable: Observable<BICompanyOrOrderResponse> = this.biService.getOrOrderCompany(
      biOrderRequest,
      biSourceId
    );
    observable.subscribe(
      (companyOrOrderResponse) => {
        this.inProgress = false;
        if (companyOrOrderResponse.company) {
          this.company = companyOrOrderResponse.company;
          this.forceOrder = this.company.biActualReportExists;
          this.initData(this.company.id);
          if (this.company.id && this.canAccessRating() && this.companyRatingsComponent) {
            this.companyRatingsComponent.reloadRatings();
          }
          if (this.companySource === BISource.INTERNAL) {
            // Reload documents to show BI report that was just retrieved.
            if (this.documentList) {
              this.documentList.search();
            }
          } else {
            // After we have loaded new company to our database, we can switch the view to local company mode.
            this.companySource = BISource.INTERNAL;
          }
          this.errors = companyOrOrderResponse.company.warnings;
          this.cd.detectChanges();
          if (!this.errors || this.errors.length === 0) {
            this.growlService.notice('Report was read. You can open it in "Information report" section.');
          } else {
            this.growlService.warning('No report available! Please check later.');
          }
        } else if (companyOrOrderResponse.orderResponse) {
          this.errors = companyOrOrderResponse.orderResponse.warnings;
          if (!this.errors || this.errors.length === 0) {
            this.growlService.notice('Report was ordered. Wait a few days until Bussiness Inteligence processes it.');
          }
        } else if (companyOrOrderResponse.errors) {
          this.errors = companyOrOrderResponse.errors;
          this.inProgress = false;
          if (this.companySource === BISource.INTERNAL && this.biReportList) {
            this.biReportList.search();
          }
        }
        if (this.isForceOrderReportCondition(biReportType.id)) {
          if (!!this.forceOrder) {
            this.orderReportConfirmDialog.openAndExecuteOnConfirm(
              'company.preview.confirm.actualReport.title',
              'company.preview.confirm.actualReport.msg',
              this.orderReport.bind(this),
              this.cancelOrderingReport.bind(this)
            );
          }
        }
      },
      (error) => {
        this.inProgress = false;
        this.errors = error;
      },
      () => {
        if (biOrderRequest.companyId) {
          this.company.id = biOrderRequest.companyId;
        }
        if (this.company.id) {
          this.initializeInternalCompanyView(this.company.id);
        }
      }
    );
  }

  isForceOrderReportCondition(biReportTypeId: number): boolean {
    return (
      (this.credendo || this.mehib) &&
      this.company &&
      biReportTypeId !== BIReportType.INVESTIGATION &&
      DateUtils.compare(this.company.lastReportFetchingDate, DateUtils.addDay(DateUtils.today(), -30)) > 0
    );
  }

  public toCompanySearch(): void {
    if (this.internal) {
      this.router.toCompanySearch();
    } else {
      this.router.toCompanySearchExternalByBI(this.companySource ? this.companySource.valueOf() : undefined);
    }
  }

  public loadExternalId(biSourceId: number): void {
    this.biService.loadExternalId(biSourceId, this.company.id).subscribe((c) => {
      this.company = c;
      if (
        !this.company.identifiers ||
        !this.company.identifiers.find(
          (ident) => ident.type.id === CompanyIdentifierType.EXTERNAL_ID && ident.biSource.id === biSourceId
        )
      ) {
        this.errors = 'Can not load external id';
      }
    });
  }

  public onPrintSelected(template: TemplateSimpleDto): void {
    this.router.toDocumentDetailsNewOperations(
      KukeRepoOperation.PROPOSALS,
      template.id,
      this.company.id,
      template.type.id
    );
  }

  private setOrderedReportTypes(types: DictionaryDto[]): void {
    types.filter((r) => r.id !== BIReportType.PREMIUM_REPORT).forEach((t) => this.biReportTypes.push(t));
  }

  private initializeView(params: Params): void {
    this.inProgress = false;
    const source: number = +params['source'];
    if (source) {
      const id = params['id'];
      this.biCountryId = +params['countryId'];
      if (!id) {
        this.errors = 'Id must be provided';
        return;
      }
      this.initializeExternalCompanyView(id, source);
    } else {
      this.companySource = BISource.INTERNAL;
      const id = +params['id'];
      if (isNaN(id)) {
        this.objectNotFound = true;
        this.company = undefined;
        this.errors = 'Id ' + id + ' is incorrect';
        return;
      }
      this.initializeInternalCompanyView(id);
      this.loadTemplates();
    }
    this.dictionaryService.getDictionary('BIReportType').subscribe((ort) => this.setOrderedReportTypes(ort));
  }

  private fetchCurrentContractInvolvement(companyId: number): void {
    this.contractService
      .getCurrentInvolvement(companyId)
      .subscribe((involvement: BondingContractExposureDto) => (this.contractInvolvement = involvement));
  }

  private loadPossibleBiSources(sourceId: number): void {
    if (this.kuke) {
      this.country = this.companyGUIService.getCompanyCountry(this.company);
      if (this.country) {
        this.companyGUIService.getPossibleBI(this.country.id).subscribe((res) => {
          this.possibleBISources = res;
          if (!this.internal) {
            this.possibleBISources = this.possibleBISources.filter((s) => s.id === sourceId);
          }
        });
      }
    }
  }

  private loadStatistics(): void {
    this.companyService.getStatistics(this.company.id).subscribe((statistics) => (this.statistics = statistics));
  }

  private createBondDataProvider(
    clientId: number,
    beneficiaryId: number
  ): SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto> {
    const dp = new SearchDataProvider<BondVersionCriteriaDto, BondVersionSimpleDto>(this.bondVersionService);
    dp.searchCriteria = <SearchCriteria<BondVersionCriteriaDto>>{};
    dp.searchCriteria.criteria = <BondVersionCriteriaDto>{};
    dp.searchCriteria.criteria.bond = <BondDto>{};
    dp.searchCriteria.criteria.beneficiaryId = beneficiaryId;
    dp.searchCriteria.criteria.clientId = clientId;
    return dp;
  }

  private createContractDataProvider(
    clientId: number
  ): SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto> {
    const dp = new SearchDataProvider<ContractVersionCriteriaDto, ContractVersionSimpleDto>(
      this.contractVersionService
    );
    dp.searchCriteria = <SearchCriteria<ContractVersionCriteriaDto>>{};
    dp.searchCriteria.criteria = <ContractVersionCriteriaDto>{};
    dp.searchCriteria.criteria.contract = <ContractBaseDto>{};
    dp.searchCriteria.criteria.clientId = clientId;
    dp.searchCriteria.parameters = {};
    if (this.kuke) {
      dp.searchCriteria.parameters['EXPOSURE_PER_CONTRACT'] = 'true';
    }
    return dp;
  }

  private createPolicyDataProvider(companyId: number): void {
    this.policyDataProvider = new SearchDataProvider<PolicyContractVersionCriteriaDto, PolicyContractVersionSimpleDto>(
      this.policyService
    );
    this.policyDataProvider.textSearch = false;
    this.policyDataProvider.searchCriteria = <SearchCriteria<PolicyContractVersionCriteriaDto>>{};
    this.policyDataProvider.searchCriteria.criteria = <PolicyContractVersionCriteriaDto>{};
    if (this.kuke) {
      this.policyDataProvider.searchCriteria.criteria.versionPhase = 'LAST';
    } else {
      this.policyDataProvider.searchCriteria.criteria.last = true;
    }
    this.policyDataProvider.searchCriteria.criteria.linkedCompanyId = companyId;
  }

  private createPolicyInquiryDataProvider(clientId: number): void {
    this.policyInquiryDataProvider = new SearchDataProvider<
      PolicyInquiryVersionCriteriaDto,
      PolicyInquiryVersionSimpleDto
    >(this.inquiryService);
    this.policyInquiryDataProvider.searchCriteria.criteria = <PolicyInquiryVersionCriteriaDto>{};
    this.policyInquiryDataProvider.searchCriteria.criteria.client = <CompanyCriteriaDto>{};
    this.policyInquiryDataProvider.searchCriteria.criteria.client.id = clientId;
    this.policyInquiryDataProvider.searchCriteria.criteria.last = true;
    this.policyInquiryDataProvider.textSearch = false;
  }

  private createClaimDataProvider(
    mainBeneficiaryId: number,
    riskId: number
  ): SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto> {
    const dp = new SearchDataProvider<ClaimVersionCriteriaDto, ClaimVersionDto>(this.claimService);
    dp.textSearch = false;
    dp.searchCriteria = <SearchCriteria<ClaimVersionCriteriaDto>>{};
    dp.searchCriteria.criteria = <ClaimVersionCriteriaDto>{
      last: true,
      claim: {mainBeneficiary: {id: mainBeneficiaryId}, risk: {id: riskId}},
    };
    dp.searchCriteria.criteria.last = true;
    return dp;
  }

  private createSubsidiariesDataProvider(companyId: number): SearchDataProvider<CompanyCriteriaDto, CompanySimpleDto> {
    const dp = new SearchDataProvider<CompanyCriteriaDto, CompanySimpleDto>(this.companyService);
    dp.textSearch = false;
    dp.searchCriteria = <SearchCriteria<CompanyCriteriaDto>>{};
    dp.searchCriteria.criteria = <CompanyCriteriaDto>{
      parentOfBranch: {id: companyId},
    };
    return dp;
  }

  private createBondingContractInquiryDataProvider(
    companyId: number
  ): SearchDataProvider<BondingContractInquiryVersionCriteriaDto, BondingContractInquiryVersionSimpleDto> {
    const dp = BusinessUtils.createBondingContractInquiryVersionDataProvider(this.bondingContractInquiryService);
    dp.searchCriteria.criteria.last = true;
    dp.textSearch = false;
    if (this.kuke) {
      dp.searchCriteria.criteria.inquiry.client.company.id = companyId;
    } else {
      dp.searchCriteria.criteria.clientId = companyId;
    }
    return dp;
  }

  private initializeInternalCompanyView(id: number): void {
    this.initData(id);
    this.loadInternalCompany(id);
  }

  private initializeExternalCompanyView(id: any, biSourceId: number): void {
    this.company = this.companyGUIService.getCompany(id);
    if (this.company.id) {
      this.companySource = BISource.INTERNAL;
      this.initData(this.company.id);
      if (this.canAccessRating() && this.companyRatingsComponent) {
        this.companyRatingsComponent.reloadRatings();
      }
      this.cd.detectChanges();
    } else {
      this.companySource = biSourceId;
    }
    this.loadPossibleBiSources(this.companySource);
    this.mainNaceCodes = this.filterMainNaceCodes();
  }

  private initData(id: number): void {
    this.createPolicyDataProvider(id);
    this.createPolicyInquiryDataProvider(id);
    this.claimDataProvider = this.createClaimDataProvider(id, null);
    this.claimRiskDataProvider = this.createClaimDataProvider(null, id);
    this.clientBondDataProvider = this.createBondDataProvider(id, null);
    this.contractDataProvider = this.createContractDataProvider(id);
    this.beneficiaryBondDataProvider = this.createBondDataProvider(null, id);
    this.brokerContractDataProvider = BusinessUtils.createBrokerContractVersionDataProvider(this.brokerContractService);
    this.brokerContractDataProvider.searchCriteria.criteria.company.id = id;
    this.brokerContractDataProvider.searchCriteria.criteria.last = true;
    this.subsidiariesDataProvider = this.createSubsidiariesDataProvider(id);
    this.bondingContractInquiryDataProvider = this.createBondingContractInquiryDataProvider(id);
  }

  private loadInternalCompany(id: number): void {
    this.companyService.getCompany(id).subscribe(
      (company) => {
        this.company = company;
        // lazy loading of statistics - request sent only if user opened section
        if (this.statistics) {
          this.loadStatistics();
        }
        this.mainNaceCodes = this.filterMainNaceCodes();
        this.loadPossibleBiSources(undefined);
        this.dictionaryService
          .getDictionary('LimitRequestType')
          .subscribe((dicts) => this.setLimitButtonOptions(dicts));
        setTimeout(() => this.refreshSubsidiaries(company.id));
      },
      (error) => {
        this.errors = error;
        this.company = undefined;
      }
    );
    if (this.kuke) {
      this.fetchCurrentContractInvolvement(id);
    }
  }

  private filterMainNaceCodes(): CompanyNaceCodeDto[] {
    if (this.company && this.company.naceCodes && this.company.naceCodes.length > 0) {
      return this.company.naceCodes.filter((nc) => nc.main);
    }
    return [];
  }

  // TODO: check if there is configuration of document types for offer in DB - templates must be defined
  private loadTemplates(): void {
    // this.loadTemplatesByType(this.templates, DocumentType.WZ, BusinessObjectType.COMPANY);
    this.loadTemplatesByType(this.templates, DocumentType.COMMISSION_REFUND_REQUEST, BusinessObjectType.COMPANY);
    this.loadTemplatesByType(
      this.buyerTemplates,
      DocumentType.COMPANY_PAYMENT_BALANCE_REQUEST,
      BusinessObjectType.COMPANY
    );
    this.loadTemplatesByType(
      this.buyerTemplates,
      DocumentType.COMPANY_PAYMENT_HISTORY_REQUEST,
      BusinessObjectType.COMPANY
    );
  }

  private loadTemplatesByType(templates: TemplateSimpleDto[], type: DocumentType, boType: BusinessObjectType): void {
    this.templateService.findByType(type, boType, null).subscribe((result) => {
      if (result !== undefined && result !== null) {
        result.forEach((r) => templates.push(r));
      }
    });
  }

  toServiceContacts() {
    this.router.toServiceContacts({boTypeId: BusinessObjectType.COMPANY, boId: this.company.id});
  }

  toLegalInsolvency() {
    this.company.lastLegalInsolvencyVersion
      ? this.router.toLegalInsolvency(this.company.lastLegalInsolvencyVersion.id)
      : this.router.toLegalInsolvencyWithParams({companyId: this.company.id});
  }

  onSelectLimitOption(option: Item) {
    if (option.id === 2) {
      this.router.toImporterWithParams({typeId: ImportType.LIMIT_REQUESTS_AUTO_DECISION, companyId: this.company.id});
    } else if (option.id === 3) {
      this.router.toLimitMassDecision({companyId: this.company.id});
    } else if (option.parentId && option.parentId === 1) {
      this.router.toLimitDetailsNew(0, 0, this.company.id, option.id);
    }
  }

  setLimitButtonOptions(limitRequestTypes: DictionaryDto[]) {
    const allowedTypes = [LimitRequestType.STANDARD, LimitRequestType.FALCON, LimitRequestType.FARMER];
    const requestTypes = limitRequestTypes.filter((t) => allowedTypes.includes(t.id));
    const newLimitItems = requestTypes.map((t) => <Item>{id: t.id, parentId: 1, label: t.name});
    this.limitButtonOptions = [];
    this.limitButtonOptions.push(<Item>{id: 1, labelKey: 'company.preview.limit.new', items: newLimitItems});
    this.limitButtonOptions.push(<Item>{id: 2, labelKey: 'company.preview.limit.autoDecision'});
    if (this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_IMPORT_FILE_LIMIT_MASS_DECISION)) {
      this.limitButtonOptions.push(<Item>{id: 3, labelKey: 'company.preview.limit.massReduction'});
    }
  }

  canDocumentSend(): boolean {
    return this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_DOCUMENT_SEND);
  }

  generateReport() {
    let params = new HttpParams();
    params = params.append('idFirmy', this.company.id + '');
    this.errors = undefined;
    this.businessReportService.generate(
      ReportDefinition.COMPANY_NETTO_EXPOSITION,
      `report_${this.company.id}.xlsx`,
      params,
      (errorMsg) => (this.errors = errorMsg)
    );
  }

  addManualRole() {
    this.inProgress = true;
    this.companyService.addManualRole(this.company.id).subscribe((result) => {
      this.company = result;
      this.growlService.notice('Role added');
      this.inProgress = false;
    });
  }

  removeManualRole() {
    this.inProgress = true;
    this.companyService.removeManualRole(this.company.id).subscribe((result) => {
      this.company = result;
      this.growlService.notice('Role removed');
      this.inProgress = false;
    });
  }

  canSetRoles(): boolean {
    return (
      this.featureService.get(Feature.COMPANY_USE_COMPANY_ROLES) &&
      this.loggedUserService.hasRight(CompanyElementaryRight.COMPANY_ROLE_MANAGEMENT)
    );
  }
}

import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {PolicyContractService} from './policy-contract.service';
import {BondVersionService} from './bond-version.service';
import {
  BusinessObjectType,
  FinancialDocumentCategory,
  FINoteCategory,
  InvoiceCategory,
  RatingType,
  RepoDocumentElementaryRight,
} from '../model/dictionary-ids';
import {ClaimService} from './claim.service';
import {ContactNoteService} from './contact-note.service';
import {LimitDecisionService} from './limit-decision.service';
import {LimitDecisionDto, PolicyInquiryVersionSimpleDto, ProductLimitListBaseDto} from '../model';
import {SettableObjectType} from './cache.service';
import {CollectionVersionService} from './collection-version.service';
import {ExternalProviderVersionService} from './external-provider-version.service';
import {AbstractRouterService} from '../../shared/abstract-router.service';
import {ClaimCalculationService} from './claim-calculation.service';
import {ClaimIndemnificationVersionService} from './claim-indemnification-version.service';
import {InquiryService} from './inquiry.service';
import {FormatService} from './format.service';
import {PolicyLimitListViewParams} from '../../bonding/policy-limit-list/components/policylimitlist-details.component';
import {ImportViewParams} from '../../bonding/import/import.component';
import {BondingContractInquiryViewParams} from '../../bonding/bonding-contract/inquiry/bonding-contract-inquiry-details.component';
import {CompanyViewParams} from '../../bonding/company';
import {RepositoryDocumentsParams} from '../../bonding/repository-documents';
import {BondingContractOfferViewParams} from '../../bonding/bonding-contract/offer/bonding-contract-offer-details.component';
import {NumberUtils} from '../utils/number-utils';
import {AppConfigService} from './app-config.service';
import {PolicyClauseViewParams} from '../../bonding/policy/clause/components/policyclause-details.component';
import {PolicyCessionVersionService} from './policy-cession-version.service';
import {PolicyMonitoringVersionService} from './policy-monitoring-version.service';
import {BondingContractViewParams} from '../../bonding/bonding-contract/contract';
import {ServiceContactParams} from '../../bonding/service-contacts';
import {LimitMassDecisionViewParams} from '../../bonding/limit/limit-mass-decision.component';
import {LegalInsolvencyViewParams} from '../../bonding/company/company-legal-insolvency-details.component';
import {MasterPolicyContractService} from './master-policy-contract.service';
import {ThirdPartyViewParams} from '../../bonding/thirdparty/components/thirdparty-details.component';
import {LimitService} from './limit.service';
import {BrokerContractRelationService} from './broker-contract-relation.service';
import {CountryRatingService} from './country-rating.service';
import {DeclarationCriteria} from '../../bonding/declaration/shared/declaration-details-view';
import {BondingContractInquiryService} from './bonding-contract-inquiry.service';

@Injectable()
export class RouterService extends AbstractRouterService {
  constructor(
    _router: Router,
    private policyContractService: PolicyContractService,
    private masterPolicyContractService: MasterPolicyContractService,
    private bondVersionService: BondVersionService,
    private bondingContractInquiryService: BondingContractInquiryService,
    protected countryRatingService: CountryRatingService,
    private claimService: ClaimService,
    private limitDecisionService: LimitDecisionService,
    private contactNoteService: ContactNoteService,
    collectionVersionService: CollectionVersionService,
    limitService: LimitService,
    private externalProviderVersionService: ExternalProviderVersionService,
    private claimCalcService: ClaimCalculationService,
    private claimIndService: ClaimIndemnificationVersionService,
    private inquiryService: InquiryService,
    private formatService: FormatService,
    private cessionVersionService: PolicyCessionVersionService,
    private monitoringVersionService: PolicyMonitoringVersionService,
    private brokerContractRelationService: BrokerContractRelationService,
    private appService: AppConfigService,
    private location: Location
  ) {
    super(_router, collectionVersionService, limitService);
  }

  // REPORT
  toReportSearch() {
    this._router.navigate(['bon/report-search']);
  }

  toReportDetails(id: number) {
    this._router.navigate(['bon/report-details', id]);
  }

  toReportGeneration() {
    this._router.navigate(['bon/report-generation']);
  }

  // BUSINESS REPORT
  toBusinessReportGeneration() {
    this._router.navigate(['bon/business-report-generation']);
  }

  toReportExecutionSearch() {
    this._router.navigate(['bon/report-execution-search']);
  }

  // TASK
  toTaskSearch() {
    this._router.navigate(['bon/task-search']);
  }

  toTaskSearchWithBo(boTypeId: number, boId: number, assignedToMe?: boolean) {
    this._router.navigate(['bon/task-search', boTypeId, boId], {queryParams: {assignedToMe: assignedToMe}});
  }

  toTaskDetails(id: number) {
    this._router.navigate(['bon/task-details', id]);
  }

  toNewTaskDetailsWithBO(boTypeId: number, boId: number) {
    this._router.navigate(['bon/task-details', boTypeId, boId]);
  }

  // COMPANY
  toCompanyManagers(id: number) {
    this._router.navigate(['bon/company-managers', id]);
  }

  toCompanySearch() {
    this._router.navigate(['bon/company-search']);
  }

  // COMPANY GROUP
  toCompanyGroupSearch() {
    this._router.navigate(['bon/group-search']);
  }

  toCompanyGroupPreview(id: number) {
    this._router.navigate(['bon/group-preview', id]);
  }

  toCompanyGroupDetails(id: number) {
    this._router.navigate(['bon/group-details', id]);
  }

  toCompanySearchExternalByBI(source: number) {
    this._router.navigate(['bon/company-search', source]);
  }

  toCompanyDetails(id: number) {
    this._router.navigate(['bon/company-details', id]);
  }

  toCompanyDetailsNewWithType(typeId: number) {
    this.toCompanyDetailsWithParams(<CompanyViewParams>{typeId: typeId});
  }

  toCompanyDetailsNew(
    returnViewId: number,
    settableObject: SettableObjectType,
    companyTypeId?: number,
    legalFormId?: number
  ) {
    console.log('toCompanyDetailsNew::', returnViewId);
    this.toCompanyDetailsWithParams(<CompanyViewParams>{
      returnViewId: returnViewId,
      settableObject: settableObject,
      typeId: companyTypeId,
      legalFormId: legalFormId,
      readFromCacheOnReturn: true,
    });
  }

  toCompanyDetailsWithParams(params: CompanyViewParams) {
    this._router.navigate(['bon/company-details'], {
      queryParams: params,
    });
  }

  toCompanyPreview(id: number) {
    this._router.navigate(['bon/company-preview', id]);
  }

  toExternalCompanyPreview(source: number, countryId: number, externalId: string) {
    this._router.navigate(['bon/company-preview', source, countryId, externalId]);
  }

  toCompanyRating(ratingType: RatingType, ratingCategoryId: number, id: number, calculate?: boolean) {
    if (!ratingType) {
      this._router.navigate(['bon/company-rating', ratingCategoryId, id], {
        queryParams: {calculate: calculate},
      });
    } else {
      this._router.navigate(['bon/company-rating', ratingType, ratingCategoryId, id], {
        queryParams: {calculate: calculate},
      });
    }
  }

  toLegalInsolvency(versionId: number) {
    this._router.navigate(['bon/company-legal-insolvency', versionId]);
  }

  toLegalInsolvencyWithParams(params: LegalInsolvencyViewParams) {
    this._router.navigate(['bon/company-legal-insolvency'], {
      queryParams: params,
    });
  }

  toCompanyFinancialData(companyId: number) {
    this._router.navigate(['bon/company-financial-data', companyId]);
  }

  toCompanyCrm(companyId?: number, businessObjectType?: number, businessObjectId?: number) {
    this._router.navigate(['bon/company-crm'], {
      queryParams: {companyId: companyId, boType: businessObjectType, boId: businessObjectId},
    });
  }

  toRepositoryDocumentsWithParams(params: RepositoryDocumentsParams) {
    this._router.navigate(['bon/repository-documents'], {
      queryParams: params,
    });
  }

  toRepositoryDocuments(
    businessObjectTypeId: number,
    businessObjectId: number,
    rightToRead: RepoDocumentElementaryRight,
    rightToWrite: RepoDocumentElementaryRight,
    categoryId?: number,
    allowedFileTypes?: string
  ) {
    this.toRepositoryDocumentsWithParams({
      boTypeId: businessObjectTypeId,
      boId: businessObjectId,
      rightToRead: rightToRead,
      rightToWrite: rightToWrite,
      categoryId: categoryId,
      allowedFileTypes: allowedFileTypes,
    });
  }

  toServiceNotes(businessObjectType: number, businessObjectId: number) {
    this._router.navigate(['bon/service-notes', businessObjectType, businessObjectId]);
  }

  toServiceNotesWithReturnId(businessObjectType: number, businessObjectId: number, returnId: number) {
    this._router.navigate(['bon/service-notes', businessObjectType, businessObjectId, returnId]);
  }

  toCompanyCrmSelectedNote(noteId: number) {
    this.contactNoteService.getContactNote(noteId).subscribe((cn) => {
      if (cn.businessObject) {
        this._router.navigate(['bon/contact-note-details', cn.businessObject.relatedToId, noteId]);
      } else {
        this._router.navigate(['bon/contact-note-details', noteId]);
      }
    });
  }

  toContactNoteDetails(
    noteId: number,
    companyId?: number,
    businessObjectType?: number,
    businessObjectId?: number,
    companyRoleId?: number,
    moduleId?: number,
    contactNoteReasonId?: number
  ) {
    this._router.navigate(['bon/contact-note-details', noteId], {
      queryParams: {
        companyId: companyId,
        boType: businessObjectType,
        boId: businessObjectId,
        companyRoleId: companyRoleId,
        moduleId: moduleId,
        contactNoteReasonId: contactNoteReasonId,
      },
    });
  }

  // CONTRACT
  toContractSearch() {
    this._router.navigate(['bon/contract-version-search']);
  }

  toContractDetails(contractVersionId: number, params?: BondingContractViewParams) {
    this.navigate('contract-details', contractVersionId, params);
  }

  toExternalLimit(contractId: number) {
    this._router.navigate(['bon/external-limit', contractId]);
  }

  toContractDetailsNew(clientId: number) {
    this.toContractDetails(null, {clientId: clientId});
  }

  toContractPreview(contractVersionId: number) {
    this._router.navigate(['bon/contract-version-preview', contractVersionId]);
  }

  toContractPreviewByContractId(contractId: number) {
    this._router.navigate(['bon/contract-preview', contractId]);
  }

  toContractTechnicalDetails(contractId: number) {
    this._router.navigate(['bon/contract-technical-details', contractId]);
  }

  toBondingContractInquirySearch() {
    this._router.navigate(['bon/bonding-contract-inquiry-search']);
  }

  toBondingContractInquiryDetails(id: number, params?: BondingContractInquiryViewParams) {
    this.navigate('bonding-contract-inquiry-details', id, params);
  }

  toBondingContractInquiryRenewal(versionId: number) {
    this.toBondingContractInquiryDetails(null, {versionIdForRenewal: versionId});
  }

  toBondingContractInquiryRenewalFromPolicy(contractVersionId: number) {
    this.toBondingContractInquiryDetails(null, {contractVersionId: contractVersionId});
  }

  toBondingContractInquiryTechnicalDetails(versionId: number, mainSalesRepUserId: number) {
    const extras = mainSalesRepUserId ? {queryParams: {mainSalesRepUserId: mainSalesRepUserId}} : null;
    this._router.navigate(['bon/bonding-contract-inquiry-technical-details', versionId], extras);
  }

  toBondingContractOfferDetails(id: number, params?: BondingContractOfferViewParams) {
    this.navigate(BONDING_CONTRACT_OFFER_DETAILS, id, params);
  }

  toBondingContractRatesTable() {
    this._router.navigate(['bon/bonding-contract-rates-table']);
  }

  // POLICY
  toPolicyContractSearch() {
    this._router.navigate(['bon/policy-contract-version-search']);
  }

  toPolicyContractPreview(versionId: number) {
    if (versionId === 0) {
      this.toPolicyContractDetails(0);
    } else {
      this._router.navigate(['bon/policy-contract-preview', versionId]);
    }
  }

  toDeclarationImportView(dc: DeclarationCriteria) {
    if (dc) {
      this._router.navigate([
        'bon/policy-declaration-import/',
        dc.selectedPolicy ? dc.selectedPolicy.id : 0,
        dc.selectedRp ? dc.selectedRp.id : 0,
        dc.selectedLlv ? dc.selectedLlv.id : 0,
        dc.selectedDecOn ? dc.selectedDecOn.id : 0,
      ]);
    }
  }

  toPolicyContractDetails(versionId: number) {
    this._router.navigate(['bon/policy-contract-details', versionId]);
  }

  toPolicyContractReadOnly(versionId: number) {
    this._router.navigate(['bon/policy-contract-display', versionId, true]);
  }

  toPolicyContractDetailsNew(clientId: number) {
    this._router.navigate(['bon/policy-contract-details', 0, clientId]);
  }

  toPolicyContractDetailsByPolicyContractId(policyContractId: number) {
    this.policyContractService
      .getPolicyContract(policyContractId)
      .pipe(map((policyContract) => policyContract.versions[policyContract.versions.length - 1]))
      .subscribe((policyContractVersion) => this.toPolicyContractDetails(policyContractVersion.id));
  }

  toPolicyContractPreviewByPolicyContractId(policyContractId: number) {
    this.policyContractService
      .getPolicyContract(policyContractId)
      .pipe(map((policyContract) => policyContract.versions[policyContract.versions.length - 1]))
      .subscribe((policyContractVersion) => this.toPolicyContractPreview(policyContractVersion.id));
  }

  toPolicyContractPreviewByMasterPolicyContractId(masterPolicyContractId: number) {
    this.masterPolicyContractService
      .getMasterPolicyContract(masterPolicyContractId)
      .pipe(map((mpc) => mpc.policyContracts[mpc.policyContracts.length - 1]))
      .subscribe((pc) => this.toPolicyContractPreviewByPolicyContractId(pc.id));
  }

  toPolicyContractTechnicalDetails(id: number) {
    this._router.navigate(['bon/policy-contract-technical-details', id]);
  }

  toPolicyMonitoringVersionDetailNew(policyContractId: number, policyMonitoringType: number) {
    this._router.navigate(['bon/policy-monitoring-new', policyContractId, policyMonitoringType]);
  }

  toPolicyMonitoringVersionDetailVersionId(versionId: number) {
    this._router.navigate(['bon/policy-monitoring-version', versionId]);
  }

  toPolicyClauseDetails(id: number, params?: PolicyClauseViewParams) {
    this.navigate(POLICY_CLAUSE_DETAILS, id, params);
  }

  toPolicyOfferClauseNew(offerId: number) {
    this.toPolicyClauseDetails(undefined, <PolicyClauseViewParams>{offerId: offerId});
  }

  toPolicyClauseNew(policyVersionId: number) {
    this.toPolicyClauseDetails(undefined, <PolicyClauseViewParams>{policyVersionId: policyVersionId});
  }

  toProductDetails(pll: ProductLimitListBaseDto) {
    if (pll.masterPolicyContract) {
      this.toPolicyContractPreview(pll.masterPolicyContract.lastVersion.id);
    } else if (pll.policyInquiry) {
      this.toPolicyInquiryDetails(pll.policyInquiry.lastVersion.id);
    } else if (pll.bondingContractInquiry) {
      this.toBondingContractInquiryDetails(pll.bondingContractInquiry.lastVersion.id);
    } else if (pll.bondingContract) {
      this.toContractDetails(pll.bondingContract.lastVersion.id);
    }
  }

  toPolicyDrawdownSummary(masterPolicyId: number) {
    this._router.navigate(['bon/policy-drawdown-summary', masterPolicyId]);
  }

  toPolicyDrawdownNew(masterPolicyId: number) {
    this._router.navigate(['bon/policy-drawdown-details'], {
      queryParams: {masterPolicyId: masterPolicyId},
    });
  }

  toPolicyDrawdownPaymentsNew(masterPolicyId: number) {
    this._router.navigate(['bon/policy-drawdown-payments'], {
      queryParams: {masterPolicyId: masterPolicyId},
    });
  }

  toPolicyDrawdownDetails(drawdownId: number) {
    this._router.navigate(['bon/policy-drawdown-details', drawdownId]);
  }

  toCountryCategoryLimitVersion(countryCategoryLimitId: number) {
    this._router.navigate(['bon/country-category-limits-version', countryCategoryLimitId]);
  }

  toPolicyDrawdownPaymentsDetails(drawdownPaymentId: number) {
    this._router.navigate(['bon/policy-drawdown-payments', drawdownPaymentId]);
  }

  toPolicyDrawdownScheduleDetails(masterPolicyId: number) {
    this._router.navigate(['bon/policy-drawdown-schedule'], {
      queryParams: {masterPolicyId: masterPolicyId},
    });
  }

  // Cession
  toCessionPreview(id: number) {
    this.cessionVersionService.getCessionBase(id).subscribe((c) => this.toCessionVersionPreview(c.lastVersionId));
  }

  toCessionVersionPreview(id: number) {
    this._router.navigate(['bon/policy-cession-preview', id]);
  }

  toCessionDetails(id: number) {
    this._router.navigate(['bon/policy-cession-details', id]);
  }

  toCessionDetailsNew(policyContractId: number) {
    this._router.navigate(['bon/policy-cession-details', 0, policyContractId]);
  }

  // Bonus/malus request
  toBonusMalusRequests(className: string, policyContractId: number) {
    this._router.navigate(['bon/bonus-malus-request-list', className, policyContractId]);
  }

  toBonusMalusRequestDetailsTemplate(className: string, policyContractId: number, bonusMalusRequestTypeId: number) {
    this._router.navigate(['bon/bonus-malus-request-details', className, 0], {
      queryParams: {
        policyContractId: policyContractId,
        bonusMalusRequestTypeId: bonusMalusRequestTypeId,
      },
    });
  }

  toBonusMalusRequestDetails(className: string, id: number) {
    this._router.navigate(['bon/bonus-malus-request-details', className, id]);
  }

  toBonusMalusRequestDetailsUrlOnly(className: string, id: number) {
    this.location.replaceState('bon/bonus-malus-request-details/' + className + '/' + id);
  }

  toBonusMalusRequestPreview(className: string, bonusMalusRequestId: number, policyContractId: number) {
    this._router.navigate(['bon/premium-return-request-preview', className, bonusMalusRequestId, policyContractId]);
  }

  // BOND
  toBondSearch() {
    this._router.navigate(['bon/bond-version-search']);
  }

  toBondDetails(id: number) {
    this._router.navigate(['bon/bond-details', id]);
  }

  toBondDetailsByBondId(bondId: number) {
    this.bondVersionService.getLastBondVersionForBond(bondId).subscribe((bondVersion) => {
      if (bondVersion) {
        this.toBondDetails(bondVersion.id);
      }
    });
  }

  toBondDetailsNew(clientId: number, contractId: number) {
    this._router.navigate(['bon/bond-details', 0, clientId, contractId]);
  }

  // TEMPLATE
  toTemplateSearch() {
    if (this.appService.kuke) {
      this._router.navigate(['bon/versioned-template-search']);
    } else {
      this._router.navigate(['bon/template-search']);
    }
  }

  toTemplateDetails(id: number) {
    this._router.navigate(['bon/template-details', id]);
  }

  // DOCUMENT
  toDocumentSearch() {
    this._router.navigate(['bon/document-search']);
  }

  toDocumentDetails(id: number) {
    this._router.navigate(['bon/document-details', id]);
  }

  toDocumentDetailsData(
    id: number,
    parentBusinessObjectTypeId: number,
    parentBusinessObjectId: number,
    businessObjectId: number,
    contactBusinessObjectTypeId: number,
    contactBusinessObjectId: number
  ) {
    this._router.navigate([
      'bon/document-details',
      id,
      parentBusinessObjectTypeId,
      parentBusinessObjectId,
      businessObjectId,
      contactBusinessObjectTypeId,
      contactBusinessObjectId,
    ]);
  }

  toDocumentDetailsWithAdditionalValues(id: number, additionalValuesBoTypeId, additionalValuesBoId) {
    this._router.navigate(['bon/document-details', id, additionalValuesBoTypeId, additionalValuesBoId]);
  }

  toDocumentDetailsNew(templateId: number, businessObjectId: number, params?: {key: string; value: any}[]) {
    let copyFor: number;
    if (params) {
      copyFor = params.find((p) => (p.key = 'copyFor'))?.value;
    }
    if (copyFor) {
      this._router.navigate(['bon/document-details-new', templateId, businessObjectId, copyFor]);
    } else {
      this._router.navigate(['bon/document-details-new', templateId, businessObjectId]);
    }
  }

  toDocumentDetailsNewWithAdditionalValues(
    templateId: number,
    businessObjectId: number,
    additionalValuesBoTypeId: number,
    additionalValuesBoId: number
  ) {
    this._router.navigate([
      'bon/document-details-new-with-additional',
      templateId,
      businessObjectId,
      additionalValuesBoTypeId,
      additionalValuesBoId,
    ]);
  }

  toDocumentDetailsNewWithContacts(
    templateId: number,
    parentBusinessObjectTypeId: number,
    parentBusinessObjectId: number,
    businessObjectId: number,
    contactBusinessObjectTypeId: number,
    contactBusinessObjectId: number
  ) {
    this._router.navigate([
      'bon/document-details-new',
      templateId,
      parentBusinessObjectTypeId,
      parentBusinessObjectId,
      businessObjectId,
      contactBusinessObjectTypeId,
      contactBusinessObjectId,
    ]);
  }

  toDocumentDetailsNewOperations(operationId: number, templateId: number, businessObjectId: number, docTypeId: number) {
    this._router.navigate([
      'bon/document-details-new-operations',
      operationId,
      templateId,
      businessObjectId,
      docTypeId,
    ]);
  }

  // BROKER
  toBrokerContractSearch() {
    this._router.navigate(['bon/broker-contract-search']);
  }

  // note this is used as a dynamic function call, and you will not find static usages of it
  toBrokerContractDetails() {
    this.toBrokerContractDetailsEditByBrokerContractVersionId(0);
  }

  toBrokerContractDetailsEditByBrokerContractVersionId(brokerContractVersionId: number) {
    this._router.navigate(['bon/broker-contract-details/edit', brokerContractVersionId]);
  }

  toBrokerContractDetailsEditByCompanyId(companyId: number) {
    this._router.navigate(['bon/broker-contract-details/edit', 0, companyId]);
  }

  toBrokerContractDetailsCloseByBrokerContractVersionId(brokerContractVersionId: number) {
    this._router.navigate(['bon/broker-contract-details/close', brokerContractVersionId]);
  }

  // only one of parameters should be provided: versionId or contractId, not both
  toBrokerContractPreview(versionId: number, contractId: number) {
    this._router.navigate(['bon/broker-contract-preview', versionId, contractId]);
  }

  toBrokerContractRelationDetailsById(id: number) {
    this.brokerContractRelationService.redirectInfo(id).subscribe((res) => {
      this.toBrokerContractRelationDetails(id, res['relationId'], res['relationType'], res['brokerContractId']);
    });
  }

  toBiReport(reportId: number) {
    this._router.navigate(['bon/bi-report-search'], {queryParams: {reportId: reportId}});
  }

  toBrokerContractRelationDetails(
    relationVersionId: number,
    relatedToId: number,
    relatedToTypeId: number,
    brokerContractId: number
  ) {
    this._router.navigate([
      'bon/broker-contract-relation-details',
      relationVersionId,
      brokerContractId,
      relatedToId,
      relatedToTypeId,
    ]);
  }

  toBrokerContractRelationDetailsForPolicy(linkId: number, relatedToId: number, brokerContractId: number) {
    this.toBrokerContractRelationDetails(linkId, relatedToId, BusinessObjectType.MASTER_POLICY, brokerContractId);
  }

  toBrokerContractRelationDetailsForInquiry(linkId: number, relatedToId: number, brokerContractId: number) {
    this.toBrokerContractRelationDetails(
      linkId,
      relatedToId,
      BusinessObjectType.POLICY_INQUIRY_VERSION,
      brokerContractId
    );
  }

  toBrokerContractRelationDetailsForContract(linkId: number, relatedToId: number, brokerContractId: number) {
    this.toBrokerContractRelationDetails(linkId, relatedToId, BusinessObjectType.CONTRACT, brokerContractId);
  }

  toBrokerContractRepresentative(brokerContractId: number): void {
    this._router.navigate(['bon/broker-contract-representative', brokerContractId]);
  }

  // SALES REP
  toSalesRepSearch() {
    this._router.navigate(['bon/sales-rep-search']);
  }

  toSalesRepDetails(versionId: number) {
    this._router.navigate(['bon/sales-rep-details', versionId]);
  }

  toSalesRepDetailsNew() {
    this._router.navigate(['bon/sales-rep-details', 'new']);
  }

  toSalesRepPreview(id: number) {
    this._router.navigate(['bon/sales-rep-preview', id]);
  }

  toSalesRepRelationDetails(relationId: number) {
    this._router.navigate(['bon/sales-rep-relation-details', relationId]);
  }

  toSalesRepRelationDetailsForPolicy(salesRepId: number, masterPolicyId?: number) {
    this.toSalesRepRelationDetailsNew(masterPolicyId, BusinessObjectType.MASTER_POLICY, salesRepId);
  }

  toSalesRepRelationDetailsForPolicyInquiry(salesRepId: number, policyInquiryVersionId?: number) {
    this.toSalesRepRelationDetailsNew(policyInquiryVersionId, BusinessObjectType.POLICY_INQUIRY_VERSION, salesRepId);
  }

  toSalesRepRelationDetailsForContract(salesRepId: number, contractId?: number) {
    this.toSalesRepRelationDetailsNew(contractId, BusinessObjectType.CONTRACT, salesRepId);
  }

  toSalesRepRelationDetailsForContractInquiry(salesRepId: number, bondingContractInquiryVersionId?: number) {
    this.toSalesRepRelationDetailsNew(
      bondingContractInquiryVersionId,
      BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION,
      salesRepId
    );
  }

  toSalesRepRelationDetailsNew(relatedToId: number, relatedToTypeId: number, salesRepId: number) {
    this._router.navigate(['bon/sales-rep-relation-details/new'], {
      queryParams: {relatedToId: relatedToId, relatedToTypeId: relatedToTypeId, salesRepId: salesRepId},
    });
  }

  // LIMIT
  toLimitSearch() {
    this._router.navigate(['bon/limit-search']);
  }

  toLimitBusinessParams() {
    this._router.navigate(['bon/limit-business-params-details']);
  }

  toLimitDetails(requestId: number) {
    this._router.navigate(['bon/limit-details', requestId]);
  }

  toPolicyClauseTypePreview(clauseTypeId: number) {
    this._router.navigate(['bon/policyclausetype-preview', clauseTypeId]);
  }

  toLimitDetailsNew(insuredId: number, masterPolicyId: number, buyerId: number, typeId: number) {
    this.toLimitDetailsWithParams({
      insuredId: insuredId,
      masterPolicyId: masterPolicyId,
      buyerCompanyId: buyerId,
      typeId: typeId,
    });
  }

  toLimitDecision(id: number) {
    this.limitDecisionService
      .getById<LimitDecisionDto>(id)
      .subscribe((res) => this._router.navigate(['bon/limit-details', res.limitRequest.id]));
  }

  toLimitDetailsByCoverId(id: number) {
    this.limitService
      .getCover(id)
      .subscribe((res) => this._router.navigate(['bon/limit-details', res.limitRequest.id]));
  }

  toBuyerLimitMassDecision(companyId: number) {
    this._router.navigate(['bon/buyer-limit-mass-decision', companyId]);
  }

  toLimitMassDecision(params: LimitMassDecisionViewParams) {
    this._router.navigate(['bon/limit-mass-decision'], {
      queryParams: params,
    });
  }

  toLimitCoverPlusProposal(coverId: number) {
    console.log('Cover plus proposal view is available only in portal mode');
  }

  toLimitMailingList(companyId: number) {
    this._router.navigate(['bon/limit-mailing-list', companyId]);
  }

  toLimitMailingMultilist(templateId: number, companyId: number) {
    this._router.navigate(['bon/limit-mailing-multilist', templateId, companyId]);
  }

  toRecoveries(claimId: number): void {
    this._router.navigate(['bon/recoveries', claimId]);
  }

  toExternalProviders(claimId: number): void {
    this._router.navigate(['bon/external-providers', claimId]);
  }

  toCompanyExternalProviders(companyId: number): void {
    this._router.navigate(['bon/company-external-providers', companyId]);
  }

  toClaimDetailsByClaimId(claimId: number) {
    this.claimService
      .getClaim(claimId)
      .pipe(map((claim) => claim.versions[claim.versions.length - 1]))
      .subscribe((claimVersion) => this.toClaimDetails(claimVersion.id));
  }

  toClaimTreatOrder(policyContractId: number, returnClaimId?: number) {
    this._router.navigate(['bon/claim-treat-order', policyContractId], {queryParams: {returnClaimId: returnClaimId}});
  }

  toClaimDocuments(claimId: number) {
    this._router.navigate(['bon/claim-documents', claimId]);
  }

  toClaimSimplifiedCalculation(claimVersionId: number) {
    this._router.navigate(['bon/claim-simplified-calculation', claimVersionId]);
  }

  toClaimCalculation(calcId: number) {
    this._router.navigate(['bon/claim-calculation', calcId]);
  }

  toClaimCalculationByClaimId(claimId: number) {
    this.claimCalcService.getLastCalc(claimId).subscribe((x) => this.toClaimCalculation(x.id));
  }

  toClaimIndemnification(versionId: number) {
    this._router.navigate(['bon/claim-indemnification', versionId]);
  }

  toClaimCalculationCheckBeforePayment(calculationId: number) {
    this._router.navigate(['bon/claim-calculation-check-before-payment', calculationId]);
  }

  toClaimCalculationPayoutPreparation(calculationId: number) {
    this._router.navigate(['bon/claim-calculation-payout-preparation', calculationId]);
  }

  toClaimIndemnificationNew(claimId: number, calcId?: number) {
    this._router.navigate(['bon/claim-indemnification'], {queryParams: {claimId: claimId, calcId: calcId}});
  }

  toClaimLawsuitByClaimId(claimId: number) {
    this._router.navigate(['bon/claim-lawsuit'], {queryParams: {claimId: claimId}});
  }

  toLinkedClaimsByClaimVersionId(claimVersionId: number) {
    this._router.navigate(['bon/claim-linked-claims', claimVersionId]);
  }

  toLinkedClaimsByBondVersionId(bondVersionId: number) {
    this._router.navigate(['bon/bond-linked-claims', bondVersionId]);
  }
  toClaimLawsuitByVersionId(versionId: number) {
    this._router.navigate(['bon/claim-lawsuit'], {queryParams: {versionId: versionId}});
  }

  toClaimIndemnificationByClaimId(claimId: number) {
    this.claimIndService.getLastByClaim(claimId).subscribe((x) => this.toClaimIndemnification(x.id));
  }

  toClaimIndemnificationByParentId(parentId: number) {
    this.claimIndService.getLastByParent(parentId).subscribe((x) => this.toClaimIndemnification(x.id));
  }

  toClaimRepaymentSchedule(claimId: number) {
    this._router.navigate(['bon/repaymentSchedule'], {queryParams: {claimId: claimId}});
  }

  toClaimCommissionNew(claimId: number) {
    this._router.navigate(['bon/claim-commission'], {
      queryParams: {claimId: claimId},
    });
  }

  toClaimCommission(claimCommissionId: number) {
    this._router.navigate(['bon/claim-commission', claimCommissionId]);
  }

  // COLLECTION
  toCollectionEstimatedCosts(parentId: number) {
    this._router.navigate(['bon/collection-estimated-costs', parentId]);
  }

  toCollectionAccountedCosts(parentId: number) {
    this._router.navigate(['bon/collection-accounted-costs', parentId]);
  }

  toCollectionCostsInvoicing(costsId: number) {
    this._router.navigate(['bon/collection-costs-invoicing', costsId]);
  }

  // EXTERNAL PROVIDER
  toExternalProviderSearch() {
    this._router.navigate(['bon/external-provider-search']);
  }

  toExternalProviderDetails(versionId: number) {
    this._router.navigate(['bon/external-provider-details', versionId]);
  }

  toExternalProviderDetailsByParentId(parentId: number) {
    this.externalProviderVersionService
      .getLastVersionByParentId(parentId)
      .subscribe((ver) => this.toExternalProviderDetails(ver.id));
  }

  toExternalProviderDetailsNew() {
    this._router.navigate(['bon/external-provider-details']);
  }

  // EVENT
  toEvents(relatedTo: number, relatedToId: number, navLinkObjectTypeId?: number, navLinkObjectId?: number) {
    this._router.navigate(['bon/event', relatedTo, relatedToId], {
      queryParams: {navLinkObjectId: navLinkObjectId, navLinkObjectTypeId: navLinkObjectTypeId},
    });
  }

  // INVOICE
  toInvoiceSearch(categoryId: InvoiceCategory) {
    this._router.navigate(['bon/invoice-search', InvoiceCategory[categoryId]]);
  }

  toBusinessObjectInvoiceSearch(businessObjectTypeId: number, businessObjectId: number) {
    this._router.navigate([
      'bon/invoice-search',
      InvoiceCategory[InvoiceCategory.INVOICE],
      businessObjectTypeId,
      businessObjectId,
    ]);
  }

  toInvoiceDetails(categoryId: FinancialDocumentCategory, id: number) {
    this._router.navigate(['bon/invoice-details', InvoiceCategory[categoryId], id]);
  }

  toInvoiceCorrection(
    categoryId: InvoiceCategory,
    correctedId: number,
    nullify?: boolean,
    newItemStatusId?: number,
    newClientId?: number,
    hideSpecification?: boolean
  ) {
    this._router.navigate([
      'bon/invoice-details',
      InvoiceCategory[categoryId],
      0,
      correctedId,
      !!nullify,
      newItemStatusId || 0,
      newClientId || 0,
      !!hideSpecification,
    ]);
  }

  toFINoteSearch(categoryId: FinancialDocumentCategory) {
    this._router.navigate(['bon/fi-note-search', FINoteCategory[categoryId]]);
  }

  toFINoteDetails(categoryId: FinancialDocumentCategory, id: number) {
    this._router.navigate(['bon/fi-note-details', FINoteCategory[categoryId], id]);
  }

  toInvoicePaymentSearch() {
    this._router.navigate(['bon/invoice-payment-search']);
  }

  // INVOICE ITEM
  toInvoiceItemSearch(categoryId: FinancialDocumentCategory) {
    this._router.navigate(['bon/invoice-item-search', InvoiceCategory[categoryId]]);
  }

  toInvoiceItemDetails(categoryId: FinancialDocumentCategory, id: number) {
    this._router.navigate(['bon/invoice-item-details', InvoiceCategory[categoryId], id]);
  }

  toNewInvoiceItem(
    categoryId: FinancialDocumentCategory,
    relatedToTypeId: number,
    relatedToId: number,
    sourceItemId: number
  ) {
    this._router.navigate([
      'bon/invoice-item-details',
      InvoiceCategory[categoryId],
      0,
      relatedToTypeId ? BusinessObjectType[relatedToTypeId] : 0,
      NumberUtils.notNull(relatedToId),
      NumberUtils.notNull(sourceItemId),
    ]);
  }

  // TREATY
  toTreatySearch() {
    this._router.navigate(['bon/treaty-search']);
  }

  toTreatyDetails(treatyId: number) {
    this._router.navigate(['bon/treaty-details', treatyId]);
  }

  toTreatyVersionDetails(versionId: number) {
    this._router.navigate(['bon/treaty-version-details', versionId]);
  }

  // USER
  toUserDetails(id: number) {
    console.log('OAUTH2' + this.appService.oauth2);
    if (this.appService.oauth2) {
      this._router.navigate(['bon/ad-user-details', id]);
    } else {
      this._router.navigate(['bon/user-details', id]);
    }
  }

  toUserCreation(type: string, businessUnit: number) {
    this._router.navigate(['bon/user-creation', type, businessUnit]);
  }

  toUserSearch() {
    this._router.navigate(['bon/user-search']);
  }

  // COUNTRY RATING
  toCountryRatingSearch(type: string) {
    this._router.navigate(['bon/country-rating-search', type]);
  }

  toCountryCategoryLimitDetails(versionId?: number) {
    if (versionId) {
      this._router.navigate(['bon/country-category-limits-version', versionId]);
    } else {
      this._router.navigate(['bon/country-category-limits-new']);
    }
  }

  toCountryRating(id: number, type: string) {
    this._router.navigate(['bon/country-rating-details', id, type]);
  }

  toCountryRatingsImport() {
    this._router.navigate(['bon/country-rating-import']);
  }

  toSalesLeadSearch() {
    this._router.navigate(['bon/sales-lead-search']);
  }

  toSalesLeadDetailsNew() {
    this._router.navigate(['bon/sales-lead-details']);
  }

  toSalesLeadDetails(id: number) {
    this._router.navigate(['bon/sales-lead-details', id]);
  }

  // POLICY INQUIRY
  toInquirySearch() {
    this._router.navigate(['bon/inquiry-search']);
  }

  toInquiryDetailsNew(clientId: number, typeId?: number, buId?: number) {
    if (typeId) {
      this._router.navigate(['bon/inquiry-details', 0, clientId, typeId, buId]);
    } else {
      this._router.navigate(['bon/inquiry-details', 0, clientId]);
    }
  }

  toPolicyLimitListSearchFromPolicy(masterPolicyId: number) {
    this._router.navigate(['bon/policylimitlist-search', masterPolicyId]);
  }

  toPolicyLimitListSearch() {
    this._router.navigate(['bon/policylimitlist-search']);
  }

  toPolicyLimitListDetails(id: number) {
    this._router.navigate(['bon/policylimitlist-details', id]);
  }

  toPolicyLimitListDetailsNew(masterPolicyId: number) {
    this.toPolicyLimitListDetailsWithParams({masterPolicyId: masterPolicyId});
  }

  toPolicyLimitListDetailsWithParams(params: PolicyLimitListViewParams) {
    this._router.navigate(['bon/policylimitlist-details'], {
      queryParams: params,
    });
  }

  toInquiryDetails(inquiry: PolicyInquiryVersionSimpleDto) {
    if (this.appService.kuke) {
      if (inquiry.policyInquiry.inquiryType === 'POLICY_ANNEX') {
        this.toPolicyAnnexInquiryDetails(inquiry.id);
      } else if (inquiry.policyInquiry.inquiryType === 'RENEWAL_INQUIRY') {
        this.toPolicyRenewalInquiryDetails(inquiry.id);
      } else {
        this.toPolicyInquiryDetails(inquiry.id);
      }
    } else {
      this.toPolicyInquiryDetails(inquiry.id);
    }
  }

  toPolicyInquiryDetails(versionId: number) {
    this._router.navigate(['bon/inquiry-details', versionId]);
  }

  toPolicyAnnexInquiryDetails(versionId: number) {
    this._router.navigate(['bon/annex-inquiry-details', versionId]);
  }

  toPolicyRenewalInquiryDetails(versionId: number) {
    this._router.navigate(['bon/renewal-inquiry-details', versionId]);
  }

  toPolicyInquiryDetailsByParentId(id: number) {
    this.inquiryService.getLastVersion(id).subscribe((res) => this.toInquiryDetails(res));
  }

  toDocumentPackSearch(boTypeId: number, boId: number) {
    this._router.navigate(['bon/documents-pack', boTypeId, boId]);
  }

  toInquiryOfferDetails(versionId: number, offerId: number) {
    if (this.appService.kuke) {
      this.toKukeInquiryOfferDetails(offerId);
    } else {
      this._router.navigate(['bon/inquiry-offer-details', versionId, 'offer', offerId]);
    }
  }

  toNewInquiryOfferDetails(versionId: number, offerTypeId: number, sourceOfferId?: number, blogOffer?: boolean) {
    const navParams: any[] = ['bon/inquiry-offer-details', versionId, 'newoffer', offerTypeId];
    if (sourceOfferId) {
      navParams.push('sourceoffer', sourceOfferId);
      if (blogOffer) {
        navParams.push(true);
      }
    }
    this._router.navigate(navParams);
  }

  toKukeInquiryOfferDetails(offerId: number, inquiryId?: number) {
    if (inquiryId > 0) {
      this._router.navigate(['bon/kuke-inquiry-offer-details', offerId, inquiryId]);
    } else {
      this._router.navigate(['bon/kuke-inquiry-offer-details', offerId]);
    }
  }

  toNewKukeInquiryOfferDetails(versionId: number, offerTypeId: number, addOfferEdge: boolean, parentOfferId: number) {
    if (!parentOfferId) {
      parentOfferId = 0;
    }
    this._router.navigate([
      'bon/kuke-inquiry-offer-details',
      versionId,
      'newoffer',
      offerTypeId,
      addOfferEdge,
      parentOfferId,
    ]);
  }

  toThirdPartyDetails(id: number) {
    this._router.navigate(['bon/thirdparty-details', id]);
  }

  toThirdPartyDetailsWithParams(params: ThirdPartyViewParams) {
    this.toViewWithParams('thirdparty-details', params);
  }

  toClientCompanyDetails(id: number) {
    this._router.navigate(['bon/client-company-details', id]);
  }

  // IMPORTER
  toImporter(importTypeId = 0) {
    this.toImporterWithParams({typeId: importTypeId});
  }

  toImporterWithParams(params: ImportViewParams) {
    this.toViewWithParams('import', params);
  }

  toBiReportSearch() {
    this._router.navigate(['bon/bi-report-search']);
  }

  // ASSIGNMENT RULES
  toGroupAssignmentRules() {
    this._router.navigate(['bon/group-assignment-rules']);
  }

  toUserAssignmentRules() {
    this._router.navigate(['bon/user-assignment-rules']);
  }

  // PROFILE QUOTAS
  toUserRoleQuotas() {
    this._router.navigate(['bon/user-role-quotas']);
  }

  toBatchAllExecutions() {
    this._router.navigate(['bon/batch-executions']);
  }

  toRunBatch(name: string) {
    this._router.navigate(['bon/batch-run', name]);
  }

  toSearchIndex() {
    this._router.navigate(['bon/search-index']);
  }

  toBusinessLog() {
    this._router.navigate(['bon/business-log']);
  }

  toHealthPage() {
    this._router.navigate(['bon/repo-health-page']);
  }

  toBusinessParams() {
    this._router.navigate(['bon/business-params']);
  }

  toRejectManagement() {
    this._router.navigate(['bon/reject-management']);
  }

  toBikRatings() {
    this._router.navigate(['bon/bik-rating-scale']);
  }

  // HR
  toEmployees() {
    this._router.navigate(['bon/user-search/true']);
  }

  toTraining() {
    this._router.navigate(['bon/training-search']);
  }

  toTrainingDetails(id: number) {
    this._router.navigate(['bon/training-details', id]);
  }

  toUserHoliday() {
    this._router.navigate(['bon/user-holiday-search']);
  }

  toHolidayDetails(id: number) {
    this._router.navigate(['bon/user-holiday-details', id]);
  }

  toUserHolidaySettings() {
    this._router.navigate(['bon/user-holiday-settings-search']);
  }

  toHolidaySettingsDetails(id: number) {
    this._router.navigate(['bon/user-holiday-settings-details', id]);
  }

  // Business Object routing
  goToBusinessObject(relatedToId: number, id: number) {
    super.goToBusinessObject(relatedToId, id);

    console.log('router service goToBusinessObject: relatedToId = ' + relatedToId + ', id = ' + id);
    switch (relatedToId) {
      case BusinessObjectType.CONTRACT:
        this.toContractPreviewByContractId(id);
        break;
      case BusinessObjectType.CONTRACT_VERSION:
        this.toContractPreview(id);
        break;
      case BusinessObjectType.BOND:
        this.toBondDetailsByBondId(id);
        break;
      case BusinessObjectType.BOND_VERSION:
        this.toBondDetails(id);
        break;
      case BusinessObjectType.BONDING_CONTRACT_OFFER:
        this.toBondingContractOfferDetails(id);
        break;
      case BusinessObjectType.CESSION_VERSION:
        this.toCessionDetails(id);
        break;
      case BusinessObjectType.COMPANY:
        this.toCompanyPreview(id);
        break;
      case BusinessObjectType.POLICY:
        this.toPolicyContractPreviewByPolicyContractId(id);
        break;
      case BusinessObjectType.POLICY_VERSION:
        this.toPolicyContractPreview(id);
        break;
      case BusinessObjectType.MASTER_POLICY:
        this.toPolicyContractPreviewByMasterPolicyContractId(id);
        break;
      case BusinessObjectType.INVOICE:
        this.toInvoiceDetails(InvoiceCategory.INVOICE, id);
        break;
      case BusinessObjectType.INVOICE_ITEM:
        this.toInvoiceItemDetails(InvoiceCategory.INVOICE, id);
        break;
      case BusinessObjectType.CLAIM:
        this.toClaimDetailsByClaimId(id);
        break;
      case BusinessObjectType.CLAIM_VERSION:
        this.toClaimDetails(id);
        break;
      case BusinessObjectType.LIMIT_DECISION:
        this.toLimitDecision(id);
        break;
      case BusinessObjectType.LIMIT_REQUEST:
        this.toLimitDetails(id);
        break;
      case BusinessObjectType.POLICY_RENEWAL:
        this.toPolicyContractPreviewByPolicyContractId(id);
        break;
      case BusinessObjectType.CONTRACT_RENEWAL:
        this.toContractPreviewByContractId(id);
        break;
      case BusinessObjectType.CONTACT_NOTE:
        this.toCompanyCrmSelectedNote(id);
        break;
      case BusinessObjectType.POLICY_INQUIRY_VERSION:
        this.toPolicyInquiryDetails(id);
        break;
      case BusinessObjectType.POLICY_INQUIRY:
        this.toPolicyInquiryDetailsByParentId(id);
        break;
      case BusinessObjectType.BONDING_CONTRACT_INQUIRY_VERSION:
        this.toBondingContractInquiryDetails(id);
        break;
      case BusinessObjectType.BROKER_CONTRACT:
        this.toBrokerContractPreview(0, id);
        break;
      case BusinessObjectType.BROKER_CONTRACT_VERSION:
        this.toBrokerContractPreview(id, 0);
        break;
      case BusinessObjectType.EXTERNAL_PROVIDER:
        this.toExternalProviderDetailsByParentId(id);
        break;
      case BusinessObjectType.EXTERNAL_PROVIDER_VERSION:
        this.toExternalProviderDetails(id);
        break;
      case BusinessObjectType.COLLECTION_ESTIMATED_COSTS:
        this.toCollectionEstimatedCosts(id);
        break;
      case BusinessObjectType.COLLECTION_ACCOUNTED_COSTS:
        this.toCollectionCostsInvoicing(id);
        break;
      case BusinessObjectType.CLAIM_CALCULATION:
        this.toClaimCalculation(id);
        break;
      case BusinessObjectType.CLAIM_INDEMNIFICATION:
        this.toClaimIndemnificationByParentId(id);
        break;
      case BusinessObjectType.SALES_LEAD:
        this.toSalesLeadDetails(id);
        break;
      case BusinessObjectType.THIRD_PARTY:
        this.toThirdPartyDetails(id);
        break;
      case BusinessObjectType.OFFER:
        this.toInquiryOfferDetails(0, id);
        break;
      case BusinessObjectType.EDGE_OFFER:
        this.toKukeInquiryOfferDetails(id);
        break;
      case BusinessObjectType.CESSION:
        this.toCessionPreview(id);
        break;
      case BusinessObjectType.CLAIM_INDEMNIFICATION_VERSION:
        this.toClaimIndemnification(id);
        break;
      case BusinessObjectType.MALUS_REQUEST:
        this.toBonusMalusRequestDetails('malusRequest', id);
        break;
      case BusinessObjectType.BONUS_REQUEST:
        this.toBonusMalusRequestDetails('bonusRequest', id);
        break;
      case BusinessObjectType.TREATY:
        this.toTreatyDetails(id);
        break;
      case BusinessObjectType.CLAIM_LAWSUIT:
        this.toClaimLawsuitByVersionId(id);
        break;
      case BusinessObjectType.BONDING_CONTRACT_INQUIRY:
        this.bondingContractInquiryService
          .getLastVersionByParentId(id)
          .subscribe((v) => this.toBondingContractInquiryDetails(v.id));
        break;
      case BusinessObjectType.LIMIT:
        this.toLimitDetailsByLimitId(id);
        break;
      case BusinessObjectType.LIMIT_COVER_PLUS:
        this.toLimitDetailsByCoverId(id);
        break;
      case BusinessObjectType.LIMIT_LIST_VERSION:
        this.toPolicyLimitListDetails(id);
        break;
      case BusinessObjectType.BROKER_CONTRACT_RELATION_VERSION:
        this.toBrokerContractRelationDetailsById(id);
        break;
      case BusinessObjectType.BI_REPORT:
        this.toBiReport(id);
        break;
      case BusinessObjectType.USER_HOLIDAY:
        this.toHolidayDetails(id);
        break;
      case BusinessObjectType.COUNTRY_RATING_VERSION:
        this.countryRatingService.getCountryRatingVersion(id).subscribe((rating) => {
          if (this.appService.mehib) {
            this.toCountryRating(rating.countryRating.id, 'OECD');
          } else {
            this.toCountryRating(rating.countryRating.id, 'ALL');
          }
        });
        break;
      case BusinessObjectType.POLICY_DRAWDOWN:
        this.toPolicyDrawdownDetails(id);
        break;
      case BusinessObjectType.COUNTRY_CATEGORY_LIMIT_VERSION:
        this.toCountryCategoryLimitVersion(id);
        break;
      default:
        console.error('Undefined routing for business object type:', relatedToId);
    }
  }

  toStartPage() {
    this._router.navigate(['bon/home']);
  }

  toPasswordChange() {
    this._router.navigate(['bon/password-change']);
  }

  toServiceContacts(params: ServiceContactParams) {
    this._router.navigate(['bon/service-contacts'], {queryParams: params});
  }

  toPolicyInquiryTechnicalDetails(id: number, mainSalesRepUserId: number, statusId: number) {
    const extras: {queryParams: {statusId: number; mainSalesRepUserId?: number}} = {queryParams: {statusId: statusId}};
    if (mainSalesRepUserId) {
      extras.queryParams = {...extras.queryParams, mainSalesRepUserId: mainSalesRepUserId};
    }
    this._router.navigate(['bon/inquiry-details-technical-details', id], extras);
  }

  toCalculator(offerId: number) {
    this._router.navigate(['bon/inquiry-details-calculator-details', offerId]);
  }

  toEcgCalculator(inquiryId: number, offerId: number) {
    this._router.navigate(['bon/ecg-calculator-details', inquiryId, offerId]);
  }

  toDeclarationDetails(policyContractId: number) {
    this._router.navigate(['bon/policy-declaration-details', policyContractId]);
  }

  toBannerManagement() {
    this._router.navigate(['bon', 'banner-management']);
  }

  toClauseTypeSearch() {
    this._router.navigate(['bon/policyclausetype-search']);
  }

  toReportingPeriodEditScreen(policyContractId: number) {
    this._router.navigate(['bon/policy-reporting-periods-details', policyContractId]);
  }

  toClientPolicyPreviewKuke(lastPolicyContractVersionId: number) {
    throw Error('Not implemented in intranet');
  }
}

export class View {
  private static AllViews: {[viewId: number]: View} = {};
  static readonly LIMIT_DETAILS = new View(1, undefined, (router, returnObjectId: number, newObjId: number) =>
    router.toLimitDetailsWithParams({readFromCache: !returnObjectId, requestId: returnObjectId})
  );
  static readonly INQUIRY_DETAILS_NEW = new View(2, 'inquiry-details-newclient');
  static readonly COLLECTION_DETAILS_NEW = new View(3, undefined, (router, returnObjectId: number, newObjId: number) =>
    router.toCollectionDetailsNew({readFromCache: true})
  );
  static readonly POLICY_LIMIT_LIST_DETAILS = new View(
    4,
    undefined,
    (router, returnObjectId: number, newObjId: number) =>
      (<RouterService>router).toPolicyLimitListDetailsWithParams({readFromCache: true})
  );
  static readonly BONDING_CONTRACT_INQUIRY_DETAILS = new View(
    5,
    undefined,
    (router, returnObjectId: number, newObjId: number) =>
      (<RouterService>router).toBondingContractInquiryDetails(null, {readFromCache: true})
  );
  static readonly BOND_DETAILS = new View(6, 'bond-details');
  static readonly INQUIRY_DETAILS_NEW_SUBINSURED = new View(7, 'inquiry-details-subinsured');

  static readonly THIRD_PARTY_DETAILS = new View(8, undefined, (router, returnObjectId: number, newObjId: number) =>
    (<RouterService>router).toThirdPartyDetailsWithParams({id: returnObjectId, newCompanyId: newObjId})
  );

  private constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly navigator?: (service: AbstractRouterService, returnObjectId: number, newObjId: number) => void
  ) {
    View.AllViews[id] = this;
  }

  public static get(viewId: number): View {
    return View.AllViews[viewId];
  }
}

export const BONDING_CONTRACT_OFFER_DETAILS = 'bonding-contract-offer-details';
export const POLICY_CLAUSE_DETAILS = 'policyclause-details';

import {Component, Input} from '@angular/core';
import {BondingContractInquiryVersionDto} from '../../../../bonding_shared/model';
import {ControlContainer, NgForm} from '@angular/forms';
import {BusinessObjectType} from '../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'bonding-contract-inquiry-tab-collateral',
  templateUrl: './bonding-contract-inquiry-tab-collateral.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class BondingContractInquiryTabCollateralComponent {
  @Input() showErrors = false;
  @Input() inquiryVersion: BondingContractInquiryVersionDto;
  @Input() businessObjectTypeId: BusinessObjectType;
  @Input() readonly = false;
}

import {Component, Input} from '@angular/core';
import {PolicyContractViewService} from '../../../services/policy-contract-view.service';
import {Section} from '../section';
import {
  AppConfigService,
  DictionaryService,
  InquiryService,
  PolicyContractBaseDto,
  PolicyInquiryBaseDto,
  PolicyInquiryVersionCriteriaDto,
  PolicyInquiryVersionSimpleDto,
  RouterService,
  SearchCriteria,
} from '../../../../../bonding_shared';
import {PolicyElementaryRight} from '../../../../../bonding_shared/model/dictionary-ids';

@Component({
  selector: 'ecg-policy-inquiries-section',
  templateUrl: './ecg-policy-inquiries-section.component.pug',
})
export class EcgPolicyInquiriesSectionComponent extends Section {
  readonly PolicyElementaryRight = PolicyElementaryRight;

  public renewalInquiries: PolicyInquiryVersionSimpleDto[];
  public annexInquiries: PolicyInquiryVersionSimpleDto[];

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    protected inquiryService: InquiryService,
    protected router: RouterService,
    protected appService: AppConfigService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService);
  }

  @Input()
  set policyContract(policyContractId: number) {
    const criteria = <SearchCriteria<PolicyInquiryVersionCriteriaDto>>{};
    criteria.criteria = <PolicyInquiryVersionCriteriaDto>{};
    criteria.criteria.policyInquiry = <PolicyInquiryBaseDto>{};
    criteria.criteria.policyInquiry.policyContract = <PolicyContractBaseDto>{id: policyContractId};
    criteria.criteria.policyInquiryTypes = ['RENEWAL_INQUIRY'];
    this.inquiryService
      .searchByCriteria<PolicyInquiryVersionCriteriaDto, PolicyInquiryVersionSimpleDto>(criteria)
      .subscribe((inqs) => {
        this.renewalInquiries = inqs.result;
      });
  }

  goToRenewalInquiry(inquiryId: number) {
    this.router.toPolicyRenewalInquiryDetails(inquiryId);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AbstractService, AppConfigService, LoggedUserService} from '../../bonding_shared/services';
import {
  CompanyInDeclarationDto,
  DeclarationCriteriaDto,
  DeclarationDto,
  DeclarationStubDto,
  ReportingPeriodDto,
  SearchCriteria,
  SearchResult,
} from '../model';
import {DeclarationWithoutEntriesDto} from '../model/dtos';

@Injectable()
export class DeclarationAbstractService extends AbstractService {
  constructor(public http: HttpClient, appConfigService: AppConfigService, loggedUserService: LoggedUserService) {
    super(http, appConfigService, loggedUserService);
  }

  findOrInitializeDeclarationByCriteria(
    criteria: SearchCriteria<DeclarationCriteriaDto>
  ): Observable<SearchResult<DeclarationDto>> {
    return this.post1<SearchCriteria<DeclarationCriteriaDto>, SearchResult<DeclarationDto>>(
      criteria,
      this.url + '/findOrInitializeDeclarationByCriteria'
    );
  }

  initializeAmendmentByCriteria(
    criteria: SearchCriteria<DeclarationCriteriaDto>
  ): Observable<SearchResult<DeclarationDto>> {
    return this.post1<SearchCriteria<DeclarationCriteriaDto>, SearchResult<DeclarationDto>>(
      criteria,
      this.url + '/initializeAmendmentByCriteria'
    );
  }

  saveDeclarationStub(declarationStub: DeclarationStubDto): Observable<DeclarationWithoutEntriesDto> {
    return this.put1<DeclarationStubDto, DeclarationWithoutEntriesDto>(
      declarationStub,
      this.url + '/saveDeclarationStub'
    );
  }

  saveZeroDeclarationStub(declarationStub: DeclarationStubDto): Observable<DeclarationWithoutEntriesDto> {
    return this.put1<DeclarationStubDto, DeclarationWithoutEntriesDto>(
      declarationStub,
      this.url + '/saveZeroDeclarationStub'
    );
  }

  saveAmendmentStub(declarationStub: DeclarationStubDto): Observable<DeclarationWithoutEntriesDto> {
    return this.put1<DeclarationStubDto, DeclarationWithoutEntriesDto>(
      declarationStub,
      this.url + '/saveAmendmentStub'
    );
  }

  confirmDeclarationStub(declarationStub: DeclarationStubDto): Observable<DeclarationWithoutEntriesDto> {
    return this.put1<DeclarationStubDto, DeclarationWithoutEntriesDto>(
      declarationStub,
      this.url + '/confirmDeclarationStub'
    );
  }

  confirmAmendmentStub(declarationStub: DeclarationStubDto): Observable<DeclarationWithoutEntriesDto> {
    return this.put1<DeclarationStubDto, DeclarationWithoutEntriesDto>(
      declarationStub,
      this.url + '/confirmAmendmentStub'
    );
  }

  findDeclarationsForPolicy(policyId: number, onlyConfirmed: boolean): Observable<DeclarationWithoutEntriesDto[]> {
    if (onlyConfirmed) {
      return this.findAllConfirmedDeclarationsForPolicy(policyId);
    } else {
      return this.findAllDeclarationsForPolicy(policyId);
    }
  }

  // PORTAL - Set policy
  // INTRANET - Declaration history
  // returns declaration without entries!
  findAllConfirmedDeclarationsForPolicy(policyId: number): Observable<DeclarationWithoutEntriesDto[]> {
    return this.get<DeclarationWithoutEntriesDto[]>(
      this.url + '/findAllConfirmedDeclarationsForPolicy?policyId=' + policyId
    );
  }

  findAllDeclarationsForPolicy(policyId: number): Observable<DeclarationWithoutEntriesDto[]> {
    return this.get<DeclarationWithoutEntriesDto[]>(this.url + '/findAllDeclarationsForPolicy?policyId=' + policyId);
  }

  // PORTAL - Set policy
  // INTRANET - Declaration history
  // returns declaration without entries!
  //
  // only confirmed declarations (effectiveDate in past)
  // only one final declaration for triple (rp.id, llv.id, decOn.id) either declaration or amendment
  findFinalConfirmedDeclarationsForPolicy(policyId: number): Observable<DeclarationWithoutEntriesDto[]> {
    return this.get<DeclarationWithoutEntriesDto[]>(
      this.url + '/findFinalConfirmedDeclarationsForPolicy?policyId=' + policyId
    );
  }

  findCurrentReportingPeriodsForOutstandings(): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(this.url + '/findCurrentReportingPeriodsForOutstandings');
  }

  findCurrentReportingPeriodsForDeclarations(): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(this.url + '/findCurrentReportingPeriodsForDeclarations');
  }

  findAllClientRPsForDeclarationAmendments(): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(this.url + '/findAllClientRPsForDeclarationAmendments');
  }

  findClientReportingPeriodsForTurnoversHistory(): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(this.url + '/findClientReportingPeriodsForTurnoversHistory');
  }

  findClientReportingPeriodsForOutstandingsHistory(): Observable<ReportingPeriodDto[]> {
    return this.get<ReportingPeriodDto[]>(this.url + '/findClientReportingPeriodsForOutstandingsHistory');
  }

  cleanDeclaration(id: number): Observable<ReportingPeriodDto> {
    return this.get<ReportingPeriodDto>(this.url + '/cleanDeclaration/' + id);
  }

  findAllCompaniesToBeDeclaredOn(limitListVersionId: number): Observable<CompanyInDeclarationDto[]> {
    return this.get<CompanyInDeclarationDto[]>(
      this.url + '/findAllCompaniesToBeDeclaredOn?limitListVersionId=' + limitListVersionId
    );
  }

  checkK7Message(reportingPeriodId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/messages/k7/' + reportingPeriodId);
  }

  isProductDependentOnPremiumRate(productTypeId: number): Observable<boolean> {
    return this.get<boolean>(this.url + '/productDependentOnPremiumRate/' + productTypeId);
  }
}

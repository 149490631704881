import {Component, Input, ViewChild} from '@angular/core';
import {SearchDataProvider} from '../../../../bonding_shared/services/search-data-provider';
import {InvoiceCriteriaDto, InvoiceDto} from '../../../../bonding_shared/model/dtos';
import {RouterService} from '../../../../bonding_shared/services/router-service';
import {ATableComponent} from '../../../../bonding_shared/components/aku-table/a-table.component';
import {InvoiceCategory} from '../../../../bonding_shared/model/dictionary-ids';
import {AppConfigService} from '../../../../bonding_shared';

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.component.html',
})
export class InvoiceListComponent {
  @ViewChild(ATableComponent, {static: true}) invoiceTable: ATableComponent<InvoiceDto>;

  @Input() categoryId: InvoiceCategory;
  @Input() pageSize: number = 20;

  @Input() showContractNumber: boolean = true;

  @Input() set dataProvider(dp: SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>) {
    this.invoiceTable.dataProvider = dp;
    this.invoiceTable.search();
  }

  constructor(public router: RouterService, private appService: AppConfigService) {}

  isEmpty(): boolean {
    if (!this.invoiceTable) {
      return true;
    }
    return this.invoiceTable.totalCount < 1;
  }

  get kuke(): boolean {
    return this.appService.kuke;
  }
}

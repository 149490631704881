/**
 * Created by siminski on 03.08.2016.
 */
import {forwardRef, Component, Input, Output, EventEmitter, AfterViewInit, Injector} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, AbstractControl, NgControl} from '@angular/forms';
import {HooverColor} from '../hover-info';
import {DateUtils} from '../../utils/date-utils';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

const DATE_ROW_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateRowComponent),
  multi: true,
};

@Component({
  selector: 'date-row',
  template: `
    <div class="bon-row">
      <label class="bon-label">
        {{ labelKey ? (labelKey | translate) : label }}
        <hover-info [message]="getHooverMsg()" [color]="getHooverColor()">></hover-info>
      </label>
      <date-picker
        [ngModel]="value"
        [type]="type"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [notPast]="notPast"
        [notFuture]="notFuture"
        [required]="required"
        (changeItem)="onChange($event)"
        [disabled]="disabled"
        [floatRight]="floatRight"
        [ngClass]="getCssClasses()"
        [presentationMode]="presentationMode"
      >
      </date-picker>
      <error-message [control]="control" [show]="showErrors"></error-message>
    </div>
  `,
  providers: [DATE_ROW_CONTROL_VALUE_ACCESSOR],
})
export class DateRowComponent implements ControlValueAccessor, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() showErrors: boolean;
  @Input() errorClass = '';
  @Input() type = 'date';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() required: boolean;
  @Input() warning = false;
  @Input() notPast: boolean;
  @Input() notFuture: boolean;
  @Input() hooverMsg: string;
  @Input() presentationMode = false;
  @Input() hooverColor: HooverColor;
  @Input() counter = false;
  @Input() floatRight = false;
  @Input() counterWarningDay: number;
  @Output() changeItem = new EventEmitter<Date>();

  private onChangeListeners: Function;
  private onTouchedListeners: Function;
  public value: Date;

  constructor(private translateService: TranslateService, private injector: Injector) {}

  writeValue(obj: any): void {
    if ((!obj && !this.value) || obj === this.value) {
      return;
    }
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onChange(event: Date) {
    if ((!event && !this.value) || event === this.value) {
      return;
    }

    this.value = event;
    if (this.onChangeListeners) {
      this.onChangeListeners(this.value);
    }
    this.changeItem.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public getCssClasses() {
    const cssClasses: any = {};
    cssClasses['bon-input-size'] = true;
    if (this.warning) {
      cssClasses['bon-input-size-red'] = true;
    }
    if (this.control?.invalid && this.showErrors) {
      cssClasses[this.errorClass] = true;
    }
    return cssClasses;
  }

  getHooverMsg(): string {
    return this.hooverMsg || this.getCounterMsg();
  }

  getHooverColor(): HooverColor {
    return this.hooverColor || this.getCounterColor();
  }

  getCounterMsg(): string {
    if (!this.value || !this.counter) {
      return undefined;
    }
    if (DateUtils.isToday(this.value)) {
      return this.translateService.instant('common.deadlineToday');
    }
    if (DateUtils.inPastNoTime(this.value)) {
      return this.translateService.instant('common.daysLate', {days: DateUtils.daysBeforeToday(this.value)});
    }
    return this.translateService.instant('common.daysLeft', {days: DateUtils.daysAfterToday(this.value)});
  }

  getCounterColor(): HooverColor {
    if (!this.value || !this.counter) {
      return undefined;
    }
    return DateUtils.daysAfterToday(this.value) <= this.counterWarningDay ? 'red' : 'blue';
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.control = this.injector.get(NgControl, null).control;
    }
  }
}

import {Component, Input} from '@angular/core';
import {
  AppConfigService,
  CollectionInvoiceDto,
  CollectionPaymentsBaseDto,
  CollectionPaymentsDto,
  CollectionVersionDto,
  DictionaryBaseDto,
  LoggedUserService,
  StringUtils,
} from '../../../bonding_shared';
import {UntypedFormGroup} from '@angular/forms';
import {CollectionPaymentAllocation, CollectionType, Currency} from '../../../bonding_shared/model/dictionary-ids';
import * as _ from 'lodash';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

const CONFIG: Record<
  string,
  {
    func: (i: CollectionInvoiceDto) => boolean;
    trueLabel: string;
    falseLabel: string;
    totalLabel: string;
    showPercent: boolean;
  }
> = {
  overdue: {
    func: (i) => moment().isAfter(moment(i.dueDate), 'd'),
    trueLabel: 'collection.balanceSummary.overdue',
    falseLabel: 'collection.balanceSummary.notOverdue',
    totalLabel: 'collection.totalOpenAmountInCredit',
    showPercent: false,
  },
  insurer: {
    func: () => true,
    trueLabel: 'collection.balanceSummary.allocationInsurer',
    falseLabel: 'collection.balanceSummary.allocationInsured',
    totalLabel: 'collection.totalOpenAmount',
    showPercent: false,
  },
  insured: {
    func: (i) => true,
    trueLabel: 'collection.balanceSummary.insured',
    falseLabel: 'collection.balanceSummary.notInsured',
    totalLabel: 'collection.totalOpenAmount',
    showPercent: false,
  },
};

@Component({
  selector: 'collection-balance-summary',
  templateUrl: 'collection-balance-summary.component.pug',
})
export class CollectionBalanceSummaryComponent {
  @Input() formModel: UntypedFormGroup;
  @Input() hidePartition: boolean;
  @Input() partitionBy: string;
  @Input() version: CollectionVersionDto;
  @Input() showSummary = true;
  @Input() showDisputeAmount = true;
  @Input() showErrors: boolean;

  @Input() set payments(payments: CollectionPaymentsBaseDto) {
    this._payments = payments;
  }

  private dummyEmptyPayments = this.getEmpty();

  get payments() {
    return (
      this._payments || (this.version && this.version.parent && this.version.parent.payments) || this.dummyEmptyPayments
    );
  }
  _payments: CollectionPaymentsBaseDto;
  summary: InvoiceSummary[];
  currencies: DictionaryBaseDto[] = [];
  currencyRegExp: RegExp;

  constructor(
    private translateService: TranslateService,
    private appService: AppConfigService,
    private loggedUserService: LoggedUserService
  ) {}

  get canAddDisputeAmount(): boolean {
    return (
      this.currencies?.length > this.version?.disputeAmounts.length &&
      !(this.appService.kuke && this.loggedUserService.portal)
    );
  }

  get config() {
    return CONFIG[this.partitionBy];
  }

  get showEquivalent() {
    return (
      this.version &&
      this.version.parent &&
      this.version.parent.type &&
      this.version.parent.type.id === CollectionType.RECOVERY_CREDIT_INSURANCE
    );
  }

  @Input()
  set invoices(inv: CollectionInvoiceDto[]) {
    this.currencies = inv
      .filter((thing, i, arr) => arr.findIndex((t) => t.currency.id === thing.currency.id) === i)
      .map((i) => i.currency);
    let regexpString = '';
    let index = 0;
    this.currencies.forEach((adt) => {
      index += 1;
      regexpString += adt.code;
      if (index !== this.currencies.length) {
        regexpString += '|';
      }
    });
    this.currencyRegExp = new RegExp(regexpString);

    this.summary = _.chain(inv)
      .groupBy((i) => i.currency.id)
      .map((invoices, currencyId) => {
        const currencyCode = invoices[0].currency.code;

        const both = _.partition(invoices, this.config.func);
        const insured =
          this.partitionBy === 'insurer'
            ? this.sumPartition(invoices, true, 'INSURER')
            : this.sumPartition(both[0], true);
        const notInsured =
          this.partitionBy === 'insurer'
            ? this.sumPartition(invoices, false, 'INSURED')
            : this.sumPartition(both[1], false);

        let sumLastPaymentDate = insured.lastPaymentDate;
        if (notInsured.lastPaymentDate && insured.lastPaymentDate) {
          sumLastPaymentDate =
            insured.lastPaymentDate > notInsured.lastPaymentDate ? insured.lastPaymentDate : notInsured.lastPaymentDate;
        } else if (!sumLastPaymentDate) {
          sumLastPaymentDate = notInsured.lastPaymentDate;
        }

        const sum = <InvoiceSummary>{
          total: insured.total + notInsured.total,
          paid: insured.paid + notInsured.paid,
          inRecovery: insured.inRecovery + notInsured.inRecovery,
          balance: insured.balance + notInsured.balance,
          groupLabel: this.translateService.instant('collection.balanceSummary.sum'),
          lastPaymentDate: sumLastPaymentDate,
          lastPaymentAmount: insured.lastPaymentAmount + notInsured.lastPaymentAmount,
        };

        if (this.partitionBy === 'insurer') {
          sum.lastPaymentDate = insured.lastPaymentDate;
          sum.lastPaymentAmount = insured.lastPaymentAmount;
          if (!sum.lastPaymentDate || notInsured.lastPaymentDate > sum.lastPaymentDate) {
            sum.lastPaymentDate = notInsured.lastPaymentDate;
            sum.lastPaymentAmount = notInsured.lastPaymentAmount;
          }
          // insured.lastPaymentDate = notInsured.lastPaymentDate = undefined;
          // insured.lastPaymentAmount = notInsured.lastPaymentAmount = undefined;
        }

        insured.totalPercent = Math.round((insured.total / sum.total) * 100);
        insured.balancePercent = Math.round((insured.balance / sum.balance) * 100);
        notInsured.totalPercent = Math.round((notInsured.total / sum.total) * 100);
        notInsured.balancePercent = Math.round((notInsured.balance / sum.balance) * 100);

        if (insured.lastPaymentAmount && insured.lastPaymentAmount > 0 && !insured.lastPaymentDate) {
          insured.lastPaymentDate = sum.lastPaymentDate;
        }

        if (notInsured.lastPaymentAmount && notInsured.lastPaymentAmount > 0 && !notInsured.lastPaymentDate) {
          notInsured.lastPaymentDate = sum.lastPaymentDate;
        }

        const all = [insured, notInsured, sum];
        all.forEach((x) => (x.currency = currencyCode));
        return this.hidePartition ? [sum] : all;
      })
      .flatten()
      .value();
  }

  sumPartition(
    invoices: CollectionInvoiceDto[],
    partition: boolean,
    allocation?: 'INSURER' | 'INSURED'
  ): InvoiceSummary {
    const res = invoices.reduce(
      (sum, i) => {
        sum.total += this.getOpenAmountGross(i, allocation) || 0;
        sum.inRecovery += i.inRecovery
          ? this.getOpenAmountGross(i, allocation) - this.getPaidAmount(i, allocation) || 0
          : 0;
        sum.paid += this.getPaidAmount(i, allocation) || 0;

        if (i.payments && i.payments.length > 0) {
          const payments =
            !!allocation && !StringUtils.isEmpty(allocation)
              ? i.payments.filter((x) => x.allocation.id === CollectionPaymentAllocation[allocation])
              : i.payments;
          let lastPayment = null;
          if (payments && payments.length > 0) {
            lastPayment = payments.reduce((a, b) => (a.dateOfPayment > b.dateOfPayment ? a : b));
          }
          if (lastPayment && (!sum.lastPaymentDate || lastPayment.dateOfPayment > sum.lastPaymentDate)) {
            sum.lastPaymentDate = lastPayment.dateOfPayment;
            sum.lastPaymentAmount = lastPayment.amountGross;
          }
        }
        return sum;
      },
      <InvoiceSummary>{total: 0, inRecovery: 0, paid: 0, lastPaymentAmount: 0}
    );
    res.balance = res.total - res.paid;
    res.groupLabel = this.translateService.instant(partition ? this.config.trueLabel : this.config.falseLabel);
    return res;
  }

  public getBalance() {
    return Math.max(this.version.indemnification - this.payments.equivalentAmount || 0, 0);
  }

  private getEmpty(): CollectionPaymentsDto {
    return <CollectionPaymentsDto>{
      mainDebt: [],
      other: [],
      equivalentAmount: 0,
      equivalentCurrency: <DictionaryBaseDto>{id: Currency.PLN},
    };
  }

  private getOpenAmountGross(i: CollectionInvoiceDto, allocation: 'INSURER' | 'INSURED') {
    switch (allocation) {
      case 'INSURER':
        return i.insurersOpenAmountGross;
      case 'INSURED':
        return i.insuredsOpenAmountGross;
      default:
        return i.openAmountGross;
    }
  }

  private getPaidAmount(i: CollectionInvoiceDto, allocation: 'INSURER' | 'INSURED') {
    switch (allocation) {
      case 'INSURER':
        return i.insurersPaidAmount;
      case 'INSURED':
        return i.insuredsPaidAmount;
      default:
        return i.paidAmount;
    }
  }
}

interface InvoiceSummary {
  currency: string;
  groupLabel: string;
  total: number;
  totalPercent: number;
  lastPaymentAmount: number;
  lastPaymentDate: Date;
  paid: number;
  inRecovery: number;
  balance: number;
  balancePercent: number;
}

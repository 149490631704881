import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, Validators} from '@angular/forms';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HooverColor} from '../hover-info';

/**
 * Created by siminski on 02.08.2016.
 */

const INPUT_ROW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRowComponent),
  multi: true,
};

@Component({
  selector: 'input-row',
  template: `
    <div class="bon-row">
      <label class="bon-label">
        {{ labelKey ? (labelKey | translate) : label }}
        <hover-info [message]="hoverMsg" [color]="hoverColor"></hover-info>
      </label>
      <input
        [style.position]="presentationMode ? 'absolute' : 'relative'"
        [style.opacity]="presentationMode ? '0' : '1'"
        [style.left]="presentationMode ? '-10000px' : '0'"
        [style.z-index]="presentationMode ? '-1000' : '0'"
        [type]="type"
        [value]="value"
        (input)="onInput($event)"
        (blur)="onTouchedListeners()"
        [disabled]="disabled"
        [pattern]="pattern"
        [readonly]="readonly"
        [placeholder]="placeholder"
        [ngClass]="control | bonInputCssClasses: showErrors:errorClass"
        (keydown)="onKeyDown($event)"
        (paste)="onPaste($event)"
      />
      <div *ngIf="presentationMode" class="bon-input-size">
        <span
          *ngIf="!presentationModeLinkAction"
          [class.float-right]="floatRight"
          [class.presentation]="type !== 'number'"
          [class.presentation-num]="type === 'number'"
          >{{ value }}</span
        >
        <a class="a-link " *ngIf="presentationModeLinkAction" (click)="presentationModeLinkAction()">
          <span class="presentation">{{ value }}</span>
        </a>
      </div>
      <error-message
        [control]="control"
        [show]="showErrors"
        [errorTypePrecedence]="errorTypePrecedence"
      ></error-message>
    </div>
  `,
  providers: [INPUT_ROW_CONTROL_VALUE_ACCESSOR],
})
export class InputRowComponent implements ControlValueAccessor, AfterViewInit {
  @Input() control: AbstractControl;
  @Input() label: string;
  @Input() labelKey: string;
  @Input() disabled: any;
  @Input() readonly: any;
  _presentationMode = false;
  @Input() set presentationMode(presentationMode: boolean) {
    this._presentationMode = presentationMode;
    if (this.model) {
      this.model.nativeElement.value = this.value;
    }
  }
  get presentationMode() {
    return this._presentationMode;
  }
  @Input() presentationModeLinkAction: () => void;
  @Input() showErrors: boolean;
  @Input() required: boolean;
  @Input() pattern: string;
  @Input() errorClass = '';
  @Input() type = 'string';
  @Input() floatRight = false;
  @Input() placeholderKey: string;
  @Input() hoverMsg: string;
  @Input() hoverColor: HooverColor;
  @Input() errorTypePrecedence: string[] = [];
  @Output() changeValue = new EventEmitter<string>();
  @Output() paste = new EventEmitter<ClipboardEvent>();
  @Output() keyDown = new EventEmitter<KeyboardEvent>();

  @Input() keyDownFunc: Function;

  @ViewChild('model') model: ElementRef;

  private onChangeListeners: Function;
  public onTouchedListeners: Function;
  public value: string;

  constructor(protected translateService: TranslateService, private injector: Injector) {}

  writeValue(obj: any): void {
    this.value = obj || '';
  }

  registerOnChange(fn: any): void {
    this.onChangeListeners = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedListeners = fn;
  }

  onInput(event: Event) {
    this.value = (<any>event.target).value;
    this.onChangeListeners(this.value);
    this.changeValue.emit(this.value);
  }

  /**
   * Needed to be able to disable model-validated components. Such components must be disabled in FormGroup definition:
   *
   *  Example:
   * form = new FormGroup({
   *     first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),
   *     last: new FormControl('Drew', Validators.required)
   *   });
   *
   * @param disabled
   */
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get placeholder() {
    return this.placeholderKey ? this.translateService.instant(this.placeholderKey) : '';
  }

  public onPaste(clipboardEvent: ClipboardEvent) {
    this.paste.emit(clipboardEvent);
  }

  public onKeyDown(e: KeyboardEvent) {
    if (this.keyDownFunc) {
      this.keyDownFunc(e);
    }
    this.keyDown.emit(e);
  }

  ngAfterViewInit(): void {
    if (!this.control) {
      this.control = this.injector.get(NgControl, null).control;
    }
    this.control.addValidators(Validators.maxLength(255));
  }
}

/**
 * Service for common logic of invoice components.
 *
 */
import {Injectable} from '@angular/core';
import {SearchDataProvider} from '../../../bonding_shared/services/search-data-provider';
import {
  BrokerContractIdDto,
  BusinessObjectDto,
  ContractIdDto,
  DictionaryBaseDto,
  InvoiceCriteriaDto,
  InvoiceDto,
  InvoiceIdDto,
  LocalDateRange,
} from '../../../bonding_shared/model/dtos';
import {InvoiceService} from '../../../bonding_shared/services/invoice.service';
import {LoggedUserService} from '../../../bonding_shared/services/logged-user.service';
import {ContractLinkDto, PolicyContractIdDto} from '../../../bonding_shared/model';
import {InvoiceCategory} from '../../../bonding_shared/model/dictionary-ids';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceUtils} from '../../../bonding_shared/utils/invoice-utils';

@Injectable()
export class InvoiceGuiService {
  dataProviders: SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>[] = [];

  constructor(
    private service: InvoiceService,
    private loggedUserService: LoggedUserService,
    private translateService: TranslateService
  ) {}

  createDataProvider<C, R>(): SearchDataProvider<C, R> {
    return new SearchDataProvider<C, R>(this.service);
  }

  public getDataProvider(
    category: InvoiceCategory,
    businessObjectTypeId: number,
    businessObjectId: number
  ): SearchDataProvider<InvoiceCriteriaDto, InvoiceDto> {
    let dataProvider = this.dataProviders[category];
    // Creating criteria cache. If we do the search from external view (i.e. policy-details), we clear old criteria.
    if (!dataProvider || businessObjectTypeId || businessObjectId) {
      dataProvider = new SearchDataProvider<InvoiceCriteriaDto, InvoiceDto>(this.service);
      dataProvider.searchCriteria.criteria = <InvoiceCriteriaDto>{};
      dataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{};
      dataProvider.searchCriteria.criteria.correctedInvoice = <InvoiceIdDto>{};
      dataProvider.searchCriteria.criteria.dueDateRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.issueDateRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.creationDateRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.dateFromRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.dateToRange = <LocalDateRange>{};
      dataProvider.searchCriteria.criteria.contractLink = <ContractLinkDto>{};
      dataProvider.searchCriteria.criteria.contractLink.policyContract = <PolicyContractIdDto>{};
      dataProvider.searchCriteria.criteria.contractLink.contract = <ContractIdDto>{};
      dataProvider.searchCriteria.criteria.contractLink.brokerContract = <BrokerContractIdDto>{};
      dataProvider.searchCriteria.criteria.category = <DictionaryBaseDto>{id: category};

      dataProvider.textSearch = false;
      this.dataProviders[category] = dataProvider;
    } else {
      if (dataProvider.searchCriteria.criteria.businessObject.relatedToId) {
        // Previous search was from the bond view - we must clean it.
        dataProvider.searchCriteria.criteria.businessObject = <BusinessObjectDto>{};
      }
    }

    InvoiceUtils.fillContractLinkCriteria(dataProvider.searchCriteria.criteria, businessObjectTypeId, businessObjectId);

    return dataProvider;
  }

  public getInvoiceCategoryLabel(invoiceCategory: DictionaryBaseDto, plural: boolean): string {
    if (!invoiceCategory) {
      return '';
    }

    let labelKey = 'invoice.guiService.';
    labelKey += plural ? 'categories.' : 'category.';
    labelKey += invoiceCategory.code;

    return this.translateService.instant(labelKey);
  }
}

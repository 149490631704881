import {from as observableFrom} from 'rxjs';
import {concatMap, filter} from 'rxjs/operators';
import {Directive, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgForm, RadioControlValueAccessor, ValidatorFn, Validators} from '@angular/forms';
import {DetailsView} from '../../../../bonding_shared/components/details-view/details-view';
import {
  AddressDto,
  AppConfigService,
  BackendError,
  CompanyRawDto,
  CompanySimpleDto,
  CompanySimpleWithIdentifiersDto,
  CompanySimpleWithNaceCodesDto,
  ContactPersonService,
  CustomValidators,
  DictionaryBaseDto,
  DictionaryBaseService,
  DictionaryService,
  DocumentService,
  ErrorReason,
  GrowlService,
  isErrorReasons,
  LanguageService,
  LoggedUserService,
  NumberFormatDto,
  NumberFormatService,
  QuotationBondDto,
  QuotationDto,
  StringUtils,
} from '../../../../bonding_shared';
import {PortalRouterService, QuotationService} from '../../../services';
import {
  BondType,
  ClientKnownSinceTimeElapsed,
  CompanyIdentifierType,
  ContactPersonType,
  Country,
  NumberType,
  QuotationStatus,
  UserRole,
} from '../../../../bonding_shared/model/dictionary-ids';
import {BusinessUtils} from '../../../../bonding_shared/utils/business-utils';
import {BondTypeListOnQuotationDetailsComponent} from './components/bond-type-list-on-quotation-details.component';
import {QuotationContractDataComponent} from './components/quotation-contract-data.component';
import {TranslateService} from '@ngx-translate/core';

@Directive()
export abstract class QuotationDetailsComponent extends DetailsView implements OnInit {
  readonly QuotationStatus = QuotationStatus;

  @ViewChild('bondTypeListOnQuotation', {static: true})
  bondTypeList: BondTypeListOnQuotationDetailsComponent<QuotationBondDto>;
  @ViewChild(QuotationContractDataComponent, {static: true}) contractData: QuotationContractDataComponent;
  @ViewChild('ngForm')
  set ngForm(cmp: NgForm) {
    if (cmp) {
      this._ngForm = cmp;
    }
  }
  get ngForm(): NgForm {
    return this._ngForm;
  }
  _ngForm: NgForm;
  nationalIdNumberFormat: NumberFormatDto;
  taxNumberFormat: NumberFormatDto;
  brokerDiscount: boolean;

  test: RadioControlValueAccessor;
  noReportErrorCodesCR = new Set<String>();
  notAnonymousButtonStatuses: string[] = ['QUO_DRA', 'QUO_CLO', 'QUO_EXP', 'QUO_REJ'];

  self = this;
  quotationId: number;
  quotation: QuotationDto;
  tariffBackup: BrokerQuotationTariff;
  tariff: BrokerQuotationTariff;
  quotationContractMaxTotalLiabilityLimit: number;
  quotationContractMaxBondLiabilityLimit: number;
  quotationContractMaxBondLiabilityLimitCombined: number;
  quotationContractMaxSingleBondLimitCombined: number;
  showErrors = false;
  contractMaxBondLiabilitySum: number;
  fetchingRating = false;
  fetchingCoinsured = false;
  bankBoosterLanguages: DictionaryBaseDto[] = [];

  facilityFeeCurrentYear: number;

  urlPrefix: string;
  draftLanguages: DictionaryBaseDto[];
  clientScores: any[] = [];

  abstract clientKnownSinceBoundaryLabelKey;

  protected constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected routerService: PortalRouterService,
    protected quotationService: QuotationService,
    protected loggedUserService: LoggedUserService,
    protected dictionaryService: DictionaryService,
    protected numberFormatService: NumberFormatService,
    protected dictionaryBaseService: DictionaryBaseService,
    protected languageService: LanguageService,
    protected translateService: TranslateService,
    protected growlService: GrowlService,
    protected documentService: DocumentService,
    public apiService: AppConfigService
  ) {
    super(growlService);
    this.urlPrefix = this.apiService.backendUrl;
    this.selectorNameList.push('Client');
    this.selectorNameList.push('Coinsured');
    this.noReportErrorCodesCR.add('ER-66');
    this.noReportErrorCodesCR.add('ER-114');
    this.initializeSelectorEmitters(false);
  }

  abstract contactPersonsTooltipMessageKey: string;

  ngOnInit() {
    this.route.params.subscribe((params) => this.initializeView(params));
  }

  initializeView(params: Params) {
    this.serverErrors = undefined;
    const quotationId: number = +params['id'];
    const contractId: number = params['contractId'] ? +params['contractId'] : 0;
    const anonymous: boolean = !!params['anon'];
    if (quotationId === this.quotationId) {
      return;
    }
    this.dictionaryService
      .getDictionary('Country')
      .pipe(
        concatMap((dictionary) => observableFrom(dictionary)),
        filter((entry) => entry.id === this.loggedUserService.getLoggedUserData().company.address.country.id)
      )
      .subscribe((entry) => {
        this.quotationContractMaxTotalLiabilityLimit = +entry.properties[41000007];
        this.quotationContractMaxBondLiabilityLimit = +entry.properties[41000008];
        this.onChangeTurnover();
        if (!this.loggedUserIsBEBankBroker() && this.contractData) {
          this.contractData.onBondListChange();
        }
        this.numberFormatService.getNumberFormat(entry.id, NumberType.NATIONALID).subscribe(
          (numberFormat) => (this.nationalIdNumberFormat = numberFormat),
          () => console.log('Error on getNumberFormat,')
        );
        this.numberFormatService.getNumberFormat(entry.id, NumberType.TAXID).subscribe(
          (numberFormat) => (this.taxNumberFormat = numberFormat),
          () => console.log('Error on getNumberFormat,')
        );
      });
    this.languageService.getDraftPackageLanguages().subscribe((entries) => {
      this.draftLanguages = entries;
    });
    this.languageService.getBankBoosterLanguages().subscribe((entries) => {
      this.bankBoosterLanguages = entries;
    });

    if (this.loggedUserIsBEBankBroker()) {
      this.quotationService.getBEBankClientScores().subscribe((scs) => {
        this.clientScores.length = 0;
        scs.forEach((c) => this.clientScores.push({id: c}));
      });
    }

    this.initializeQuotation(quotationId, contractId, anonymous);
  }

  initializeQuotation(quotationId: number, contractId: number, anonymous: boolean) {
    this.tariffBackup = <BrokerQuotationTariff>{};
    this.tariffBackup.collateral = 1;
    this.brokerDiscount = false;
    if (quotationId > 0) {
      this.quotationService.getQuotation(quotationId).subscribe((quotation) => {
        this.setQuotation(quotation);
      });
    } else {
      this.saveButton.hidden = false;
      this.cancelButton.hidden = false;
      this.quotationService.getQuotationTemplate(contractId, anonymous).subscribe((quotation) => {
        this.setQuotation(quotation);
      });
      // TODO: fetch contract by contractId, fill in quotation
      // TODO: create validators based on contract data, they should be updated when contract data is fetched.
    }
  }

  get clientDataDisabled(): boolean {
    return (
      this.quotation &&
      this.quotation.status.id !== QuotationStatus.QUOTATION_DRAFT &&
      this.quotation.status.id !== QuotationStatus.QUOTATION_REPORT_TO_CONFIRM
    );
  }

  get tariffDataDisabled(): boolean {
    return this.quotation && this.quotation.status.id !== QuotationStatus.QUOTATION_READY;
  }

  get mainDataDisabled(): boolean {
    return (
      this.quotation &&
      ![QuotationStatus.QUOTATION_DRAFT, QuotationStatus.QUOTATION_READY].includes(this.quotation.status.id)
    );
  }

  get clientKnownSinceDataDisabled(): boolean {
    return this.mainDataDisabled || (this.quotation.id && !!this.quotation.client);
  }

  get sepaDataDisabled(): boolean {
    return (
      this.quotation &&
      [
        QuotationStatus.QUOTATION_CONCLUDED,
        QuotationStatus.QUOTATION_EXPIRED,
        QuotationStatus.QUOTATION_CLOSED,
      ].includes(this.quotation.businessStatus.id)
    );
  }

  setQuotation(quotation: QuotationDto) {
    this.quotation = quotation;
    if (!this.form) {
      setTimeout(() => (this.form = this.ngForm.form), 0);
    }
    this.tariffBackup.facilityFee = this.quotation.facilityFee;
    this.tariffBackup.collateral = this.quotation.collateral;
    if (this.loggedUserIsBEBankBroker() && this.quotation.id && this.quotation.id > 0) {
      setTimeout(() => this.recalculateTariff(), 0);
    } else {
      this.facilityFeeCurrentYear = quotation.facilityFee - quotation.yearlyFacilityFee;
    }
    this.saveButton.hidden =
      this.quotation.businessStatus.id !== QuotationStatus.QUOTATION_DRAFT &&
      this.quotation.businessStatus.id !== QuotationStatus.QUOTATION_READY;
    this.cancelButton.hidden =
      this.quotation.businessStatus.id !== QuotationStatus.QUOTATION_DRAFT &&
      this.quotation.businessStatus.id !== QuotationStatus.QUOTATION_READY;
    this.onChangeTurnover();
    if (!this.loggedUserIsBEBankBroker() && this.contractData) {
      this.contractData.onBondListChange();
    }
    this.brokerDiscount = this.quotation.discount !== undefined && !this.isSpecialBroker();
    if (quotation.id > 0) {
      this.routerService.toQuotationDetails(quotation.id);
    }
    if (!this.quotation.contactPersons) {
      this.quotation.contactPersons = [];
    }
  }

  onSave(issueContract?: boolean, recalculateTariff?: boolean) {
    if (!this.checkIfMainContactPerson()) {
      this.setFirstAsMainContact();
    }
    this.showErrors = false;
    delete this.serverErrors;
    if (this.quotation.status.id !== QuotationStatus.QUOTATION_CLOSED && !this.form.valid) {
      this.showErrors = true;
      this.growlService.error('Form has errors');
      StringUtils.logFormInvalidFieldsRecursive(this.form);
      return;
    }
    this.inProgress = true;
    this.quotation.recalculateTariff = recalculateTariff;
    this.quotationService.saveQuotation(this.quotation).subscribe({
      next: (quotation) => {
        this.closeAllSelectors();
        this.setQuotation(quotation);
        if (issueContract) {
          this.issueContract();
        } else {
          this.inProgress = false;
        }
      },
      error: (error) => {
        this.handleServerError(error);
        this.inProgress = false;
      },
      complete: () => {
        if (!issueContract) {
          this.showSavedMsg('Quotation saved');
        }
      },
    });
    delete this.quotation.recalculateTariff;
  }

  onCancel() {
    this.closeAllSelectors();
    this.showErrors = false;
    delete this.serverErrors;
    const quotationId: number = +this.route.snapshot.params['id'];
    const contractId: number = this.route.snapshot.params['contractId'] ? +this.route.snapshot.params['contractId'] : 0;
    const anonymous: boolean = !!this.route.snapshot.params['anon'];
    this.initializeQuotation(quotationId, contractId, anonymous);
    this.saveButton.disabled = false;
    this.form.markAsPristine();
  }

  recalculateTariff() {
    if (
      (this.form.get('tariff.facilityFee') && this.form.get('tariff.facilityFee').invalid) ||
      (this.form.get('tariff.collateral') && this.form.get('tariff.collateral').invalid) ||
      (this.form.get('tariff.brokerDiscount') && this.form.get('tariff.brokerDiscount').invalid)
    ) {
      this.growlService.error('Errors on tariff');
      return;
    }
    console.log('recalculating..');
    this.inProgress = true;
    this.saveButton.disabled = true;
    let facilityFee: number = null;
    let collateral: number = null;
    if (this.form.get('tariff.facilityFee') && this.form.get('tariff.facilityFee').dirty) {
      facilityFee = this.quotation.facilityFee || 0;
    } else {
      collateral = this.quotation.collateral || 0;
    }
    this.quotationService
      .calculateTariff(this.quotation.id, facilityFee, collateral, this.quotation.discount)
      .subscribe({
        next: (tariff) => {
          this.quotation.facilityFee = tariff.facilityFee;
          this.quotation.collateral = tariff.collateral;
          this.quotation.yearlyFacilityFee = tariff.yearlyFacilityFee;
          console.log('premium rate = ' + tariff.rate);
          this.quotation.premiumRate = tariff.rate;
          if (this.loggedUserIsBEBankBroker()) {
            this.facilityFeeCurrentYear = tariff.currentYearFacilityFee;
          } else {
            this.facilityFeeCurrentYear = this.quotation.facilityFee - this.quotation.yearlyFacilityFee;
          }
        },
        error: (error) => {
          this.growlService.error('Error when calculating tariff!');
          this.handleServerError(error, false);
        },
        complete: () => {
          this.inProgress = false;
          this.showErrors = false;
          delete this.serverErrors;
          this.saveButton.disabled = false;
          const tariffControl = this.form.get('tariff');
          if (tariffControl) {
            tariffControl.markAsPristine();
            tariffControl.updateValueAndValidity();
          }
        },
      });
  }

  conditionsAccepted() {
    console.log('conditionsAccepted');
    this.setAndUpdateValidator('sepa.iban', Validators.required);
    this.setAndUpdateValidator('sepa.bic', Validators.required);
    this.onSave(true);
  }

  set mainContactPerson(value: number) {}

  checkIfMainContactPerson(): boolean {
    if (this.quotation.contactPersons) {
      for (const contact of this.quotation.contactPersons) {
        if (ContactPersonService.isMain(contact)) {
          return true;
        }
      }
    }
    return false;
  }

  setFirstAsMainContact(): void {
    if (this.quotation.contactPersons && this.quotation.contactPersons.length > 0) {
      this.quotation.contactPersons[0].type = <DictionaryBaseDto>{id: ContactPersonType.MAIN};
    }
  }

  checkLiabilityValues() {
    let maxBondLiabilitySumOfTypes = 0;
    for (const bondType of this.quotation.contractBondTypes) {
      if (bondType.bondType.id === BondType.WARENLIEFERUNGEN || bondType.bondType.id === BondType.MIETBURGSCHAFT) {
        maxBondLiabilitySumOfTypes += bondType.maxBondLiability;
      }
    }
    if (maxBondLiabilitySumOfTypes > 0.25 * 0.9 * this.quotation.contractMaxTotalLiability) {
      this.growlService.warning('errorMessage.warenlieferungenAndMietburgschaftLiabilityAlmostExceeded');
    }
  }

  issueContract() {
    this.checkLiabilityValues();
    this.inProgress = true;
    this.quotationService.issueContract(this.quotation.id).subscribe({
      next: (quotation) => {
        this.growlService.notice('Contract issued');
        this.setQuotation(quotation);
        this.inProgress = false;
      },
      error: (error) => {
        this.serverErrors = error;
        this.growlService.warning('Quotation saved, but could not issue Contract');
        console.log(error);
        this.inProgress = false;
      },
    });
  }

  onPreconfigure() {
    this.quotationService
      .getQuotationPreconfiguration(this.quotation.client.id, this.quotation.clientTurnover)
      .subscribe({
        next: (conf) => {
          this.quotation.contractMaxTotalLiability = conf.maxTotalLiability;
          if (conf.contractBondTypes && conf.contractBondTypes.length > 0) {
            this.quotation.contractBondTypes = conf.contractBondTypes;
          }
          this.serverErrors = conf.warnings;
        },
        error: (error) => {
          this.handleServerError(error);
        },
      });
  }

  onSelectClient(client: CompanySimpleWithNaceCodesDto) {
    this.serverErrors = undefined;
    if (client) {
      this.quotation.client = client;
    }
    this.quotation.orderReport = false;
    if (!client.score) {
      this.fetchingRating = true;
      this.inProgress = true;
      // The company from external search should have one external id only.
      const externalId = client.identifiers.find(
        (ident) => ident.type.id === CompanyIdentifierType.EXTERNAL_ID
      ).identifier;
      this.quotationService.getCompany(this.quotation.biSource.id, externalId).subscribe({
        next: (company) => {
          this.quotation.clientRaw = undefined;
          this.quotation.orderReport = false;
          this.quotation.client.score = company.score;
          this.quotation.client.scorePartiallyHidden = company.scorePartiallyHidden;
          this.quotation.client.id = company.id;
          this.quotation.client.naceCodes = company.naceCodes;
          this.inProgress = false;
        },
        error: (error) => {
          this.fetchingRating = false;
          this.handleFetchCreditReportError(error);
          this.inProgress = false;
        },
        complete: () => (this.fetchingRating = false),
      });
    }
    if (
      this.quotation.status.id === QuotationStatus.QUOTATION_READY ||
      this.quotation.status.id === QuotationStatus.QUOTATION_PENDING
    ) {
      this.onSave(false, true);
    }
  }

  handleFetchCreditReportError(error: BackendError) {
    if (isErrorReasons(error) && error.filter((e) => this.noReportErrorCodesCR.has(e.code)).length > 0) {
      this.serverErrors = [];
      this.serverErrors.push(<ErrorReason>{});
      this.serverErrors[0].message = this.translateService.instant(
        'Credit reform report presently not available. Please place an order.'
      );
      this.onReportOrder(true);
    } else {
      this.handleServerError(error);
    }
  }

  onReportOrder(existingCompany: boolean) {
    this.quotation.orderReport = true;
    if (!this.quotation.clientRaw) {
      this.quotation.clientRaw = <CompanyRawDto>{};
      this.quotation.clientRaw.address = <AddressDto>{};
    }
    if (existingCompany) {
      this.fillCompanyRaw();
    }
    this.quotation.client = undefined;
  }

  fillCompanyRaw() {
    this.quotation.clientRaw.registrationName = this.quotation.client.registrationName;
    this.quotation.clientRaw.nationalId = this.quotation.client.nationalId;
    this.quotation.clientRaw.vatNumber = this.quotation.client.vatNumber;
    this.quotation.clientRaw.identifiers = this.quotation.client.identifiers;
    this.quotation.clientRaw.address = this.quotation.client.address;
  }

  isOrderReportStatus() {
    return (
      this.quotation.status.id === QuotationStatus.QUOTATION_REPORT_TO_CONFIRM ||
      this.quotation.status.id === QuotationStatus.QUOTATION_REPORT_WAITING
    );
  }

  commentRequired(clientKnownSince: DictionaryBaseDto): boolean {
    return clientKnownSince?.id === ClientKnownSinceTimeElapsed.LESS_THAN;
  }

  onChangeTurnover() {
    this.quotationContractMaxBondLiabilityLimitCombined =
      this.quotation && this.quotation.clientTurnover
        ? Math.min(this.quotationContractMaxTotalLiabilityLimit, this.quotation.clientTurnover / 10)
        : this.quotationContractMaxTotalLiabilityLimit;

    this.quotationContractMaxSingleBondLimitCombined =
      this.quotation && this.quotation.clientTurnover
        ? Math.min(this.quotationContractMaxBondLiabilityLimit, this.quotation.clientTurnover / 10)
        : this.quotationContractMaxBondLiabilityLimit;
  }

  onSelectCoinsured(selectedCompany: CompanySimpleWithIdentifiersDto) {
    if (!!selectedCompany) {
      if (!this.quotation.coinsured) {
        this.quotation.coinsured = [];
      }
      this.fetchingCoinsured = true;
      this.quotationService
        .addCoinsured(
          this.quotation.id,
          BusinessUtils.findExternalId(selectedCompany.identifiers, undefined),
          BusinessUtils.findExternalId(this.quotation.client.identifiers, undefined)
        )
        .subscribe({
          next: (quotation) => this.changeCoinsuredResponsoe(quotation),
          error: (error) => {
            this.growlService.error(
              'Could not add coinsured: no report available for the selected company' + ' (' + error[0].code + ')'
            );
            this.fetchingCoinsured = false;
          },
          complete: () => (this.fetchingCoinsured = false),
        });
    }
  }

  canAddCoinsured(): boolean {
    if (!this.canRemoveCoinsured()) {
      return false;
    }
    const coinsured = this.quotation.coinsured;
    return !coinsured || coinsured.length < 10;
  }

  canRemoveCoinsured(): boolean {
    if (!this.quotation) {
      return false;
    }
    const statusId = this.quotation.businessStatus.id;
    return (
      statusId === QuotationStatus.QUOTATION_PENDING ||
      statusId === QuotationStatus.QUOTATION_READY ||
      (this.quotation.id && statusId === QuotationStatus.QUOTATION_DRAFT)
    );
  }

  canShowCoinsured(): boolean {
    const q = this.quotation;
    if (q && q.anonymous) {
      return false;
    }
    if (this.quotation.client && this.canRemoveCoinsured() && !this.loggedUserIsBEBankBroker()) {
      return true;
    }
    return q && q.coinsured && q.coinsured.length > 0 && !this.quotation.anonymous && !this.loggedUserIsBEBankBroker();
  }

  removeCoinsured(selectedCompany: CompanySimpleDto) {
    this.fetchingCoinsured = true;
    this.quotationService.removeCoinsured(this.quotation.id, selectedCompany.id).subscribe({
      next: (quotation) => {
        this.changeCoinsuredResponsoe(quotation);
      },
      error: (error) => {
        this.growlService.error('Could not remove coinsured: ' + error[0].message);
        this.fetchingCoinsured = false;
      },
      complete: () => {
        this.fetchingCoinsured = false;
      },
    });
  }

  onGetDraftPackage(langId: number) {
    this.quotationService.getDraftPackage(this.quotation.id, langId);
  }

  onBrokerDiscountCheckboxChange(checked: boolean) {
    if (!checked) {
      console.log('onBrokerDiscountCheckboxChange::clearning..');
      delete this.quotation.discount;
    }
  }

  protected changeCoinsuredResponsoe(respQuotation: QuotationDto) {
    this.quotation.coinsured = respQuotation.coinsured;
    this.quotation.version = respQuotation.version;
  }

  get contractNumber(): string {
    return BusinessUtils.contractNumber(this.quotation.contractVersion);
  }

  showClientRawForm() {
    return !this.quotation.anonymous && this.quotation.orderReport;
  }

  get clientData(): CompanySimpleWithNaceCodesDto | CompanyRawDto {
    return this.quotation.client && this.quotation.client.id ? this.quotation.client : this.quotation.clientRaw;
  }

  get annualPremiumYear(): number | string {
    return (this.quotation && this.quotation.contractValidTo && this.quotation.contractValidTo.getFullYear()) || '';
  }

  getFirstNameValidator(): () => ValidatorFn {
    return () => Validators.compose([Validators.min(2), Validators.required, CustomValidators.userFirstNameFormat]);
  }

  getLastNameValidator(): () => ValidatorFn {
    return () => Validators.compose([Validators.min(2), Validators.required, CustomValidators.userFamilyNameFormat]);
  }

  isCountryBooster(countryId: number): boolean {
    return this.loggedUserService.getLoggedUserData().company.address.country.id === countryId;
  }

  isCollateralVisible(): boolean {
    return !this.isCountryBooster(Country.IT) && !this.isCountryBooster(Country.BE);
  }

  isSpecialBroker() {
    const user = this.loggedUserService.getLoggedUserData();
    return user.roles.some((r) => r.id === UserRole.SPECIAL_BROKER);
  }

  showDraftPackage(): boolean {
    return (
      this.quotation.businessStatus &&
      this.quotation.businessStatus.code === 'QUO_PEN' &&
      !this.quotation.anonymous &&
      this.quotation.client &&
      !!this.quotation.client.id
    );
  }

  showMultipleLanguage(): boolean {
    return this.draftLanguages && this.draftLanguages.length > 1;
  }

  showOneLanguage(): boolean {
    return this.draftLanguages && this.draftLanguages.length === 1;
  }

  disableOrderReportButton() {
    const q = this.quotation;
    const quotationDraft = q && q.status && q.status.id === 62000001;
    const enableOrderReport = q && q.enableOrderReport;
    return !quotationDraft || !enableOrderReport;
  }

  onClientScoreChanged(score: number) {
    if (this.quotationId) {
      this.recalculateTariff();
    }
  }

  loggedUserIsBEBankBroker() {
    return this.loggedUserService.hasLoggedUserRole(UserRole.BE_SPECIAL_BROKER);
  }

  mapObject(val: number): any {
    return {id: val};
  }
}

export interface BrokerQuotationTariff {
  facilityFee: number;
  collateral: number;
}

import {Directive} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[surveyAnswersValidate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SurveyAnswersValidatorDirective,
      multi: true,
    },
  ],
})
export class SurveyAnswersValidatorDirective implements Validator {
  validate(form: UntypedFormGroup): ValidationErrors {
    const estateCollateralVal = <UntypedFormControl>form.get('estateCollateral')?.value;
    const environmentalProtectionVal = <UntypedFormControl>form.get('environmentalProtection')?.value;

    if (estateCollateralVal === undefined || environmentalProtectionVal === undefined) {
      return {required: true};
    }

    return !estateCollateralVal && !environmentalProtectionVal ? {invalidSurveyAnswers: true} : null;
  }
}

import {first, map} from 'rxjs/operators';
import {Injectable, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {
  AppConfigService,
  BONDING_CONTRACT_OFFER_DETAILS,
  ClaimService,
  CollectionVersionService,
  ContractVersionService,
  FeatureService,
  InquiryService,
  LimitService,
  LoggedUserService,
  PolicyInquiryVersionBaseDto,
} from '../../bonding_shared';
import {
  BusinessObjectType,
  ElementaryRight,
  Feature,
  InvoiceCategory,
  RepoDocumentElementaryRight,
  UserRole,
} from '../../bonding_shared/model/dictionary-ids';
import {AbstractRouterService} from '../../shared/abstract-router.service';
import {LimitViewParams} from '../../bonding/limit/limit-details.component';
import {ImportViewParams} from '../../bonding/import/import.component';
import {ServiceContactParams} from '../../bonding/service-contacts';
import {RepositoryDocumentsParams} from '../../bonding/repository-documents';
import {BondingContractInquiryViewParams} from '../../bonding/bonding-contract/inquiry/bonding-contract-inquiry-details.component';
import {BondingContractOfferViewParams} from '../../bonding/bonding-contract/offer/bonding-contract-offer-details.component';
import {BondingContractViewParams} from '../../bonding/bonding-contract/contract';

@Injectable()
export class PortalRouterService extends AbstractRouterService {
  constructor(
    _router: Router,
    private featureService: FeatureService,
    private contractService: ContractVersionService,
    private loggedUserService: LoggedUserService,
    private claimService: ClaimService,
    private appConfigService: AppConfigService,
    @Optional() collectionVersionService: CollectionVersionService,
    @Optional() limitService: LimitService,
    private inquiryService: InquiryService
  ) {
    super(_router, collectionVersionService, limitService);
  }

  toContractDetails(contractVersionId: number, params?: BondingContractViewParams) {
    this.navigate('contract-details', contractVersionId, params);
  }

  // QUOTATION
  toQuotationDetails(id: number, contractId?: number, anonymous?: boolean) {
    const params: any[] = [];
    params.push(id);
    if (contractId !== undefined && contractId !== null) {
      params.push(contractId);
    }
    if (anonymous !== undefined && anonymous !== null) {
      params.push({anon: anonymous});
    }
    if (this.loggedUserService.hasLoggedUserRole(UserRole.BE_SPECIAL_BROKER)) {
      this._router.navigate(['bon/bank-quotation-details', ...params]);
    } else {
      this._router.navigate(['bon/company-quotation-details', ...params]);
    }
  }

  toQuotationDetailsNew() {
    if (this.loggedUserService.hasLoggedUserRole(UserRole.BE_SPECIAL_BROKER)) {
      this._router.navigate(['bon/bank-quotation-details', 0]);
    } else {
      this._router.navigate(['bon/company-quotation-details', 0]);
    }
  }
  toQuotationSearch() {
    this._router.navigate(['bon/quotation-search']);
  }

  toBrokerPortfolioContractVersionPreview(contractVersionId: number) {
    this._router.navigate(['bon/common-contract-preview', contractVersionId]);
  }

  // CONTRACT
  toContractSearch() {
    this._router.navigate(['bon/contract-version-search']);
  }

  toClientContractPreview(contractVersionId: number) {
    this._router.navigate(['bon/common-contract-preview', contractVersionId]);
  }

  toClientTemplates(clientId: number) {
    this._router.navigate(['bon/client-template-list', clientId]);
  }

  toClientDashboard(contractVersionId: number) {
    this._router.navigate(['bon/home', contractVersionId]);
  }

  toClientDashboardNoContract() {
    this._router.navigate(['bon/home']);
  }

  toClaimDetailsByClaimId(claimId: number) {
    this.claimService
      .getClaim(claimId)
      .pipe(map((claim) => claim.versions[claim.versions.length - 1]))
      .subscribe((claimVersion) => this.toClaimDetails(claimVersion.id));
  }

  // toContractSearch() {
  //   this._router.navigate(['bon/contract-search']);
  // }
  // toContractDetails(id: number) {
  //   this._router.navigate(['bon/contract-details', id]);
  // }
  // toContractDetailsNew(clientId: number) {
  //   this._router.navigate(['bon/contract-details', 0, clientId]);
  // }
  // toContractPreview(contractVersionId: number) {
  //   this._router.navigate(['bon/contract-version-preview', contractVersionId]);
  // }
  // toContractPreviewByContractId(contractId: number) {
  //   this._router.navigate(['bon/contract-preview', contractId]);
  // }

  // BOND
  // toBondSearch() {
  //   this._router.navigate(['bon/bond-search']);
  // }

  toBondDetailsIntraStyle(id: number) {
    this._router.navigate(['bon/bond-details', id]);
  }
  // toBondDetailsByBondId(bondId: number) {
  //   this.bondVersionService.getLastBondVersionForBond(bondId)
  //     .subscribe(bondVersion => {
  //       if (bondVersion) {
  //         this.toBondDetails(bondVersion.id);
  //       }
  //     });
  // }

  toBondingContractInquiryDetailsNew() {
    this._router.navigate(['bon/bonding-contract-inquiry-details']);
  }

  toBondingContractInquiryDetails(id: number, params?: BondingContractInquiryViewParams) {
    this.navigate('bonding-contract-inquiry-details', id, params);
  }

  toBondDetailsNew(contractVersionId: number, bondType: string) {
    this._router.navigate(['bon/clientbond-details', 0, contractVersionId, bondType]);
  }

  toBondReplacementProcedure(replacedBondVersionId: number) {
    this._router.navigate(['bon/clientbond-details', 0, 0, 0, replacedBondVersionId]);
  }

  toClientBondSearch() {
    this._router.navigate(['bon/clientbond-search']);
  }

  toClientBondVersionSearch(contractVersionId: number) {
    this._router.navigate(['bon/clientbond-version-search', contractVersionId]);
  }

  toBondDetails(id: number) {
    if (this.featureService.get(Feature.INTRANET_BOND_DETAILS_IN_PORTAL)) {
      this.toBondDetailsIntraStyle(id);
    } else {
      this._router.navigate(['bon/clientbond-details', id]);
    }
  }

  toImporter(importTypeId = 0) {
    this.toImporterWithParams({typeId: importTypeId});
  }

  toImporterWithParams(params: ImportViewParams) {
    this._router.navigate(['bon/import'], {
      queryParams: params,
    });
  }

  toClientBondDetailsNewOld(special?: boolean) {
    this._router.navigate(['bon/clientbond-details', 0], {queryParams: {special: !!special}});
  }

  toClientBondDetailsNew(contractVersionId: number, special?: boolean) {
    this._router.navigate(['bon/clientbond-details', 0, contractVersionId], {queryParams: {special: !!special}});
  }

  toThirdPartySearch() {
    this._router.navigate(['bon/thirdparty-search']);
  }
  toThirdPartyDetails(id: number) {
    this._router.navigate(['bon/thirdparty-details', id]);
  }
  toInvoicesDashboard() {
    this._router.navigate(['bon/invoices-dashboard']);
  }
  toClaimsDashboard() {
    this._router.navigate(['bon/claims-dashboard']);
  }
  toCollectionsDashboard() {
    this._router.navigate(['bon/collections-dashboard']);
  }
  // LIMIT
  toLimitSearch() {
    this._router.navigate(['bon/client-limit-search']);
  }
  toLimitDetails(requestId: number) {
    this._router.navigate(['bon/limit-details', requestId]);
  }

  toLimitDetailsWithParams(params: LimitViewParams) {
    this._router.navigate(['bon/limit-details'], {
      queryParams: params,
    });
  }

  toLimitDetailsNew(insuredId: number, masterPolicyId: number, buyerId: number, typeId: number) {
    this.toLimitDetailsWithParams({
      insuredId: insuredId,
      masterPolicyId: masterPolicyId,
      buyerCompanyId: buyerId,
      typeId: typeId,
    });
  }

  toLimitCoverPlusProposal(coverId: number) {
    this._router.navigate(['bon/client-limit-cover-plus-view', coverId]);
  }

  toClientCompanyDetails(id: number) {
    this._router.navigate(['bon/client-company-details', id]);
  }
  toClientCompanyDetailsNew(clientId: number) {
    this._router.navigate(['bon/client-company-details-new', clientId]);
  }
  toClientCompanySearch() {
    this._router.navigate(['bon/client-company-search']);
  }

  toClientPolicyPreview(policyVersionId: number) {
    this._router.navigate(['bon/client-policy-preview', policyVersionId]);
  }

  toClientPolicyPreviewKuke(policyVersionId: number) {
    this._router.navigate(['bon/kuke-client-policy-preview', policyVersionId]);
  }

  toClientPolicyPreviewEcg(policyVersionId: number) {
    this._router.navigate(['bon/ecg-client-policy-preview', policyVersionId]);
  }

  toPolicyContractSearch() {
    this._router.navigate(['bon/client-policy-search/']);
  }

  toBondingContractOfferDetails(id: number, params?: BondingContractOfferViewParams) {
    this.navigate(BONDING_CONTRACT_OFFER_DETAILS, id, params);
  }

  toPolicyContractTechnicalDetails(id: number) {
    this._router.navigate(['bon/policy-contract-technical-details', id]);
  }

  toPolicyLimitListDetails(listId: number) {
    this._router.navigate(['bon/policylimitlist-details', listId]);
  }

  toTurnoverDetails() {
    this._router.navigate(['bon/client-turnover-details']);
  }

  toAmendmentsDetails() {
    this._router.navigate(['bon/client-amendment-details']);
  }

  toOutstandingsDetails() {
    this._router.navigate(['bon/client-outstandings-details']);
  }

  toOutstandingsDetailsByParams(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-outstandings-details', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  toUserDetails(userId: number) {
    this._router.navigate(['bon/client-user-details', userId]);
  }

  toNewDeclarationAmendmentDetails(baseDeclarationId: number) {
    this._router.navigate(['bon/client-amendment-details/', baseDeclarationId]);
  }

  toTurnoverAmendmentDetails(policyContractId: number) {
    this._router.navigate(['bon/client-amendment-details-by-policy/', policyContractId]);
  }

  toTurnoverAmendmentDetailsByParams(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-amendment-details/', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  toTurnoverImportView(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-turnover-import/', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  toAmendmentImportView(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-amendment-import/', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  toTurnoverDetailsByParams(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-turnover-details/', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  toOutstandingsImportView(reportingPeriodId: number, limitListVersionId: number, declaredOnId: number) {
    this._router.navigate(['bon/client-outstandings-import/', reportingPeriodId, limitListVersionId, declaredOnId]);
  }

  // INVOICE
  toClientInvoices() {
    this._router.navigate(['bon/client-invoice-list']);
  }

  toClientInvoicesByBusinessObject(businessObjectTypeId: number, businessObjectId: number) {
    this._router.navigate(['bon/client-invoice-list', businessObjectTypeId, businessObjectId]);
  }

  toClientInvoicesByBusinessObjectNumber(
    businessObjectTypeId: number,
    businessObjectId: number,
    businessObjectNumber: string
  ) {
    this._router.navigate(['bon/client-invoice-list', businessObjectTypeId, businessObjectId, businessObjectNumber]);
  }

  toClientInvoicePreview(categoryId: InvoiceCategory, invoiceId: number) {
    this._router.navigate(['bon/client-invoice-preview', InvoiceCategory[categoryId], invoiceId]);
  }

  toReportExecutionSearch() {
    this._router.navigate(['bon/report-execution-search']);
  }

  toClientUsers() {
    this._router.navigate(['bon/client-user-search']);
  }

  toStartPage() {
    if (this.appConfigService.kuke || this.loggedUserService.hasRight(ElementaryRight.PORTAL_BROKER)) {
      this._router.navigate(['bon/home']);
    } else {
      this.redirectToContractDashboard();
    }
  }

  toInfo(businessUnit: string, site: string) {
    this._router.navigate(['bon/info', businessUnit, site]);
  }

  private redirectToContractDashboard() {
    if (this.loggedUserService.hasRight(ElementaryRight.PORTAL_CLIENT)) {
      this.contractService
        .getClientContracts()
        .pipe(first(() => true, null))
        .subscribe(
          (contract) => (contract ? this.toClientDashboard(contract.id) : this.toClientDashboardNoContract()),
          (error) => console.log('Contracts load error: ', error)
        );
    } else {
      this.toClientDashboardNoContract();
    }
  }

  // Business Object routuing
  goToBusinessObject(relatedToId: number, id: number) {
    console.log('router service goToBusinessObject: relatedToId = ' + relatedToId + ', id = ' + id);
    switch (relatedToId) {
      case BusinessObjectType.LIMIT_REQUEST:
        this.toLimitDetails(id);
        break;
    }
  }

  toBlockchain() {
    this._router.navigate(['bon/blockchain']);
  }

  toPolicyAnnexInquiryDetails(versionId: number) {
    this._router.navigate(['bon/client-annex-inquiry-details', versionId]);
  }

  toNewInquiryDetails() {
    this._router.navigate(['bon/client-inquiry-preview', 0]);
  }

  toInquiryDetails(inquiry: PolicyInquiryVersionBaseDto) {
    if (this.appConfigService.kuke) {
      if (inquiry.policyInquiry.inquiryType === 'POLICY_ANNEX') {
        this.toPolicyAnnexInquiryDetails(inquiry.id);
      } else if (inquiry.policyInquiry.inquiryType === 'RENEWAL_INQUIRY') {
        this.toPolicyRenewalInquiryDetails(inquiry.id);
      } else {
        this.toPolicyInquiryDetails(inquiry.id);
      }
    } else {
      this.toPolicyInquiryDetails(inquiry.id);
    }
  }

  toPolicyLimitListSearch() {
    this._router.navigate(['bon/client-policylimitlist-search']);
  }

  toPolicyInquiryDetails(versionId: number) {
    this._router.navigate(['bon/client-inquiry-preview/', versionId]);
  }

  toPolicyRenewalInquiryDetails(versionId: number) {
    this._router.navigate(['bon/renewal-inquiry-details', versionId]);
  }

  // CR IKI-9148
  // toPolicyInquirySearch() {
  //   this._router.navigate(['bon/client-inquiry-search']);
  // }
  //
  // toInquiriesDashboard() {
  //   this._router.navigate(['bon/inquiries-dashboard']);
  // }

  toClientOffers() {
    this._router.navigate(['bon/client-offers']);
  }

  toServiceContacts(params: ServiceContactParams) {
    this._router.navigate(['bon/service-contacts'], {queryParams: params});
  }

  toRepositoryDocumentsWithParams(params: RepositoryDocumentsParams) {
    this._router.navigate(['bon/repository-documents'], {
      queryParams: params,
    });
  }

  toRepositoryDocuments(
    businessObjectTypeId: number,
    businessObjectId: number,
    rightToRead: RepoDocumentElementaryRight,
    rightToWrite: RepoDocumentElementaryRight,
    categoryId?: number,
    allowedFileTypes?: string
  ) {
    this.toRepositoryDocumentsWithParams({
      boTypeId: businessObjectTypeId,
      boId: businessObjectId,
      rightToRead: rightToRead,
      rightToWrite: rightToWrite,
      categoryId: categoryId,
      allowedFileTypes: allowedFileTypes,
    });
  }

  toPolicyInquiryDetailsByParentId(id: number) {
    this.inquiryService.getLastVersion(id).subscribe((res) => this.toPolicyInquiryDetails(res.id));
  }

  toPolicyContractPreview(versionId: number) {
    this.toClientPolicyPreviewKuke(versionId);
  }

  toEcgClientInquiryNew() {
    this._router.navigate(['bon/ecg-client-inquiry-preview']);
  }

  toContractPreview(contractVersionId: number) {
    this._router.navigate(['bon/common-contract-preview', contractVersionId]);
  }
}

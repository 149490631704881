import {Component} from '@angular/core';
import {PolicyContractViewService} from '../../services/policy-contract-view.service';
import {Section} from './section';
import {AppConfigService, DictionaryService, PolicyContractVersionService} from '../../../../bonding_shared/services';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'other-standard-conditions-section',
  templateUrl: './other-standard-conditions-section.component.pug',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class OtherStandardConditionsSectionComponent extends Section {
  self = this;

  langCodeRegexp: RegExp = /^(?:it|es|de|fr|en|pl|nl|de_AT)$/;

  constructor(
    protected policyContractViewService: PolicyContractViewService,
    public appService: AppConfigService,
    protected pcvService: PolicyContractVersionService,
    protected dictionaryService: DictionaryService
  ) {
    super(policyContractViewService, appService, pcvService, dictionaryService);
  }

  // other standard conditions CR and KUKE !!
  onRunInChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.runInValue;
    }
  }

  // other standard conditions ONLY KUKE!!
  onInsuranceThresholdChanged(checked: boolean) {
    if (!checked) {
      delete this.policy.insuranceThresholdValue;
    }
  }
}
